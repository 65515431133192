import { FaAngleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

// Please note that this button is fundamentally different from the previous button in your browser. While the previous button in your browser brings you back to the previously visited page, this back button brings you up 1 level in the page hierarchy.
export default function BackButton(props) {
    return (
        <Link to={props.href}>
            <FaAngleLeft
                className="text-primary me-2"
                style={{ marginTop: "-4px" }}
            />
        </Link>
    );
}
