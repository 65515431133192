import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { firebaseConfig } from './firebase_config.js';

let compatApp;
let modularApp;

if (!getApps().length) {
    compatApp = firebase.initializeApp(firebaseConfig);
    modularApp = initializeApp(firebaseConfig);
} else {
    compatApp = firebase.app();
    modularApp = getApp();
}

export function getFirebaseApp() {
    return modularApp;
}

export function getFirebaseCompatApp() {
    return compatApp;
}

export function getFirebaseFunction(name, region = undefined) {
    const functions = getFunctions(modularApp, region);
    return httpsCallable(functions, name);
}

export function getFirebaseFirestore() {
    // This is the v8 (old) firestore api
    // Have to change api.js some day to upgrade to v9
    // the backend of v8 will be deprecated soon
    return firebase.firestore(compatApp);
}

export function getFirebaseStorage() {
    return getStorage(modularApp);
}

export function getFirebaseStorageRef(path) {
    return ref(getStorage(modularApp), path);
}

export function getFirebaseStorageDownloadUrl(path) {
    return getDownloadURL(ref(getStorage(modularApp), path));
}

export function getFirebaseAuth() {
    return getAuth(modularApp);
}

// export function getFirebaseFunctions() {
//     return getFunctions(modularApp);
// }
