import React from "react";
import { Form } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

function EnabledSelector(props) {
    // TODO : merge this component with enabled/disabled button on routineSettingsPage
    return (
        <Form.Select
            value={props.value ? "true" : "false"}
            {...props.register("command_setpayloadrecording_recording", { onChange: props.onChange })}
        >
            <option value="true">True</option>
            <option value="false">False</option>
        </Form.Select>
    );
}

export default function SetPayloadStateCommand(props) {
    function setRecording(event) {
        const value = event.target.value === "true";
        props.updateCommandSettings("recording", value);
    }
    
    return (
        <>
            <CorvusSetting name='Recording'>
                <EnabledSelector
                    value={props.command.settings.recording}
                    onChange={setRecording}
                    register={props.register}
                    defaultValue={props.command.settings.recording}
                />
            </CorvusSetting>
        </>
    );
}
