import React from "react";
import { Card, Form, Image } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useParams } from "react-router-dom";
import * as api from "../../api/api.jsx";
import { getFirebaseStorageDownloadUrl } from "../../corvusFirebase.js";
import BackButton from "../../components/BackButton/index.jsx";
import Layout, { DroneLivestreamBreadcrumbContainer, Title } from "../../components/Layout/Layout.jsx";
import urls from "../../urls.js";
import "./index.css";

export function LivestreamComponent({ droneId, cameraName }) {
    const [livestreams, setLivestreams] = React.useState({});
    const [imagePath, setImagePath] = React.useState('broadcast-interrupted.png');
    const [isInitialUpdate, setIsInitialUpdate] = React.useState(true);
    const [imageUrl, setImageUrl] = React.useState(null);
    const [isCameraAvailable, setIsCameraAvailable] = React.useState(false);

    const prevCameraNameRef = React.useRef();
    const prevLastPictureRef = React.useRef('');
    const timeoutRef = React.useRef(null);
    const requestRef = React.useRef(0); // Ref to keep track of the latest request ID

    React.useEffect(() => {
        return api.subscribeLivestream(droneId, setLivestreams);
    }, [droneId]);

    React.useEffect(() => {
        if (livestreams && livestreams[cameraName]) {
            setIsCameraAvailable(true);

            const isCameraNameChanged = cameraName !== prevCameraNameRef.current;
            prevCameraNameRef.current = cameraName;

            if (isInitialUpdate || isCameraNameChanged) {
                // We always get an initial update from the livestreams document when we subscribe but we 
                // want to ignore the first update because that is just the initial value and not necessarily changing.
                // When the camera name changes, we show a broadcast-interrupted and let it pick up again if
                // the new camera also gets updated.
                setIsInitialUpdate(false);
                prevLastPictureRef.current = livestreams[cameraName].lastPicture;
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                setImagePath('broadcast-interrupted.png');
            } else if (livestreams[cameraName].lastPicture && livestreams[cameraName].lastPicture !== prevLastPictureRef.current) {
                // Image changed
                prevLastPictureRef.current = livestreams[cameraName].lastPicture;
                setImagePath(`${droneId}/${cameraName}/${livestreams[cameraName].lastPicture}`);

                // Clear the previous timeout and set a new one
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                    console.log('Broadcast timeout');
                    setImagePath('broadcast-interrupted.png');
                }, 20000);
            }
        } else {
            setIsCameraAvailable(false);
        }

        // Clear timeout on unmount
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [livestreams, droneId, cameraName, isInitialUpdate]);

    React.useEffect(() => {
        if (imagePath !== '') {
            const currentRequestId = ++requestRef.current; // Increment the request ID
            const url = `gs://corvus-backend.appspot.com/livestreams/${imagePath}`;
            getFirebaseStorageDownloadUrl(url).then((storageUrl) => {
                // Only update the state if the current request ID matches the latest request ID
                if (currentRequestId === requestRef.current) {
                    setImageUrl(storageUrl);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [imagePath]);

    return (
        <>
            {isCameraAvailable ? (
                <Image src={imageUrl} alt="Logo" width="100%" rounded />
            ) : (
                cameraName ? (
                    <span>Camera {cameraName} not available</span>
                ) : (
                    <span>No camera selected</span>
                )
            )}
        </>
    );
}

export default function LivestreamPage() {
    const { droneId } = useParams();
    const [livestreams, setLivestreams] = React.useState({})
    const [cameraOptions, setCameraOptions] = React.useState([])
    const [currentCamera, setCurrentCamera] = React.useState('')

    function cameraSelected(e) {
        setCurrentCamera(e.target.value);
    }

    React.useEffect(() => {
        return api.subscribeLivestream(droneId, setLivestreams);
    }, [droneId, setLivestreams]);

    React.useEffect(() => {
        if (livestreams) {
            // update the options for the cameras we can have pictures for
            let cameraOptions = Object.entries(livestreams).map(([k, _]) => k).filter((k) => k !== 'id');
            cameraOptions.sort();
            setCameraOptions(cameraOptions);
        }
    }, [livestreams]);

    return (
        <Layout>
            <DroneLivestreamBreadcrumbContainer />
            <Card className="mb-4">
                <Title title={
                    <>
                        <div className='input-group'>
                            <BackButton
                                href={urls.drone(droneId)}
                            />
                            Camera:&nbsp;
                            <Form.Select onChange={cameraSelected}>
                                {currentCamera === '' && (
                                    <option key="disabledOption" value={''}>
                                        {"Select a camera"}
                                    </option>
                                )}
                                {currentCamera !== '' && (
                                    <option key="disabledOption" value={''} disabled>
                                        {"Select a camera"}
                                    </option>
                                )}
                                {cameraOptions.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </div >
                    </>
                }>
                </Title >
                <Card.Body className='p-4 pt-0'>
                    <LivestreamComponent
                        droneId={droneId}
                        cameraName={currentCamera}
                    />
                </Card.Body>
            </Card >
        </Layout >
    );
}
