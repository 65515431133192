import { InputGroup } from "react-bootstrap";
import CorvusSettingLabel from "../CorvusSettingLabel";

export default function CorvusSetting(props) {
    return (
        <InputGroup className={props.className}>
            <CorvusSettingLabel name={props.name} />
            {props.children}
        </InputGroup>
    );
}