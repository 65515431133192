import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../../api/api";
import CorvusSetting from "../../../components/CorvusSetting";

function ZoneSelector(props) {
    const [zones, setZones] = React.useState([]);

    React.useEffect(() => {
        api.getZones(props.organizationId, props.locationId, setZones);
    }, [props.organizationId, props.locationId]);

    return (
        <Form.Select
            value={props.value}
            onChange={(event) => {
                props.onChange(event.target.value)
            }}
        >
            <option value="none" disabled>
                Select Zone ...
            </option>
            {zones.map((zone) => {
                return (
                    <option
                        key={zone.id}
                        value={zone.id}
                    >
                        {zone.name}
                    </option>
                );
            })}
        </Form.Select>
    );
}


export default function ScanZoneCommand(props) {
    const { organizationId, locationId } = useParams();

    const setZone = (zone) =>
        props.updateCommandSettings("zone", zone);

    return (
        <CorvusSetting name='Zone'>
            <ZoneSelector
                organizationId={organizationId}
                locationId={locationId}
                value={props.command.settings.zone || "none"}
                onChange={setZone}
            />
        </CorvusSetting>
    );
}
