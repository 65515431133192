import * as missionApi from "../../api/missionApi";
import { STATE_PENDING, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, STATE_UNKNOWN } from "../../api/missionApi";
import StateIcon from "../StateIcon";

export default function ProcessingStatusMessage(props) {

    function processingStatusIsTodo(mission) {
        if (missionApi.anyAppStartedProcessing(mission)) return false;
        if (missionApi.everyAppFinishedProcessing(mission)) return false;
        return true;
    }

    function processingStatusIsDoing(mission) {
        if (!missionApi.anyAppStartedProcessing(mission)) return false;
        if (missionApi.everyAppFinishedProcessing(mission)) return false;
        return true;
    }

    function processingStatusIsSucceeded(mission) {
        if (!missionApi.everyAppSucceededProcessing(mission)) return false;
        return true;
    }

    function processingStatusIsFailed(mission) {
        if (!missionApi.anyAppFailedProcessing(mission)) return false;
        return true;
    }

    function getProcessingState(mission) {
        if (mission?.states?.processing !== undefined) {
            return mission?.states?.processing;
        } else if (processingStatusIsTodo(props.mission)) {
            return STATE_PENDING
        } else if (processingStatusIsDoing(props.mission)) {
            return STATE_RUNNING
        } else if (processingStatusIsSucceeded(props.mission)) {
            return STATE_SUCCEEDED
        } else if (processingStatusIsFailed(props.mission)) {
            return STATE_FAILED
        } else {
            return STATE_UNKNOWN
        }
    }

    return (
        <span>
            <StateIcon
                state={getProcessingState(props.mission)}
                className={"me-4"}
            />
            Processing
        </span>
    );
}