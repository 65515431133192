import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaComment, FaCrown, FaSignOutAlt } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth_provider";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseAuth } from "../../corvusFirebase.js";
import urls from "../../urls.js";
import "./Header.css";

export default function Header(props) {
    const history = useHistory();
    const { user, userClaims } = useAuth();
    const { organizationId, locationId } = useParams();
    const { getUserIsAdmin, setAdminRole, setUserRole } = useUser();

    const loginElements = () => {
        return (
            <div className="d-flex">
                <Button
                    variant="outline-secondary"
                    href="/login"
                    className="ms-2 px-5"
                >
                    Login
                </Button>
            </div>
        );
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const loggedIn = () => {
        return (
            <NavDropdown
                align={{ sm: "end" }}
                title={capitalizeFirstLetter(user.email.split("@")[0])}
            >
                {userClaims && userClaims.isAdmin && (
                    <>
                        <NavDropdown.Item
                            onClick={() =>
                                getUserIsAdmin()
                                    ? setUserRole()
                                    : setAdminRole()
                            }
                        >
                            {getUserIsAdmin() && (
                                <div>
                                    <FaCrown
                                        className="me-2 text-danger"
                                        style={{ marginTop: "-2px" }}
                                    />
                                    Be Client
                                </div>
                            )}
                            {!getUserIsAdmin() && (
                                <div className="text-success">
                                    <FaCrown
                                        className="me-2"
                                        style={{ marginTop: "-2px" }}
                                    />
                                    Be Admin
                                </div>
                            )}
                        </NavDropdown.Item>
                    </>
                )}
                <NavDropdown.Item as={Link} to={urls.feedback()}>
                    <FaComment className="me-2" style={{ marginTop: "-2px" }} />
                    Feedback
                </NavDropdown.Item>
                <NavDropdown.Item
                    onClick={() => {
                        getFirebaseAuth().signOut();
                        history.push(urls.home);
                    }}
                >
                    <FaSignOutAlt
                        className="me-2"
                        style={{ marginTop: "-2px" }}
                    />
                    Log out
                </NavDropdown.Item>
            </NavDropdown>
            // TODO : use or get rid of this code:
            // <Dropdown align={{ lg: "end" }}>
            //     <Dropdown.Toggle variant="outline-secondary">
            //         {getUserIsAdmin() && (
            //             <FaCrown
            //                 className="me-2 text-warning"
            //                 style={{ marginTop: "-2px" }}
            //             />
            //         )}
            //         {getUserIsAdmin() && (
            //             <FaUser
            //                 className="me-2"
            //                 style={{ marginTop: "-2px" }}
            //             />
            //         )}
            //         {capitalizeFirstLetter(user.email.split("@")[0])}
            //     </Dropdown.Toggle>
            //     <Dropdown.Menu align="right">
            //         <Dropdown.Item
            //             onClick={() => {
            //                 history.push(urls.feedback());
            //             }}
            //         >
            //             <FaComment
            //                 className="me-2"
            //                 style={{ marginTop: "-2px" }}
            //             />
            //             Feedback
            //         </Dropdown.Item>
            //         <Dropdown.Item
            //             onClick={() => {
            //                 history.push(urls.support());
            //             }}
            //         >
            //             <FaHeadset
            //                 className="me-2"
            //                 style={{ marginTop: "-2px" }}
            //             />
            //             Support
            //         </Dropdown.Item>
            //         <Dropdown.Item
            //             onClick={() => {
            //                 getFirebaseAuth().signOut();
            //                 history.push(urls.home);
            //             }}
            //         >
            //             <FaSignOutAlt
            //                 className="me-2"
            //                 style={{ marginTop: "-2px" }}
            //             />
            //             Log out
            //         </Dropdown.Item>
            //     </Dropdown.Menu>
            // </Dropdown>
        );
    };
    return (
        <Navbar
            expand="lg"
            variant="light"
            className="py-3 mb-4"
            style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid rgba(0,0,0,.125)",
            }}
        >
            <Container style={props.style}>
                <Navbar.Brand as={Link} to={urls.home()} >
                    <img
                        src={process.env.PUBLIC_URL + "/logo-black-white.png"}
                        width="30"
                        height="30"
                        className="d-inline-block align-top me-2"
                        alt="Corvus Drones logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to={urls.locationDrones(organizationId, locationId)}>
                            Drones
                        </Nav.Link>
                        <Nav.Link as={Link} to={urls.tasks(organizationId, locationId)}>
                            Tasks
                        </Nav.Link>
                        <Nav.Link as={Link} to={urls.locationMissions(organizationId, locationId)}>
                            Missions
                        </Nav.Link>
                        <Nav.Link as={Link} to={urls.routines(organizationId, locationId)}>
                            Routines
                        </Nav.Link>
                        {getUserIsAdmin() && (
                            <NavDropdown title="Beta">
                                <NavDropdown.Item as={Link} to={urls.flights(organizationId, locationId)}>
                                    Flights
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={urls.measurements(organizationId, locationId)}>
                                    Measurements
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={urls.docks(organizationId, locationId)}>
                                    Docks
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={urls.apps(organizationId, locationId)}>
                                    Apps
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {getUserIsAdmin() && (
                            <NavDropdown title="Admin">
                                <NavDropdown.Item as={Link} to={urls.allMissions()}>
                                    All Missions
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={urls.allRoutines()}>
                                    All Routines
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={urls.allOrganizations()}>
                                    All Organizations
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={urls.payloads()}>
                                    All Payloads
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                    </Nav>
                    <Nav>{user ? loggedIn() : loginElements()}</Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
