// TODO : publish thank you for feedback message

import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { FaPaperPlane } from "react-icons/fa";
import Layout, {
    Title,
    FeedbackBreadcrumbContainer,
} from "../../components/Layout/Layout";
import * as api from "../../api/api";
import { useAuth } from "../../contexts/auth_provider";
import urls from "../../urls.js";

export default function FeedbackPage() {
    const history = useHistory();
    const feedbackDescriptionRef = React.useRef("");
    const { user } = useAuth();

    function onSendFeedback() {
        const feedback = { description: feedbackDescriptionRef.current.value };
        api.sendFeedback(user.uid, feedback);
        history.push(urls.home());
    }

    return (
        <Layout>
            <FeedbackBreadcrumbContainer />
            <Card>
                <Title title="Feedback" />
                <Card.Body className="px-4 pt-0">
                    <Card.Text>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="m-0 p-0 mb-4">
                                    <Form.Control
                                        autoFocus
                                        as="textarea"
                                        rows={3}
                                        placeholder="What could be improved?"
                                        ref={feedbackDescriptionRef}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Button
                                    className="me-2"
                                    variant="success"
                                    onClick={() => {
                                        onSendFeedback();
                                    }}
                                >
                                    <FaPaperPlane className="me-2" />
                                    Send feedback
                                </Button>
                            </Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Layout>
    );
}
