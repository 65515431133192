// This file is meant to be copied between corvus-web, corvus-functions & corvus-backend as they all use Firestore.

// TODO : rename auto added id field to _id and remove it in any save function
// TODO : merge the getDocumentPath and getCollectionPath functions into the classes
// TODO : Add version checking of some document stored in Firestore to see if the same version of this file is copied to all repositories.

// for corvus-web
import { getFirebaseFirestore } from "../corvusFirebase.js";

// // for corvus-functions
// const admin = require("firebase-admin");
// const firestore = admin.firestore();
// const getFirestore = () => firestore;

export const ORGANIZATION_COLLECTION = 'organizations';
export const PAYLOAD_COLLECTION = 'payloads';
export const LOCATION_COLLECTION = 'locations';
export const FLIGHT_COLLECTION = 'flights';
export const MISSION_COLLECTION = 'missions';
export const FEEDBACK_COLLECTION = 'feedbacks';
export const PROCESS_COLLECTION = 'processes';
export const MEASUREMENT_COLLECTION = 'measurements';
export const OVERLAY_COLLECTION = 'overlays';
export const TASK_COLLECTION = 'tasks';
export const DOCK_COLLECTION = 'docks';
export const ROUTINE_COLLECTION = 'routines';
export const AREA_COLLECTION = 'areas';
export const ZONE_COLLECTION = 'zones';
export const FLYZONE_COLLECTION = 'flyzones'; // TODO : replace by 'zones'
export const MARKER_COLLECTION = 'markers';
export const DRONE_COLLECTION = 'drones';
export const DRONE_CONFIG_COLLECTION = 'configs';
export const DRONE_ALERTS_COLLECTION = 'alerts';
export const ALERTS_COLLECTION = 'alerts';
export const RESULT_COLLECTION = 'results';
export const APP_COLLECTION = 'apps';
export const COMMAND_COLLECTION = "droneCommands";

export const CURRENT_CONFIG_DOCUMENT = 'current';

export function getAppCollectionPath() {
    return APP_COLLECTION;
}

export function getAppDocumentPath(appId) {
    const appCollectionPath = getAppCollectionPath();
    return `${appCollectionPath}/${appId}`;
}

export function getOrganizationCollectionPath() {
    return ORGANIZATION_COLLECTION;
}

export function getOrganizationDocumentPath(organizationId) {
    const organizationCollectionPath = getOrganizationCollectionPath();
    return `${organizationCollectionPath}/${organizationId}`;
}

export function getLivestreamCollectionPath() {
    return `livestreams`;
}

export function getLivestreamDocumentPath(droneId) {
    const livestreamCollectionPath = getLivestreamCollectionPath();
    return `${livestreamCollectionPath}/${droneId}`;
}

export function getPayloadCollectionPath() {
    return PAYLOAD_COLLECTION;
}

export function getPayloadDocumentPath(payloadId) {
    const payloadCollectionPath = getPayloadCollectionPath();
    return `${payloadCollectionPath}/${payloadId}`;
}

export function getDroneCollectionPath() {
    return DRONE_COLLECTION;
}

export function getDroneDocumentPath(droneId) {
    const droneCollectionPath = getDroneCollectionPath();
    return `${droneCollectionPath}/${droneId}`;
}

export function getDroneAlertCollectionPath(droneId) {
    const droneDocumentPath = getDroneDocumentPath(droneId);
    return `${droneDocumentPath}/${DRONE_ALERTS_COLLECTION}`;
}

export function getDroneAlertDocumentPath(droneId) {
    const droneAlertsCollectionPath = getDroneAlertCollectionPath(droneId);
    return `${droneAlertsCollectionPath}/${CURRENT_CONFIG_DOCUMENT}`;
}

export function getDroneCommandPath(droneId) {
    return `${COMMAND_COLLECTION}/${droneId}`;
}

export function getNewDroneAlertCollectionPath(droneId) {
    const droneDocumentPath = getDroneDocumentPath(droneId);
    return `${droneDocumentPath}/${ALERTS_COLLECTION}`;
}

export function getNewDroneAlertDocumentPath(droneId, alertId) {
    const droneAlertsCollectionPath = getNewDroneAlertCollectionPath(droneId);
    return `${droneAlertsCollectionPath}/${alertId}`;
}

export function getLocationCollectionPath(organizationId) {
    const organizationDocumentPath = getOrganizationDocumentPath(organizationId);
    return `${organizationDocumentPath}/${LOCATION_COLLECTION}`;
}

export function getLocationDocumentPath(organizationId, locationId) {
    const locationCollectionPath = getLocationCollectionPath(organizationId);
    return `${locationCollectionPath}/${locationId}`;
}

export function getFlightCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${FLIGHT_COLLECTION}`;
}

export function getFlightDocumentPath(organizationId, locationId, flightId) {
    const flightCollectionPath = getFlightCollectionPath(organizationId, locationId);
    return `${flightCollectionPath}/${flightId}`;
}

export function getMissionCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${MISSION_COLLECTION}`;
}

export function getMissionDocumentPath(organizationId, locationId, missionId) {
    const missionCollectionPath = getMissionCollectionPath(organizationId, locationId);
    return `${missionCollectionPath}/${missionId}`;
}

export function getMissionFeedbackCollectionPath(organizationId, locationId, missionId) {
    const missionDocumentPath = getMissionDocumentPath(organizationId, locationId, missionId);
    return `${missionDocumentPath}/${FEEDBACK_COLLECTION}`;
}

export function getMissionFeedbackDocumentPath(organizationId, locationId, missionId, feedbackId) {
    const missionFeedbackCollectionPath = getMissionFeedbackCollectionPath(organizationId, locationId, missionId);
    return `${missionFeedbackCollectionPath}/${feedbackId}`;
}

export function getProcessCollectionPath(organizationId, locationId) {
    const missionDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${missionDocumentPath}/${PROCESS_COLLECTION}`;
}

export function getProcessDocumentPath(organizationId, locationId, processId) {
    const processCollectionPath = getProcessCollectionPath(organizationId, locationId, processId);
    return `${processCollectionPath}/${processId}`;
}

export function getResultCollectionPath(organizationId, locationId, missionId) {
    const missionDocumentPath = getMissionDocumentPath(organizationId, locationId, missionId);
    return `${missionDocumentPath}/${RESULT_COLLECTION}`;
}

export function getResultDocumentPath(organizationId, locationId, missionId, resultId) {
    const resultCollectionPath = getResultCollectionPath(organizationId, locationId, missionId);
    return `${resultCollectionPath}/${resultId}`;
}

export function getTaskCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${TASK_COLLECTION}`;
}

export function getTaskDocumentPath(organizationId, locationId, taskId) {
    const taskCollectionPath = getTaskCollectionPath(organizationId, locationId);
    return `${taskCollectionPath}/${taskId}`;
}

export function getDockCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${DOCK_COLLECTION}`;
}

export function getDockDocumentPath(organizationId, locationId, dockId) {
    const dockCollectionPath = getDockCollectionPath(organizationId, locationId);
    return `${dockCollectionPath}/${dockId}`;
}

export function getRoutineCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${ROUTINE_COLLECTION}`;
}

export function getRoutineDocumentPath(organizationId, locationId, routineId) {
    const routineCollectionPath = getRoutineCollectionPath(organizationId, locationId);
    return `${routineCollectionPath}/${routineId}`;
}

export function getZoneCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${ZONE_COLLECTION}`;
}

export function getZoneDocumentPath(organizationId, locationId) {
    const areaCollectionPath = getZoneCollectionPath(organizationId, locationId);
    return `${areaCollectionPath}/${CURRENT_CONFIG_DOCUMENT}`;
}

export function getAreaCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${AREA_COLLECTION}`;
}

export function getAreaDocumentPath(organizationId, locationId) {
    const areaCollectionPath = getAreaCollectionPath(organizationId, locationId);
    return `${areaCollectionPath}/${CURRENT_CONFIG_DOCUMENT}`;
}

export function getFlyzoneCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${FLYZONE_COLLECTION}`;
}

export function getFlyzoneDocumentPath(organizationId, locationId) {
    const flyzoneCollectionPath = getFlyzoneCollectionPath(organizationId, locationId);
    return `${flyzoneCollectionPath}/${CURRENT_CONFIG_DOCUMENT}`;
}

export function getMarkerCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${MARKER_COLLECTION}`;
}

export function getMarkerDocumentPath(organizationId, locationId) {
    const markerCollectionPath = getMarkerCollectionPath(organizationId, locationId);
    return `${markerCollectionPath}/${CURRENT_CONFIG_DOCUMENT}`;
}

export function getMeasurementCollectionPath(organizationId, locationId) {
    const locationDocumentPath = getLocationDocumentPath(organizationId, locationId);
    return `${locationDocumentPath}/${MEASUREMENT_COLLECTION}`;
}

export function getMeasurementDocumentPath(organizationId, locationId, measurementId) {
    const measurementCollectionPath = getMeasurementCollectionPath(organizationId, locationId);
    return `${measurementCollectionPath}/${measurementId}`;
}

export function getOverlayCollectionPath(organizationId, locationId, measurementId) {
    const locationDocumentPath = getMeasurementDocumentPath(organizationId, locationId, measurementId);
    return `${locationDocumentPath}/${OVERLAY_COLLECTION}`;
}

export function getOverlayDocumentPath(organizationId, locationId, measurementId, overlayId) {
    const measurementCollectionPath = getOverlayCollectionPath(organizationId, locationId, measurementId);
    return `${measurementCollectionPath}/${overlayId}`;
}

export function getMeasurementFeedbackCollectionPath(organizationId, locationId, measurementId) {
    const measurementDocumentPath = getMeasurementDocumentPath(organizationId, locationId, measurementId);
    return `${measurementDocumentPath}/${FEEDBACK_COLLECTION}`;
}

export function getMeasurementFeedbackDocumentPath(organizationId, locationId, measurementId, feedbackId) {
    const measurementFeedbackCollectionPath = getMeasurementFeedbackCollectionPath(organizationId, locationId, measurementId);
    return `${measurementFeedbackCollectionPath}/${feedbackId}`;
}

class FirestoreCollection {

    constructor(name, path) {
        this.name = name;
        this.path = path;
    }

    createQuery() {
        return getFirebaseFirestore().collection(this.path);
    }

    getPath() {
        const message = `${this.name}: getCollectionPath() not implemented`;
        console.error(message);
        throw new Error(message);
    }

    get(onData) {
        console.log(`(Firestore) Getting ${this.name} collection ${this.path} ...`);

        return getFirebaseFirestore()
            .collection(this.path)
            .get()
            .then((snapshots) => {
                const data = (snapshots.docs.map((snapshot) => {
                    return {
                        ...snapshot.data(),
                        id: snapshot.id,
                    }
                }));
                console.log(`(Firestore) Received ${data.length} ${this.name} documents`);
                if (onData) onData(data);
                return data;
            });
    }

    add(data) {
        console.log(`(Firestore) Adding ${this.name} collection ${this.path} ...`);

        return getFirebaseFirestore()
            .collection(this.path)
            .add(data)
            .then((snapshot) => snapshot.id);
    }

    subscribe(onData, query = null) {
        const collectionQuery = this.createQuery();
        console.log(`(Firestore) Subscribing to ${this.name} document ${collectionQuery.path} ...`);

        if (query === null) {
            console.log('No query provided, using default query');
            query = this.createQuery()
        };

        const unsubscribe = query
            .onSnapshot((snapshots) => {
                console.log(`(Firestore) Received ${this.name}`);
                onData(snapshots.docs.map((snapshot) => {
                    return {
                        ...snapshot.data(),
                        id: snapshot.id,
                    }
                }));
                return onData;
            });

        return () => {
            console.log(`(Firestore) Unsubscribing from ${this.name} ...`);
            unsubscribe();
        };
    }
}

class FirestoreDocument {

    constructor(name, path) {
        this.name = name;
        this.path = path;
    }

    getQuery() {
        return getFirebaseFirestore().doc(this.path);
    }

    getPath() {
        const message = `${this.name}: getDocumentPath() not implemented`;
        console.error(message);
        throw new Error(message);
    }

    get(onData) {
        console.log(`(Firestore) Getting ${this.name} document ${this.path} ...`);
        return getFirebaseFirestore().doc(this.path).get().then((snapshot) => {
            const data = { ...snapshot.data(), id: snapshot.id };
            if (onData) onData(data);
            return data;
        });
    }

    exists() {
        console.log(`(Firestore) Checking if ${this.name} document ${this.path} exists ...`);
        return getFirebaseFirestore().doc(this.path).get().then((snapshot) => {
            return snapshot.exists;
        });
    }

    set(data) {
        console.log(`(Firestore) Setting ${this.name} document ${this.path} ...`);
        return getFirebaseFirestore().doc(this.path).set(data, { merge: true });
    }

    update(data) {
        console.log(`(Firestore) Updating ${this.name} document ${this.path} ...`);
        return getFirebaseFirestore().doc(this.path).update(data);
    }

    delete() {
        console.log(`(Firestore) Deleting ${this.name} document ${this.path}`);
        return getFirebaseFirestore().doc(this.path).delete();
    }

    subscribe(onData) {
        console.log(`(Firestore) Subscribing ${this.name} document ${this.path} ...`);

        const unsubscribe = getFirebaseFirestore()
            .doc(this.path)
            .onSnapshot((snapshot) => {
                console.log(`(Firestore) Receiving ${this.name} document ...`);
                let data = { id: snapshot.id, ...snapshot.data() };
                if (snapshot.exists) data = { id: snapshot.id, ...snapshot.data() };
                if (onData) onData(data);
                return data;
            });

        return () => {
            console.log(`(Firestore) Unsubscribing from ${this.name} ...`);
            unsubscribe();
        };
    }

}

// --------------------------------------------------------------------------------

export class AppCollection extends FirestoreCollection {
    constructor() {
        const collectionPath = getAppCollectionPath();
        super('App', collectionPath);
    }
}

export class AppDocument extends FirestoreDocument {
    constructor(appId) {
        const documentPath = getAppDocumentPath(appId);
        super('App', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class OrganizationCollection extends FirestoreCollection {
    constructor() {
        const collectionPath = getOrganizationCollectionPath();
        super('Organization', collectionPath);
    }
}

export class OrganizationDocument extends FirestoreDocument {
    constructor(organizationId) {
        const documentPath = getOrganizationDocumentPath(organizationId);
        super('Organization', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class LocationCollection extends FirestoreCollection {
    constructor(organizationId) {
        const collectionPath = getLocationCollectionPath(organizationId);
        super('Location', collectionPath);
    }
}

export class LocationDocument extends FirestoreDocument {
    constructor(organizationId, locationId) {
        const documentPath = getLocationDocumentPath(organizationId, locationId);
        super('Location', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class FlightCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getFlightCollectionPath(organizationId, locationId);
        super('Flight', collectionPath);
    }
}

export class FlightDocument extends FirestoreDocument {
    constructor(organizationId, locationId, flightId) {
        const documentPath = getFlightDocumentPath(organizationId, locationId, flightId);
        super('Flight', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class MissionCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getMissionCollectionPath(organizationId, locationId);
        super('Mission', collectionPath);
    }
}

export class MissionDocument extends FirestoreDocument {
    constructor(organizationId, locationId, missionId) {
        const documentPath = getMissionDocumentPath(organizationId, locationId, missionId);
        super('Mission', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class MissionFeedbackCollection extends FirestoreCollection {
    constructor(organizationId, locationId, missionId) {
        const collectionPath = getMissionFeedbackCollectionPath(organizationId, locationId, missionId);
        super('MissionFeedback', collectionPath);
    }
}

export class MissionFeedbackDocument extends FirestoreDocument {
    constructor(organizationId, locationId, missionId, feedbackId) {
        const documentPath = getMissionFeedbackDocumentPath(organizationId, locationId, missionId, feedbackId);
        super('MissionFeedback', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class ProcessCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getProcessCollectionPath(organizationId, locationId);
        super('Process', collectionPath);
    }
}

export class ProcessDocument extends FirestoreDocument {
    constructor(organizationId, locationId, processId) {
        const documentPath = getProcessDocumentPath(organizationId, locationId, processId);
        super('Process', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class MeasurementCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getMeasurementCollectionPath(organizationId, locationId);
        super('Measurement', collectionPath);
    }
}

export class MeasurementDocument extends FirestoreDocument {
    constructor(organizationId, locationId, measurementId) {
        const documentPath = getMeasurementDocumentPath(organizationId, locationId, measurementId);
        super('Measurement', documentPath);
    }
}

export class OverlayCollection extends FirestoreCollection {
    constructor(organizationId, locationId, measurementId) {
        const collectionPath = getOverlayCollectionPath(organizationId, locationId, measurementId);
        console.log(collectionPath);
        super('Overlay', collectionPath);
    }
}

export class OverlayDocument extends FirestoreDocument {
    constructor(organizationId, locationId, measurementId, overlayId) {
        const documentPath = getOverlayDocumentPath(organizationId, locationId, measurementId, overlayId);
        super('Overlay', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class MeasurementFeedbackCollection extends FirestoreCollection {
    constructor(organizationId, locationId, measurementId) {
        const collectionPath = getMeasurementFeedbackCollectionPath(organizationId, locationId, measurementId);
        super('MeasurementFeedback', collectionPath);
    }
}

export class MeasurementFeedbackDocument extends FirestoreDocument {
    constructor(organizationId, locationId, measurementId, feedbackId) {
        const documentPath = getMeasurementFeedbackDocumentPath(organizationId, locationId, measurementId, feedbackId);
        super('MeasurementFeedback', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class ResultCollection extends FirestoreCollection {
    constructor(organizationId, locationId, missionId) {
        const collectionPath = getResultCollectionPath(organizationId, locationId, missionId);
        super('Result', collectionPath);
    }
}

export class ResultDocument extends FirestoreDocument {
    constructor(organizationId, locationId, missionId, resultId) {
        const documentPath = getResultDocumentPath(organizationId, locationId, missionId, resultId);
        super('Result', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class TaskCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getTaskCollectionPath(organizationId, locationId);
        super('Task', collectionPath);
    }
}

export class TaskDocument extends FirestoreDocument {
    constructor(organizationId, locationId, taskId) {
        const documentPath = getTaskDocumentPath(organizationId, locationId, taskId);
        super('Task', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class DockCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getDockCollectionPath(organizationId, locationId);
        super('Dock', collectionPath);
    }
}

export class DockDocument extends FirestoreDocument {
    constructor(organizationId, locationId, dockId) {
        const documentPath = getDockDocumentPath(organizationId, locationId, dockId);
        super('Dock', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class RoutineCollection extends FirestoreCollection {
    constructor(organizationId, locationId) {
        const collectionPath = getRoutineCollectionPath(organizationId, locationId);
        super('Routine', collectionPath);
    }
}

export class RoutineDocument extends FirestoreDocument {
    constructor(organizationId, locationId, routineId) {
        const documentPath = getRoutineDocumentPath(organizationId, locationId, routineId);
        super('Routine', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class PayloadCollection extends FirestoreCollection {
    constructor() {
        const collectionPath = getPayloadCollectionPath();
        super('Payload', collectionPath);
    }
}

export class PayloadDocument extends FirestoreDocument {
    constructor(payloadId) {
        const documentPath = getPayloadDocumentPath(payloadId);
        super('Payload', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class DroneAlertCollection extends FirestoreCollection {
    constructor(droneId) {
        const collectionPath = getDroneAlertCollectionPath(droneId);
        super('Alert', collectionPath);
    }
}

export class DroneAlertDocument extends FirestoreDocument {
    constructor(droneId) {
        const documentPath = getDroneAlertDocumentPath(droneId);
        super('Alert', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class NewDroneAlertCollection extends FirestoreCollection {
    constructor(droneId) {
        const collectionPath = getNewDroneAlertCollectionPath(droneId);
        super('Alert', collectionPath);
    }
}

export class NewDroneAlertDocument extends FirestoreDocument {
    constructor(droneId, alertId) {
        const documentPath = getNewDroneAlertDocumentPath(droneId, alertId);
        super('Alert', documentPath);
    }
}

// --------------------------------------------------------------------------------

export class AlertCollection extends FirestoreCollection {
    constructor() {
        const collectionPath = getAllAlertsCollectionPath();
        super('Alert', collectionPath);
    }
}

export class AlertDocument extends FirestoreDocument {
    constructor(alertId) {
        const documentPath = getAllAlertsDocumentPath(alertId);
        super('Alert', documentPath);
    }
}

export function getAllAlertsCollectionPath() {
    return `${ALERTS_COLLECTION}`;
}

export function getAllAlertsDocumentPath(alertId) {
    const allAlertsCollectionPath = getAllAlertsCollectionPath();
    return `${allAlertsCollectionPath}/${alertId}`;
}

// --------------------------------------------------------------------------------

export class LivestreamDocument extends FirestoreDocument {
    constructor(droneId) {
        const documentPath = getLivestreamDocumentPath(droneId);
        super('Livestream', documentPath);
    }
}

// --------------------------------------------------------------------------------

// TODO : add these as classes
// export const AREA_COLLECTION = 'areas';
// export const FLYZONE_COLLECTION = 'flyzones';
// export const MARKER_COLLECTION = 'markers';
// export const DRONE_COLLECTION = 'drones';
// export const DRONE_CONFIG_COLLECTION = 'configs';
// export const DRONE_ALERTS_COLLECTION = 'alerts';
