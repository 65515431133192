// GREAT EXAMPLE
// https://github.com/WebDevSimplified/React-Firebase-Auth

import { browserLocalPersistence, browserSessionPersistence, signInWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";
import React, { createContext, useContext, useState } from "react";
import { getFirebaseAuth } from "../corvusFirebase.js";


export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [userClaims, setUserClaims] = useState();

    React.useEffect(() => {
        setLoading(true);
        getFirebaseAuth()
            .onAuthStateChanged((newUser) => {
                setUser(newUser);
                if (newUser) {
                    newUser.getIdTokenResult().then((idTokenResult) => {
                        setUserClaims(idTokenResult.claims);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            });
    }, [user]);

    function login(email, password, remember) {
        let persistanceMode = browserSessionPersistence;
        if (remember) persistanceMode = browserLocalPersistence;

        return getFirebaseAuth()
            .setPersistence(persistanceMode)
            .then(async () => {
                await signInWithEmailAndPassword(getFirebaseAuth(), email, password);
            });
    }

    function logout() {
        return getFirebaseAuth().signOut();
    }

    function resetPasswordLink(resetEmail) {
        return sendPasswordResetEmail(getFirebaseAuth(), resetEmail);
    }

    function corvusConfirmPasswordReset(code, newPassword) {
        return confirmPasswordReset(getFirebaseAuth(), code, newPassword);
    }

    const value = {
        user,
        isLoading,
        userClaims,
        setUserClaims,
        setUser,
        login,
        logout,
        resetPasswordLink,
        corvusConfirmPasswordReset,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
