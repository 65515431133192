import React from "react";
import queryString from 'query-string';
import { Alert, Card, Form, ListGroup, Spinner } from "react-bootstrap";
import * as api from "../../api/api";
import Layout, {
    Title
} from "../../components/Layout/Layout";
import MissionListItem from "../../components/MissionListItem";
import urls from "../../urls.js";
import LoadMoreButton from "../../components/LoadMoreButton";
import TimeDisplay from "../../components/TimeDisplay/index.jsx";
import moment from 'moment';

function AdminMissionList(props) {
    const [organizations, setOrganizations] = React.useState([]);

    React.useEffect(() => {
        return api.getOrganizations(setOrganizations);
    }, []);

    function getOrganizationName(organizationId) {
        const organization = organizations.find((o) => o.id === organizationId);
        return organization ? organization.name : 'Unknown';
    }

    if (props.missions.length <= 0) {
        return (
            <Alert variant="primary" className="mt-0 m-4">
                No missions found
            </Alert>
        );
    }

    return (
        <>
            {props.missions?.length > 0 && (
                <ListGroup variant='flush'>
                    {props.missions.map((mission) => (
                        <MissionListItem
                            key={mission.id}
                            mission={mission}
                            showActivity={true}
                            showMeasurements={true}
                            to={urls.locationMission(mission.organizationId, mission.locationId, mission.id)}
                        >
                            {<TimeDisplay
                                timestamp={mission.timestamp.seconds}
                                format="HH:mm:ss"
                                organizationId={mission.organizationId}
                                locationId={mission.locationId}
                            />}{` @ ${getOrganizationName(mission.organizationId)} by ${mission.drone.id}`}
                        </MissionListItem>
                    ))}
                </ListGroup>
            )}
        </>
    );
}

function MissionByDateList(props) {
    const [missionsByDate, setMissionsByDate] = React.useState({});

    React.useEffect(() => {
        const newMissionsByDate = {};
        props.missions.forEach((mission) => {
            const date = moment(mission.timestamp.toDate()).format('YYYY-MM-DD');
            console.log(date);
            if (!newMissionsByDate[date]) {
                newMissionsByDate[date] = [];
            }
            newMissionsByDate[date].push(mission);
        });
        setMissionsByDate(newMissionsByDate);
    }, [props.missions]);

    return (
        <>
            {Object.keys(missionsByDate).sort().reverse().map((date) => (
                <Card className='mb-4'>
                    {/* Flex space between */}
                    <Card.Header className="px-4 d-flex justify-content-between align-items-start">
                        <div><b>{date}</b></div>
                        <div className='text-muted'>
                            <small>
                                {missionsByDate[date].length} missions
                            </small>
                        </div>
                    </Card.Header>
                    <AdminMissionList missions={missionsByDate[date]} />
                </Card>
            ))}
        </>
    );
}

function IncludeCorvusMissionsSwitch({ className, isChecked, onToggle, isLoading }) {
    return (
        <Form>
            <div>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    className={className}
                    label={
                        <>
                            Include Corvus missions
                            {isLoading && (
                                <Spinner animation="border" role="status" size="sm" className="ms-2" variant="primary">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            )}
                        </>
                    }
                    checked={isChecked}
                    onChange={onToggle}
                />
            </div>
        </Form>
    );
}

export default function AdminMissionListPage(props) {
    const queryParams = queryString.parse(props.location.search);
    const [missions, setMissions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isUpdatingCorvusInclude, setIsUpdatingCorvusInclude] = React.useState(false);
    const [limit, setLimit] = React.useState(50);
    const [includeCorvusMissions, setIncludeCorvusMissions] = React.useState(false);
    const showDeleted = 'showDeleted' in queryParams;

    React.useEffect(() => {
        return api.subscribeAdminMissions(newMissions => {
            setMissions(newMissions);
            setIsLoading(false);
            setIsUpdatingCorvusInclude(false);
        }, limit, includeCorvusMissions, showDeleted);
    }, [limit, includeCorvusMissions, showDeleted]);

    const handleSwitchChange = (event) => {
        setIsUpdatingCorvusInclude(true);
        setIncludeCorvusMissions(event.target.checked);
    };

    return (
        <Layout>
            <Card>
                <Title title='Missions'>
                </Title>
            </Card>
            <IncludeCorvusMissionsSwitch
                className='mt-2'
                isChecked={includeCorvusMissions}
                onToggle={handleSwitchChange}
                isLoading={isUpdatingCorvusInclude}
            />
            <MissionByDateList missions={missions} />
            <LoadMoreButton
                disabled={false}
                isLoading={isLoading}
                load={() => {
                    setIsLoading(true);
                    setLimit(limit + 100);
                }}
                showLoadAll={false}
                loadAll={() => { }}
                text={`Showing ${missions.length} missions`}
            />
        </Layout>
    );
}
