import React from "react";
import queryString from 'query-string';
import { Breadcrumb, Card, Container } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import * as api from "../../api/api";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import Header from "../Header/Header";
import "./Layout.css";

// TODO : disable breadcrumb click when active

function BreadcrumbItem(props) {
    return (
        <Breadcrumb.Item
            active={props.active}
            linkAs={Link}

            linkProps={{ className: "text-decoration-none", to: props.url }}
        >
            {props.children}
        </Breadcrumb.Item>
    );
}

function OrganizationBreadcrumb(props) {
    const { organizationId } = useParams();
    const [organization, setOrganization] = React.useState(null);
    const { getUserIsAdmin } = useUser();

    React.useEffect(() => {
        api.getOrganization(organizationId, setOrganization);
    }, [organizationId]);

    return (
        <BreadcrumbItem active={props.active} url={urls.organization(organizationId)}>
            {getUserIsAdmin() && <>{organization?.name}</>}
            {!getUserIsAdmin() && <FaHome />}
        </BreadcrumbItem>
    );
}

export function LocationListBreadcrumb(props) {
    const { organizationId } = useParams();

    return (
        <BreadcrumbItem active={props.active} url={urls.locations(organizationId)}>
            Locations
        </BreadcrumbItem>
    );
}

export function LocationBreadcrumb(props) {
    const [location, setLocation] = React.useState(null);
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    return (
        <BreadcrumbItem active={props.active} url={urls.location(organizationId, location?.id)}>
            {location ? location.name : 'Location'}
        </BreadcrumbItem>
    );
}

export function OrganizationsBreadcrumb(props) {
    return (
        <BreadcrumbItem active={props.active} url={urls.organizations()}>
            <FaHome />
        </BreadcrumbItem>
    );
}

function PayloadBreadcrumb(props) {
    const { payloadId } = useParams();
    const [payload, setPayload] = React.useState(null);

    React.useEffect(() => {
        api.getPayload(payloadId, setPayload);
    }, [payloadId]);

    return (
        <BreadcrumbItem active={props.active} url={urls.payloadSettings(payload)}>
            {<>{payload?.name}</>}
        </BreadcrumbItem>
    );
}

export function PayloadListBreadcrumb(props) {
    return (
        <BreadcrumbItem active={props.active} url={urls.payloads()}>
            Payloads
        </BreadcrumbItem>
    );
}

export function FeedbackBreadcrumb(props) {
    return (
        <BreadcrumbItem url={urls.feedback()} active={props.active}>
            Feedback
        </BreadcrumbItem>
    );
}

export function SupportBreadcrumb(props) {
    return (
        <BreadcrumbItem url={urls.support()} active={props.active}>
            Support
        </BreadcrumbItem>
    );
}

export function MarkerBreadcrumb(props) {
    const { locationId } = useParams();

    return (
        <BreadcrumbItem
            url={urls.locationMarkers(locationId)}
            active={props.active}
        >
            Markers
        </BreadcrumbItem>
    );
}

export function LocationCreateBreadcrumb(props) {
    const { organizationId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationCreate(organizationId)} active={props.active}>
            Create Location
        </BreadcrumbItem>
    );
}

function RoutineListBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.routines(organizationId, locationId)} active={props.active}>
            Routines
        </BreadcrumbItem>
    );
}

function RoutineCreateBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.routineCreate(organizationId, locationId)} active={props.active}>
            Create
        </BreadcrumbItem>
    );
}

function RoutineBreadcrumb(props) {
    const { organizationId, locationId, routineId } = useParams();

    return (
        <BreadcrumbItem url={urls.routine(organizationId, locationId, routineId)} active={props.active}>
            Routine
        </BreadcrumbItem>
    );
}

function RoutineSettingsBreadcrumb(props) {
    const { organizationId, locationId, routineId } = useParams();

    return (
        <BreadcrumbItem url={urls.routine(organizationId, locationId, routineId)} active={props.active}>
            Settings
        </BreadcrumbItem>
    );
}

function TaskListBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.tasks(organizationId, locationId)} active={props.active}>
            Tasks
        </BreadcrumbItem>
    );
}

function TaskAnalyseBreadcrumb(props) {
    const { organizationId, locationId, taskId, analyseId } = useParams();

    return (
        <BreadcrumbItem
            url={urls.taskAnalyse(organizationId, locationId, taskId, analyseId)}
            active={props.active}
        >
            Analyse
        </BreadcrumbItem>
    );
}

function TaskCommandBreadcrumb(props) {
    const { organizationId, locationId, taskId, commandId } = useParams();

    return (
        <BreadcrumbItem
            url={urls.taskCommand(organizationId, locationId, taskId, commandId)}
            active={props.active}
        >
            Command
        </BreadcrumbItem>
    );
}

function TaskSettingsBreadcrumb(props) {
    const { organizationId, locationId, taskId } = useParams();

    return (
        <BreadcrumbItem url={urls.taskSettings(organizationId, locationId, taskId)} active={props.active}>
            Settings
        </BreadcrumbItem>
    );
}

function DockSettingsBreadcrumb(props) {
    const { organizationId, locationId, dockId } = useParams();

    return (
        <BreadcrumbItem url={urls.dockSettings(organizationId, locationId, dockId)} active={props.active}>
            Settings
        </BreadcrumbItem>
    );
}

function LocationSettingsBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem
            url={urls.locationSettings(organizationId, locationId)}
            active={props.active}
        >
            Settings
        </BreadcrumbItem>
    );
}

function TaskBreadcrumb(props) {
    // TODO : use the task name instead of `Task` through some kind of context component

    const { organizationId, locationId, taskId } = useParams();

    return (
        <BreadcrumbItem url={urls.task(organizationId, locationId, taskId)} active={props.active}>
            Task
        </BreadcrumbItem>
    );
}

function DockBreadcrumb(props) {
    const { organizationId, locationId, dockId } = useParams();

    return (
        <BreadcrumbItem url={urls.dock(organizationId, locationId, dockId)} active={props.active}>
            Dock
        </BreadcrumbItem>
    );
}

function DockListBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.docks(organizationId, locationId)} active={props.active}>
            Docks
        </BreadcrumbItem>
    );
}

function DroneListBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationDrones(organizationId, locationId)} active={props.active}>
            Drones
        </BreadcrumbItem>
    );
}

function MissionListBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationMissions(organizationId, locationId)} active={props.active}>
            Missions
        </BreadcrumbItem>
    );
}

function FlightListBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem url={urls.flights(organizationId, locationId)} active={props.active}>
            Flights
        </BreadcrumbItem>
    );
}

function StartBreadcrumb(props) {
    const { organizationId, locationId, droneId } = useParams();

    return (
        <BreadcrumbItem url={urls.droneStart(organizationId, locationId, droneId)} active={props.active}>
            Start
        </BreadcrumbItem>
    );
}

function DroneSettingsBreadcrumb(props) {
    const { organizationId, locationId, droneId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationDroneSettings(organizationId, locationId, droneId)} active={props.active}>
            Settings
        </BreadcrumbItem>
    );
}

function DroneNerdBreadcrumb(props) {
    const { organizationId, locationId, droneId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationDroneNerd(organizationId, locationId, droneId)} active={props.active}>
            Nerd
        </BreadcrumbItem>
    );
}

function DroneLivestreamBreadcrumb(props) {
    const { organizationId, locationId, droneId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationDroneLivestream(organizationId, locationId, droneId)} active={props.active}>
            Livestream
        </BreadcrumbItem>
    );
}

function DroneBreadcrumb(props) {
    // TODO : use the drone name instead of `Drone` through some kind of context component

    const { organizationId, locationId, droneId } = useParams();

    return (
        <BreadcrumbItem url={urls.locationDrone(organizationId, locationId, droneId)} active={props.active}>
            {droneId}
        </BreadcrumbItem>
    );
}

function MissionBreadcrumb(props) {
    let { organizationId, locationId, missionId } = useParams();
    if (missionId === undefined) {
        const queryParams = queryString.parse(window.location.search);
        missionId = queryParams.missionId;
    }

    return (
        <BreadcrumbItem url={urls.locationMission(organizationId, locationId, missionId)} active={props.active}>
            Mission
        </BreadcrumbItem>
    );
}

function ImageListBreadcrumb(props) {
    const { organizationId, locationId, missionId } = useParams();

    return (
        <BreadcrumbItem url={urls.measurements(organizationId, locationId, missionId)} active={props.active}>
            Images
        </BreadcrumbItem>
    );
}

function ImageBreadcrumb(props) {
    const { organizationId, locationId, missionId, measurementId } = useParams();

    return (
        <BreadcrumbItem url={urls.measurement(organizationId, locationId, missionId, measurementId)} active={props.active}>
            Image
        </BreadcrumbItem>
    );
}

function FlightBreadcrumb(props) {
    let { organizationId, locationId, flightId } = useParams();
    if (flightId === undefined) {
        const queryParams = queryString.parse(window.location.search);
        flightId = queryParams.flightId;
    }

    return (
        <BreadcrumbItem url={urls.flight(organizationId, locationId, flightId)} active={props.active}>
            Flight
        </BreadcrumbItem>
    );
}

function OrganizationsBreadcrumbPath(props) {
    return (
        <>
            <OrganizationsBreadcrumb active={!props.children} />
            {props.children}
        </>
    );
}

function AdminOrganizationBreadcrumbPath(props) {
    return (
        <OrganizationsBreadcrumbPath>
            <OrganizationBreadcrumb active={!props.children} />
            {props.children}
        </OrganizationsBreadcrumbPath>
    );
}

function UserOrganizationBreadcrumbPath(props) {
    return (
        <>
            <OrganizationBreadcrumb active={!props.children} />
            {props.children}
        </>
    );
}

function OrganizationBreadcrumbPath(props) {
    const { getUserIsAdmin } = useUser();

    return (
        <>
            {getUserIsAdmin() && (
                <AdminOrganizationBreadcrumbPath>
                    {props.children}
                </AdminOrganizationBreadcrumbPath>
            )}
            {!getUserIsAdmin() && (
                <UserOrganizationBreadcrumbPath>
                    {props.children}
                </UserOrganizationBreadcrumbPath>
            )}
        </>
    );
}

function PayloadBreadcrumbPath(props) {
    return (
        <PayloadListBreadcrumbPath>
            <PayloadBreadcrumb active={!props.children} />
            {props.children}
        </PayloadListBreadcrumbPath>
    );
}

function PayloadListBreadcrumbPath(props) {
    return (
        <OrganizationsBreadcrumbPath>
            <PayloadListBreadcrumb active={!props.children} />
            {props.children}
        </OrganizationsBreadcrumbPath>
    );
}

function SupportBreadcrumbPath(props) {
    return (
        <OrganizationBreadcrumbPath>
            <SupportBreadcrumb active={!props.children} />
            {props.children}
        </OrganizationBreadcrumbPath>
    );
}

function FeedbackBreadcrumbPath(props) {
    return (
        <OrganizationBreadcrumbPath>
            <FeedbackBreadcrumb active={!props.children} />
            {props.children}
        </OrganizationBreadcrumbPath>
    );
}

function LocationListBreadcrumbPath(props) {
    return (
        <OrganizationBreadcrumbPath>
            <LocationListBreadcrumb active={!props.children} />
            {props.children}
        </OrganizationBreadcrumbPath>
    );
}

function LocationBreadcrumbPath(props) {
    const [location, setLocation] = React.useState(null);
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    return (
        <LocationListBreadcrumbPath>
            {/* TODO : split LocationListBreadcrumb from this component */}
            {/* {location && <LocationListBreadcrumb active={false} />} */}
            {location && <LocationBreadcrumb active={!props.children} />}
            {props.children}
        </LocationListBreadcrumbPath>
    );
}

function LocationSettingsBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <LocationSettingsBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function LocationCreateBreadcrumbPath(props) {
    return (
        <OrganizationBreadcrumbPath>
            <LocationCreateBreadcrumb active={!props.children} />
            {props.children}
        </OrganizationBreadcrumbPath>
    );
}

function MarkerBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <MarkerBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function RoutineListBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <RoutineListBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function RoutineCreateBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <RoutineCreateBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function RoutineBreadcrumbPath(props) {
    return (
        <RoutineListBreadcrumbPath>
            <RoutineBreadcrumb active={!props.children} />
            {props.children}
        </RoutineListBreadcrumbPath>
    );
}

function RoutineSettingsBreadcrumbPath(props) {
    return (
        <RoutineBreadcrumbPath>
            <RoutineSettingsBreadcrumb active={!props.children} />
            {props.children}
        </RoutineBreadcrumbPath>
    );
}

function TaskListBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <TaskListBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function DockListBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <DockListBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

// function LocationListBreadcrumbPath(props) {
//     return (
//         <OrganizationBreadcrumbPath>
//             <LocationListBreadcrumb active={!props.children} />
//             {props.children}
//         </OrganizationBreadcrumbPath>
//     );
// }

function TaskBreadcrumbPath(props) {
    return (
        <TaskListBreadcrumbPath>
            <TaskBreadcrumb active={!props.children} />
            {props.children}
        </TaskListBreadcrumbPath>
    );
}

function DockBreadcrumbPath(props) {
    return (
        <DockListBreadcrumbPath>
            <DockBreadcrumb active={!props.children} />
            {props.children}
        </DockListBreadcrumbPath>
    );
}

function TaskAnalyseBreadcrumbPath(props) {
    return (
        <TaskBreadcrumbPath>
            <TaskAnalyseBreadcrumb active={!props.children} />
            {props.children}
        </TaskBreadcrumbPath>
    );
}

function TaskCommandBreadcrumbPath(props) {
    return (
        <TaskBreadcrumbPath>
            <TaskCommandBreadcrumb active={!props.children} />
            {props.children}
        </TaskBreadcrumbPath>
    );
}

function TaskSettingsBreadcrumbPath(props) {
    return (
        <TaskBreadcrumbPath>
            <TaskSettingsBreadcrumb active={!props.children} />
            {props.children}
        </TaskBreadcrumbPath>
    );
}

function DockSettingsBreadcrumbPath(props) {
    return (
        <DockBreadcrumbPath>
            <DockSettingsBreadcrumb active={!props.children} />
            {props.children}
        </DockBreadcrumbPath>
    );
}

function DroneListBreadcrumbPath(props) {
    return (
        <>
            <LocationBreadcrumbPath>
                <DroneListBreadcrumb active={!props.children} />
                {props.children}
            </LocationBreadcrumbPath>
        </>
    );
}

function MissionListBreadcrumbPath(props) {
    return (
        <>
            <LocationBreadcrumbPath>
                <MissionListBreadcrumb active={!props.children} />
                {props.children}
            </LocationBreadcrumbPath>
        </>
    );
}

function FlightListBreadcrumbPath(props) {
    return (
        <>
            <LocationBreadcrumbPath>
                <FlightListBreadcrumb active={!props.children} />
                {props.children}
            </LocationBreadcrumbPath>
        </>
    );
}

function DroneBreadcrumbPath(props) {
    return (
        <DroneListBreadcrumbPath>
            <DroneBreadcrumb active={!props.children} />
            {props.children}
        </DroneListBreadcrumbPath>
    );
}

function DroneCreateBreadcrumb(props) {
    const { organizationId, locationId } = useParams();

    return (
        <BreadcrumbItem
            url={urls.locationSettings(organizationId, locationId)}
            active={props.active}
        >
            Create new drone
        </BreadcrumbItem>
    );
}

function DroneCreateBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <DroneCreateBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function MissionBreadcrumbPath(props) {
    return (
        <MissionListBreadcrumbPath>
            <MissionBreadcrumb active={!props.children} />
            {props.children}
        </MissionListBreadcrumbPath>
    );
}

function LocationImageListBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <ImageListBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function MissionImageListBreadcrumbPath(props) {
    return (
        <MissionBreadcrumbPath>
            <ImageListBreadcrumb active={!props.children} />
            {props.children}
        </MissionBreadcrumbPath>
    );
}

function FlightImageListBreadcrumbPath(props) {
    return (
        <FlightBreadcrumbPath>
            <ImageListBreadcrumb active={!props.children} />
            {props.children}
        </FlightBreadcrumbPath>
    );
}

function ImageBreadcrumbPath(props) {
    return (
        <LocationImageListBreadcrumbPath>
            <ImageBreadcrumb active={!props.children} />
            {props.children}
        </LocationImageListBreadcrumbPath>
    );
}

function FlightBreadcrumbPath(props) {
    return (
        <FlightListBreadcrumbPath>
            <FlightBreadcrumb active={!props.children} />
            {props.children}
        </FlightListBreadcrumbPath>
    );
}

function StartBreadcrumbPath(props) {
    return (
        <LocationBreadcrumbPath>
            <StartBreadcrumb active={!props.children} />
            {props.children}
        </LocationBreadcrumbPath>
    );
}

function DroneSettingsBreadcrumbPath(props) {
    return (
        <DroneBreadcrumbPath>
            <DroneSettingsBreadcrumb active={!props.children} />
            {props.children}
        </DroneBreadcrumbPath>
    );
}

function DroneNerdBreadcrumbPath(props) {
    return (
        <DroneBreadcrumbPath>
            <DroneNerdBreadcrumb active={!props.children} />
            {props.children}
        </DroneBreadcrumbPath>
    );
}

function DroneLivestreamBreadcrumbPath(props) {
    return (
        <DroneBreadcrumbPath>
            <DroneLivestreamBreadcrumb active={!props.children} />
            {props.children}
        </DroneBreadcrumbPath>
    );
}

export function OrganizationBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <OrganizationBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function SupportBreadcrumbContainer(props) {
    return (
        <BreadcrumbContainer>
            <SupportBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function FeedbackBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <FeedbackBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function PayloadBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <PayloadBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function PayloadListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <PayloadListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function LocationBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <LocationBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function LocationListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <LocationListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function LocationSettingsBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <LocationSettingsBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function LocationCreateBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <LocationCreateBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function MarkerBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <MarkerBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function TaskListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <TaskListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DockListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DockListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DockBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DockBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function TaskBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <TaskBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function TaskAnalyseBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <TaskAnalyseBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function TaskCommandBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <TaskCommandBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function TaskSettingsBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <TaskSettingsBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DockSettingsBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DockSettingsBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DroneListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DroneListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function MissionListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <MissionListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function FlightListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <FlightListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DroneBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DroneBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DroneCreateBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DroneCreateBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function MissionBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <MissionBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function LocationImageListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <LocationImageListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function MissionImageListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <MissionImageListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function FlightImageListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <FlightImageListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function ImageBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <ImageBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function FlightBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <FlightBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function StartBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <StartBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DroneSettingsBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DroneSettingsBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DroneNerdBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DroneNerdBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function DroneLivestreamBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <DroneLivestreamBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function RoutineListBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <RoutineListBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function RoutineCreateBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <RoutineCreateBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function RoutineBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <RoutineBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

export function RoutineSettingsBreadcrumbContainer() {
    return (
        <BreadcrumbContainer>
            <RoutineSettingsBreadcrumbPath />
        </BreadcrumbContainer>
    );
}

function BreadcrumbContainer(props) {
    return (
        <Card className="mb-4 d-none d-md-block">
            <Card.Body className="px-4 py-2">
                <Breadcrumb>{props.children}</Breadcrumb>
            </Card.Body>
        </Card>
    );
}

export function Title(props) {
    return (
        <Card.Body className="p-4">
            <div className="d-flex justify-content-between align-middle">
                <h3 className="m-0 h3">{props.title}</h3>
                {props.children}
            </div>
        </Card.Body>
    );
}

// export function Title(props) {
//     return (
//         <Card.Body className="p-4 d-flex flex-column flex-md-row">
//             <div className="d-flex align-items-center mb-3 mb-md-0">
//                 <h3 className="m-0 h3">{props.title}</h3>
//             </div>
//             <div className="d-flex align-items-center">
//                 {props.children}
//             </div>
//         </Card.Body>
//     );
// }

function LayoutBase(props) {
    let maxWidth = "850px";

    return (
        <>
            <Header style={{ maxWidth: maxWidth }} />
            <Container style={{ maxWidth: maxWidth }} className="mb-5">
                {props.children}
            </Container>
        </>
    );
}

export default function Layout(props) {
    return <LayoutBase {...props} />;
}
