import GenericFormGroup from './GenericFormGroup';
import { Form } from 'react-bootstrap';

const SelectFormGroup = ({ options, disabledOption, ...props }) => {
    return (
        <GenericFormGroup
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <Form.Select
                    {...field}
                    isInvalid={!!errorMessage}
                    style={{ fontWeight: canResetToDefault ? 'bold' : 'normal' }}>
                    {disabledOption && (
                        <option key="disabledOption" value={disabledOption.id || ''} disabled>
                            {disabledOption.name}
                        </option>
                    )}
                    {options.map(option => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </Form.Select>
            )}
        />
    );
};

export default SelectFormGroup;
