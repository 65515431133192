import React from "react";
import { FaLink } from "react-icons/fa";


export default function FirestoreDevMenuItem(props) {

    function visitFirestore() {
        window.open(`https://console.firebase.google.com/u/0/project/corvus-backend/firestore/data/~2F${props.path}`)
    }

    return (
        <props.as onClick={visitFirestore}>
            <FaLink
                className="me-2 text-primary"
                style={{ marginTop: "-2px" }}
            />
            {props.text || 'Firestore'}
        </props.as>
    );
}
