import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function FlyToYawCommand(props) {
    const setYaw = (event) => props.updateCommandSettings("yaw", event.target.value ? parseFloat(event.target.value) : '');

    return (
        <>
            <CorvusSetting name='Yaw'>
                <Form.Control
                    autoFocus
                    {...props.register("command_flytoyaw_yaw", { onChange: setYaw })}
                    defaultValue={props.command.settings.yaw}
                />
                <InputGroup.Text>
                    °
                </InputGroup.Text>
            </CorvusSetting>
        </>
    );
}
