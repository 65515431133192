import * as missionApi from "../../api/missionApi";
import { STATE_PENDING, STATE_CANCELLED, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, STATE_UNKNOWN } from "../../api/missionApi";
import StateIcon from "../StateIcon";

export default function MissionStatusMessage(props) {

    function getMissionState(mission) {
        if (mission?.states) {
            if (mission.states.processing === STATE_SUCCEEDED) {
                return STATE_SUCCEEDED;
            }
            if (mission.states.processing === STATE_FAILED) {
                return STATE_FAILED;
            }
            if (mission.states.collecting === STATE_PENDING &&
                mission.states.uploading === STATE_PENDING &&
                mission.states.processing === STATE_PENDING) {
                return STATE_PENDING;
            }
            if (mission.states.collecting === STATE_SUCCEEDED &&
                mission.states.uploading === STATE_SUCCEEDED &&
                mission.states.processing === STATE_SUCCEEDED) {
                return STATE_SUCCEEDED;
            }
            if (mission.states.collecting === STATE_CANCELLED &&
                mission.states.uploading === STATE_CANCELLED &&
                mission.states.processing === STATE_CANCELLED) {
                return STATE_CANCELLED;
            }
            if (mission.states.collecting === STATE_FAILED ||
                mission.states.uploading === STATE_FAILED ||
                mission.states.processing === STATE_FAILED) {
                return STATE_FAILED;
            }
            else {
                return STATE_RUNNING;
            }
        } else if (missionApi.missionStatusIsTodo(mission)) {
            return STATE_PENDING
        } else if (missionApi.missionStatusIsDoing(mission)) {
            return STATE_RUNNING
        } else if (missionApi.missionStatusIsSucceeded(mission)) {
            return STATE_SUCCEEDED
        } else if (missionApi.missionStatusIsFailed(mission)) {
            return STATE_FAILED
        } else {
            return STATE_UNKNOWN
        }
    }

    return (
        <div>
            <StateIcon
                state={getMissionState(props.mission)}
                className='me-4'
            />
            {props.children}
        </div>
    );

}
