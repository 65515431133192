import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CancelButton(props) {
    return (
        <Button
            variant="outline-secondary"
            as={Link}
            to={props.href}
            className={props.className}
        >
            Cancel
        </Button>
    );
}
