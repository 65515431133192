import { uploadStatusIsTodo } from "../../components/UploadStatusMessage";
import { STATE_PENDING, STATE_RUNNING } from '../../api/missionApi.jsx';
import useExpectedImages from '../../hooks/useExpectedImages.jsx';
import useMissionFlightsSubscription from '../../hooks/useMissionFlightsSubscription.jsx';

export default function MeasurementInfoString(props) {
    const flights = useMissionFlightsSubscription(props.mission.organizationId, props.mission.locationId, props.mission)
    const expectedImages = useExpectedImages(flights);

    const nr_uploaded_measurements = props.mission?.measurements?.length || 0;;

    if (expectedImages === undefined) {
        return nr_uploaded_measurements;
    } else {
        const inCollectingState = props.mission?.states?.collecting !== undefined
            ? (props.mission.states.collecting === STATE_RUNNING || props.mission.states.collecting === STATE_PENDING)
            : uploadStatusIsTodo(props.mission);

        let displayValue = '';
        if ((nr_uploaded_measurements > 0 || !inCollectingState) &&
            nr_uploaded_measurements !== expectedImages) {

            displayValue += nr_uploaded_measurements + '/'
        }
        displayValue += expectedImages
        return (<>{displayValue}</>);
    }
};
