function serializeParams(params) {
    if (!params) {
        return '';
    }
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
}

const urls = {

    home: () => `/`,
    feedback: () => `/feedback`,
    // profile: () => `/profile`,
    login: () => `/login`,
    resetPassword: () => `/reset`,
    support: () => `/support`,
    demo: () => `/demo`,

    drone: (id) => `/drones/${id}`,


    // Drone
    locationDrones: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/drones`,
    droneCreate: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/drone/create`, // Not using drones because it interferes with the drone url :/
    locationDrone: (organizationId, locationId, droneId) => `/organizations/${organizationId}/locations/${locationId}/drones/${droneId}`,
    droneStart: (organizationId, locationId, droneId) => `/organizations/${organizationId}/locations/${locationId}/start?droneId=${droneId}`,
    locationDroneSettings: (organizationId, locationId, droneId) => `/organizations/${organizationId}/locations/${locationId}/drones/${droneId}/settings`,
    locationDroneNerd: (organizationId, locationId, droneId) => `/organizations/${organizationId}/locations/${locationId}/drones/${droneId}/nerd`,
    locationDroneLivestream: (organizationId, locationId, droneId) => `/organizations/${organizationId}/locations/${locationId}/drones/${droneId}/livestream`,
    droneSystemList: (organizationId, locationId, droneId) => `/organizations/${organizationId}/locations/${locationId}/drones/${droneId}/system`,

    // Flight
    flights: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/flights`,
    aFlight: (flightId) => `/flights/${flightId}`,

    // params is a list of key value pairs
    flight: (organizationId, locationId, flightId, params) => `/organizations/${organizationId}/locations/${locationId}/flights/${flightId}${serializeParams(params)}`,

    // Missions
    locationMissions: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/missions`,
    locationMission: (organizationId, locationId, missionId) => `/organizations/${organizationId}/locations/${locationId}/missions/${missionId}`,
    mission: (missionId) => `/missions/${missionId}`,

    // Measurements
    measurements: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/measurements`,
    flightMeasurements: (organizationId, locationId, flightId) => `/organizations/${organizationId}/locations/${locationId}/measurements?flightId=${flightId}`,
    missionMeasurements: (organizationId, locationId, missionId) => `/organizations/${organizationId}/locations/${locationId}/measurements?missionId=${missionId}`,
    measurement: (organizationId, locationId, missionId, measurementId) => `/organizations/${organizationId}/locations/${locationId}/missions/${missionId}/measurements/${measurementId}`,

    // Task
    tasks: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/tasks`,
    taskCreate: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/tasks/create`,
    task: (organizationId, locationId, taskId) => `/organizations/${organizationId}/locations/${locationId}/tasks/${taskId}`,
    taskSettings: (organizationId, locationId, taskId) => `/organizations/${organizationId}/locations/${locationId}/tasks/${taskId}/settings`,
    taskCommand: (organizationId, locationId, taskId, commandId) => `/organizations/${organizationId}/locations/${locationId}/tasks/${taskId}/commands/${commandId}`,
    taskAnalyse: (organizationId, locationId, taskId, analyseId) => `/organizations/${organizationId}/locations/${locationId}/tasks/${taskId}/analyse/${analyseId}`,
    taskStart: (organizationId, locationId, taskId) => `/organizations/${organizationId}/locations/${locationId}/start?taskId=${taskId}`,

    // Routine
    routines: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/routines`,
    routineCreate: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/routines/create`,
    routineSettings: (organizationId, locationId, routineId) => `/organizations/${organizationId}/locations/${locationId}/routines/${routineId}/settings`,
    routine: (organizationId, locationId, routineId) => `/organizations/${organizationId}/locations/${locationId}/routines/${routineId}`,

    // Map
    locationMap: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/map`,
    locationMapbox: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/mapbox`,

    // App
    apps: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/apps`,

    // Dock
    docks: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/docks`,
    dock: (organizationId, locationId, dockId) => `/organizations/${organizationId}/locations/${locationId}/docks/${dockId}`,
    dockCreate: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/docks/create`,
    dockSettings: (organizationId, locationId, dockId) => `/organizations/${organizationId}/locations/${locationId}/docks/${dockId}/settings`,

    // // Location
    locations: (organizationId) => `/organizations/${organizationId}/locations`,
    location: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}`,
    locationCreate: (organizationId) => `/organizations/${organizationId}/locations/create`,
    locationSettings: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/config`,
    locationStart: (organizationId, locationId) => `/organizations/${organizationId}/locations/${locationId}/start`,

    // Organization
    organizations: () => `/organizations`,
    organization: (organizationId) => `/organizations/${organizationId}`, // TODO : add in app

    // Payload
    payloads: () => `/payloads`,
    payloadSettings: (payloadId) => `/payloads/${payloadId}/settings`,

    allOrganizations: () => `/organizations`,
    allMissions: () => `/missions`,
    allRoutines: () => `/routines`,
    allFlights: () => `/flights`,
    allDrones: () => `/drones`,
    allMeasurements: () => `/measurements`,

};

export default urls;
