import GenericFormGroup from './GenericFormGroup';
import { Form } from 'react-bootstrap';

const BooleanFormGroup = ({ trueLabel = "True", falseLabel = "False", ...props}) => {
    return (
        <GenericFormGroup
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <Form.Select
                    {...field}
                    value={field.value === undefined ? 'false' : field.value.toString()} // Handle undefined value
                    onChange={(e) => field.onChange(e.target.value === 'true')} // Convert string back to boolean when changed
                    isInvalid={!!errorMessage}
                    style={{ fontWeight: canResetToDefault ? 'bold' : 'normal' }}>
                    <option value="true">{trueLabel}</option>
                    <option value="false">{falseLabel}</option>
                </Form.Select>
            )}
        />
    );
};

export default BooleanFormGroup;
