import * as missionApi from "../../api/missionApi";
import { STATE_PENDING, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, STATE_UNKNOWN } from "../../api/missionApi";
import TimeDisplay from "../TimeDisplay";
import StateIcon from "../StateIcon";

export default function DroneCollectStatusMessage(props) {
    
    function droneCollectStatusIsTodo(mission, drone) {
        if (missionApi.droneStartedCollecting(mission, drone)) return false;
        if (missionApi.droneFinishedCollecting(mission, drone)) return false;
        return true;
    }

    function droneCollectStatusIsDoing(mission, drone) {
        if (!missionApi.droneStartedCollecting(mission, drone)) return false;
        if (missionApi.droneFinishedCollecting(mission, drone)) return false;
        return true;
    }

    function droneCollectStatusIsSucceeded(mission, drone) {
        if (!missionApi.droneSucceededCollecting(mission, drone)) return false;
        return true;
    }

    function droneCollectStatusIsFailed(mission, drone) {
        if (!missionApi.droneFailedCollecting(mission, drone)) return false;
        return true;
    }

    function getFlightCollectState(mission, flight) {
        if (flight?.state !== undefined) {
            return flight?.state;
        } else if (droneCollectStatusIsTodo(mission, flight.drone || { id: 'Some Drone' })) {
            return STATE_PENDING
        } else if (droneCollectStatusIsDoing(mission, flight.drone || { id: 'Some Drone' })) {
            return STATE_RUNNING
        } else if (droneCollectStatusIsSucceeded(mission, flight.drone || { id: 'Some Drone' })) {
            return STATE_SUCCEEDED
        } else if (droneCollectStatusIsFailed(mission, flight.drone || { id: 'Some Drone' })) {
            return STATE_FAILED
        } else {
            return STATE_UNKNOWN
        }
    }

    return (
        <span>
            <StateIcon
                state={getFlightCollectState(props.mission, props.flight)}
                className={"me-4"}
            />
            Flight by {props.flight?.drone?.id || "some drone"} at <TimeDisplay
                timestamp={props.flight.timestamp.seconds} 
                format="HH:mm:ss" 
                organizationId={props.organizationId} 
                locationId={props.locationId} 
            />
        </span>
    );
}
