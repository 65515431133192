import { BsBattery, BsBatteryCharging, BsBatteryFull, BsBatteryHalf } from "react-icons/bs";

import Stat from '../../components/Stat';

export default function DroneBatteryStat(props) {

    function isUndefined() {
        if (props.battery === undefined) return true;
        if (props.battery === 'n/a') return true;
        return false;
    }

    if (props.offline || isUndefined()) {
        return (
            <Stat
                label='Battery'
                text={<span className='text-secondary'>Offline</span>}
                icon={
                    <BsBatteryFull
                        className='ms-2 text-secondary'
                        style={{
                            marginTop: '-2px',
                        }}
                    />
                }
            />
        );
    } else if (props.battery >= 35 && props.battery <= 100) {
        return (
            <Stat
                label='Battery'
                text={<span className='text-success'>{`${props.battery}%`}</span>}
                icon={
                    <>
                        {props.charging && (
                            <BsBatteryCharging
                                className='ms-2 text-success'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                        {!props.charging && (
                            <BsBatteryFull
                                className='ms-2 text-success'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                    </>
                }
            />
        );
    } else if (props.battery >= 20 && props.battery < 35) {
        return (
            <Stat
                label='Battery'
                text={<span className='text-warning'>{`${props.battery}%`}</span>}
                icon={
                    <>
                        {props.charging && (
                            <BsBatteryCharging
                                className='ms-2 text-warning'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                        {!props.charging && (
                            <BsBatteryHalf
                                className='ms-2 text-warning'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                    </>
                }
            />
        );
    } else if (props.battery >= 0 && props.battery < 20) {
        return (
            <Stat
                label='Battery'
                text={<span className='text-danger'>{`${props.battery}%`}</span>}
                icon={
                    <>
                        {props.charging && (
                            <BsBatteryCharging
                                className='ms-2 text-danger'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                        {!props.charging && (
                            <BsBattery
                                className='ms-2 text-danger'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                    </>
                }
            />
        );
    } else /* props.battery < 0 || props.battery > 100 */ {
        return (
            <Stat
                label='Battery'
                text={<span className='text-danger'>Invalid</span>}
                icon={
                    <>
                        {props.charging && (
                            <BsBatteryCharging
                                className='ms-2 text-danger'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                        {!props.charging && (
                            <BsBattery
                                className='ms-2 text-danger'
                                style={{
                                    marginTop: '-2px',
                                }}
                            />
                        )}
                    </>
                }
            />
        );
    }
}
