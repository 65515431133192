import * as missionApi from "../../api/missionApi";
import { STATE_PENDING, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, STATE_UNKNOWN } from "../../api/missionApi";
import StateIcon from "../StateIcon";

export function uploadStatusIsTodo(mission) {
    return (!uploadStatusIsDoing(mission) && !uploadStatusIsFinished(mission));
}

export function uploadStatusIsDoing(mission) {
    return missionApi.anyDroneStartedUploading(mission) && !uploadStatusIsFinished(mission);
}

export function uploadStatusIsSucceeded(mission) {
    return missionApi.everyDroneSucceededUploading(mission);
}

export function uploadStatusIsFailed(mission) {
    return missionApi.anyDroneFailedUploading(mission);
}

export function uploadStatusIsFinished(mission) {
    return missionApi.everyDroneFinishedUploading(mission);
}

export default function UploadStatusMessage(props) {

    function getUploadState(mission) {
        if (mission?.states?.uploading !== undefined) {
            return mission?.states?.uploading;
        } else if (uploadStatusIsTodo(props.mission)) {
            return STATE_PENDING
        } else if (uploadStatusIsDoing(props.mission)) {
            return STATE_RUNNING
        } else if (uploadStatusIsSucceeded(props.mission)) {
            return STATE_SUCCEEDED
        } else if (uploadStatusIsFailed(props.mission)) {
            return STATE_FAILED
        } else {
            return STATE_UNKNOWN
        }
    }

    return (
        <span>
            <StateIcon
                state={getUploadState(props.mission)}
                className={"me-4"}
            />
            Uploading
        </span>
    );
}
