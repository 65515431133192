import { FaBullseye } from "react-icons/fa";
import Stat from '../../components/Stat';

export default function DroneAccuracyStat(props) {
    if (props.offline || props.accuracy === 'n/a') {
        return (
            <Stat
                label='Accuracy'
                text={<span className='text-secondary'>Offline</span>}
                icon={
                    <FaBullseye
                        className='ms-2 text-secondary'
                        style={{
                            marginTop: "-2px",
                            color: "#b9b9b9",
                        }}
                    />
                }
            />
        );
    } else if (props.accuracy < 0) { // negative
        return (
            <Stat
                label='Accuracy'
                text={<span className='text-danger'>Invalid</span>}
                icon={
                    <FaBullseye
                        className='ms-2 text-danger'
                        style={{
                            marginTop: "-2px",
                            color: "#b9b9b9",
                        }}
                    />
                }
            />
        );
    } else if (props.forAdmin) {
        if (props.accuracy >= 0 && props.accuracy < 0.5) { // precise
            return (
                <Stat
                    label='Accuracy'
                    text={<span className='text-success'>{props.accuracy}m</span>}
                    icon={
                        <FaBullseye
                            className='ms-2 text-success'
                            style={{
                                marginTop: "-2px",
                                color: "#b9b9b9",
                            }}
                        />
                    }
                />
            );
        } else if (props.accuracy > 0.5 && props.accuracy < 1.0) { // rough
            return (
                <Stat
                    label='Accuracy'
                    text={<span className='text-warning'>{props.accuracy}m</span>}
                    icon={
                        <FaBullseye
                            className='ms-2 text-warning'
                            style={{
                                marginTop: "-2px",
                                color: "#b9b9b9",
                            }}
                        />
                    }
                />
            );
        } else if (props.accuracy >= 10.0) {
            return (
                <Stat
                    label='Accuracy'
                    text={<span className='text-danger'>∞</span>}
                    icon={
                        <FaBullseye
                            className='ms-2 text-danger'
                            style={{
                                marginTop: "-2px",
                                color: "#b9b9b9",
                            }}
                        />
                    }
                />
            );
        } else /* props.accuracy > 1.0 && props.accuracy < 50.0 */ {
            return (
                <Stat
                    label='Accuracy'
                    text={<span className='text-danger'>{props.accuracy}m</span>}
                    icon={
                        <FaBullseye
                            className='ms-2 text-danger'
                            style={{
                                marginTop: "-2px",
                                color: "#b9b9b9",
                            }}
                        />
                    }
                />
            );
        }
    } else {
        if (props.accuracy >= 0 && props.accuracy < 0.5) { // precise
            return (
                <Stat
                    label='Accuracy'
                    text={<span className='text-success'>Accurate</span>}
                    icon={
                        <FaBullseye
                            className='ms-2 text-success'
                            style={{
                                marginTop: "-2px",
                                color: "#b9b9b9",
                            }}
                        />
                    }
                />
            );
        } else {
            return (
                <Stat
                    label='Accuracy'
                    text={<span className='text-danger'>Inaccurate</span>}
                    icon={
                        <FaBullseye
                            className='ms-2 text-danger'
                            style={{
                                marginTop: "-2px",
                                color: "#b9b9b9",
                            }}
                        />
                    }
                />
            );
        }
    }
}
