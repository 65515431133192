import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";

export default function TaskSelector(props) {
    const [tasks, setTasks] = React.useState([]);
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getTasks(organizationId, locationId, setTasks);
    }, [organizationId, locationId]);

    return (
        <Form.Select
            value={props.value || 'none'}
            onChange={(event) => props.onChange(event.target.value)}
        >
            <option disabled value="none">
                Select a task ...
            </option>
            {tasks.map((task) => {
                return (
                    <option
                        key={task.id}
                        value={task.id}
                    >
                        {task.name}
                    </option>
                );
            })}
        </Form.Select>
    );
}
