import React from "react";
import { ListGroup } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function FlightListItem(props) {
    return (
        <ListGroup.Item
            className="px-4 py-2 d-flex justify-content-between align-items-start"
            key={props.key}
            as={Link}
            to={props.to}
            action
        >
            <div>
                {props.children}
            </div>
            <div>
                <FaChevronRight style={{ color: "#b9b9b9" }} />
            </div>
        </ListGroup.Item>
    );
}
