import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaSave, FaTrash } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import CommandTypeSelector from "../../components/CommandTypeSelector";
import CorvusSetting from "../../components/CorvusSetting";
import Layout, {
    TaskCommandBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import urls from "../../urls.js";
import FlyToWaypointCommand from "./Commands/FlyToWaypointCommand";
import FlyToXYCommand from "./Commands/FlyToXYCommand";
import FlyToYawCommand from "./Commands/FlyToYawCommand";
import FlyToZCommand from "./Commands/FlyToZCommand";
import KapCommand from "./Commands/KapCommand";
import ScanZoneCommand from "./Commands/ScanZoneCommand";
import SetPayloadStateCommand from "./Commands/SetPayloadStateCommand";
import SetXYSpeedCommand from "./Commands/SetXYSpeedCommand";
import WaitForPeriodCommand from "./Commands/WaitForPeriodCommand";
import SetPictureDistanceCommand from "./Commands/SetPictureDistanceCommand";
import XYZCommand from "./Commands/XYZCommand";

export default function TaskCommandPage(props) {
    const [task, setTask] = React.useState(null);
    const { organizationId, locationId, taskId, commandId } = useParams();
    const history = useHistory();
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (taskCommand) => {
        onSaveTask();
    };

    function getMoveXyzSettings() {
        return {
            x: 0,
            y: 0,
            z: 0,
            delay: 1.0,
            velocity: 0,
            yaw: 0,
            action: "NO_ACTION",
        };
    }

    function getMoveKapSettings() {
        return {
            startKap: "0L",
            endKap: "0R",
            startLeg: "A",
            endLeg: "A",
        };
    }

    function getCommandSettings(commandType) {
        switch (commandType) {
            case "SCHEDULE_MOVE_XYZ":
                return getMoveXyzSettings();
            case "SCHEDULE_MOVE_KAP":
                return getMoveKapSettings();
            case "SCHEDULE_SET_PAYLOAD_RECORDING":
                return { recording: false };
            default:
                return {};
        }
    }

    function updateCommandSettings(key, value) {
        const newCommand = { ...task.commands[commandId] };
        newCommand[key] = value; // This is the new way
        newCommand.settings[key] = value; // TODO : this is the old way, remove it
        const newTask = { ...task };
        newTask.commands[commandId] = newCommand;
        setTask(newTask);
    }

    function updateCommandType(newCommandType) {
        const newCommand = { type: newCommandType, settings: {} };
        newCommand.settings = getCommandSettings(newCommandType);
        const newTask = { ...task };
        newTask.commands[commandId] = newCommand;
        setTask(newTask);
    }

    function deleteCommand() {
        const newTask = { ...task };
        newTask.commands.splice(commandId, 1);
        setTask(newTask);
        onSaveTask();
        history.push(urls.task(organizationId, locationId, taskId));
    }

    function onSaveTask() {
        api.saveTask(organizationId, locationId, taskId, task).then(() => history.push(urls.task(organizationId, locationId, taskId)));
    }

    React.useEffect(() => {
        if (taskId) {
            api.getTask(organizationId, locationId, taskId, setTask);
        }
    }, [organizationId, locationId, taskId]);

    return (
        <Layout>
            <TaskCommandBreadcrumbContainer />
            <Card>
                <Title
                    title={`Command Settings (${parseInt(commandId) + 1}/${task ? task.commands.length : 0
                        })`}
                />
                <Card.Body className="px-4 pt-0">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='mb-4'>
                            {task && (
                                <CorvusSetting name='Type'>
                                    <CommandTypeSelector
                                        value={task?.commands[commandId].type || ''}
                                        onSelect={updateCommandType}
                                    />
                                </CorvusSetting>
                            )}
                        </Form.Group>
                        {task?.commands[commandId].type === "SCHEDULE_FLY_TO_XY" && (
                            <FlyToXYCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_FLY_TO_Z" && (
                            <FlyToZCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_SET_PAYLOAD_RECORDING" && (
                            <SetPayloadStateCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_FLY_TO_YAW" && (
                            <FlyToYawCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_SET_XY_SPEED" && (
                            <SetXYSpeedCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_WAIT_FOR_PERIOD" && (
                            <WaitForPeriodCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_SET_PICTURE_DISTANCE" && (
                            <SetPictureDistanceCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_MOVE_XYZ" && (
                            <XYZCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_MOVE_KAP" && (
                            <KapCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                setValue={setValue}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_FLY_TO_WAYPOINT" && (
                            <FlyToWaypointCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                setValue={setValue}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        {task?.commands[commandId].type === "SCHEDULE_SCAN_ZONE" && (
                            <ScanZoneCommand
                                register={register}
                                handleSubmit={handleSubmit}
                                setValue={setValue}
                                formErrors={errors}
                                command={task.commands[commandId]}
                                updateCommandSettings={
                                    updateCommandSettings
                                }
                            />
                        )}
                        <Card.Text className="mt-3">
                            <Button
                                // disabled
                                variant="success"
                                className="me-2"
                                type="submit"
                            >
                                <FaSave className="me-2" />
                                Save
                            </Button>
                            <Button
                                variant="outline-danger"
                                onClick={deleteCommand}
                            >
                                <FaTrash className="me-2" />
                                Delete
                            </Button>
                        </Card.Text>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
}
