import React from "react";
import { Card } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";

export default function NotFoundPage() {
    return (
        <Layout>
            <Card.Body className="p-4">
                <Card.Text>
                    Nothing here...
                </Card.Text>
            </Card.Body>
        </Layout>
    );
}
