import React from "react";
import { FaLink } from "react-icons/fa";


export default function LogDevMenuItem(props) {

    // TODO : Make this the query for missions through the props:
    // jsonPayload.mission.id="missionId" OR jsonPayload.flight.id="flightId"


    function visitLog() {
        window.open(`https://console.cloud.google.com/logs/query;query=jsonPayload.${props.name}.id%3D%22${props.value}%22;cursorTimestamp=?project=corvus-backend`)
    }

    return (
        <props.as onClick={visitLog}>
            <FaLink
                className="me-2 text-primary"
                style={{ marginTop: "-2px" }}
            />
            Cloud Log
        </props.as>
    );
}
