// TODO : make this page

import React from "react";
import { Alert, Card, ListGroup } from "react-bootstrap";
//import { useParams } from "react-router-dom";
import * as api from "../../api/api";
import Layout, {
    Title
} from "../../components/Layout/Layout";
// import MeasurementListItem from "../../components/MeasurementListItem";

function AdminMeasurementList(props) {
    // const [organizations, setOrganizations] = React.useState([]);

    // React.useEffect(() => {
    //     return api.getOrganizations(setOrganizations);
    // }, [props.measurements]);

    // function getOrganizationName(organizationId) {
    //     const organization = organizations.find((o) => o.id === organizationId);
    //     return organization ? organization.name : 'Unknown';
    // }

    if (props.measurements.length <= 0) {
        return (
            <Alert variant="primary" className="mt-0 m-4">
                No measurements found
            </Alert>
        );
    }

    return (
        <>
            {props.measurements?.length > 0 && (
                <ListGroup variant='flush'>
                    {props.measurements.map((measurement) => (
                        <>Hi</>
                        // <MeasurementListItem
                        //     key={measurement.id}
                        //     measurement={measurement}
                        //     showActivity={true}
                        //     showMeasurements={true}
                        //     to={urls.organizationFlight(measurement.organizationId, measurement.locationId, measurement.id)}
                        // >
                        //     {`${measurement.name.split(' ')[1]} @ ${getOrganizationName(measurement.organizationId)}`}
                        // </MeasurementListItem>
                    ))}
                </ListGroup>
            )}
        </>
    );
}

function MeasurementByDateList(props) {
    const [measurementsByDate, setMeasurementsByDate] = React.useState({});

    React.useEffect(() => {
        const newMeasurementsByDate = {};
        props.measurements.forEach((measurement) => {
            const date = measurement.name.split(' ')[0];
            console.log(date);
            if (!newMeasurementsByDate[date]) {
                newMeasurementsByDate[date] = [];
            }
            newMeasurementsByDate[date].push(measurement);
        });
        setMeasurementsByDate(newMeasurementsByDate);
    }, [props.measurements]);

    return (
        <>
            {Object.keys(measurementsByDate).sort().reverse().map((date) => (
                <Card className='mt-4'>
                    {/* Flex space between */}
                    <Card.Header className="px-4 d-flex justify-content-between align-items-start">
                        <div><b>{date}</b></div>
                        <div className='text-muted'>
                            <small>
                                {measurementsByDate[date].length} measurements
                            </small>
                        </div>
                    </Card.Header>
                    {/* <AdminMeasurementList measurements={measurementsByDate[date]} /> */}
                    <AdminMeasurementList measurements={props.measurements} />
                </Card>
            ))}
        </>
    );
}

export default function AdminMeasurementListPage() {
    const [measurements, setMeasurements] = React.useState([]);
    //const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        return api.subscribeAdminMeasurements(setMeasurements);
    }, []);

    // function someMeasurementSucceededLastTenSeconds() {
    //     const now = new Date();
    //     return measurements.some((measurement) => {
    //         const finishedTime = measurementApi.getMeasurementFinishedTime(measurement);
    //         if (!finishedTime) return false;
    //         const finishedDate = new Date(finishedTime);
    //         // Return whether finishedDate is in the last 10 seconds or not
    //         return 
    //     });
    // }

    return (
        <>
            {/* {someMeasurementSucceededLastTenSeconds() && (
                <Confetti recycle={false} />
            )} */}
            <Layout>
                <Card>
                    <Title title='Measurements' />
                </Card>
                <MeasurementByDateList measurements={measurements} />
            </Layout>
        </>
    );
}
