import GenericFormGroup from './GenericFormGroup';
import { Form, InputGroup } from 'react-bootstrap';

const TextFormGroup = (props) => {
    return (
        <GenericFormGroup
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <>
                    <Form.Control
                        type="text"
                        placeholder={props.placeholder}
                        {...field}
                        isInvalid={!!errorMessage}
                        style={{ fontWeight: canResetToDefault ? 'bold' : 'normal' }}
                        list={props.suggestedOptions ? `${props.name}-options` : undefined}
                    />
                    {props.unitText && (
                        <InputGroup.Text>{props.unitText}</InputGroup.Text>
                    )}

                    {/* Conditionally render datalist if suggestedOptions are provided */}
                    {props.suggestedOptions && (
                        <datalist id={`${props.name}-options`}>
                            {props.suggestedOptions.map((option, index) => (
                                <option key={index} value={option} />
                            ))}
                        </datalist>
                    )}
                </>
            )}
        />
    );
};

export default TextFormGroup;
