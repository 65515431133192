import React from "react";
import { Badge, Card, ListGroup, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaChevronRight, FaClock } from "react-icons/fa";
import * as api from "../../api/api.jsx";
import Layout, {
    Title
} from "../../components/Layout/Layout.jsx";
import urls from "../../urls.js";
import moment from 'moment-timezone';

var parser = require("cron-parser");
var cronstrue = require("cronstrue/i18n");

function RoutineList({ routinesWithTimezone }) {
    const [organizations, setOrganizations] = React.useState([]);
    const userTimezone = moment.tz.guess();

    React.useEffect(() => {
        api.getOrganizations(setOrganizations);
    }, []);

    function getOrganizationName(organizationId) {
        const organization = organizations.find((o) => o.id === organizationId);
        return organization ? organization.name : 'Unknown';
    }

    function describeCronJob(cronString) {
        return cronstrue.toString(cronString, {
            throwExceptionOnParseError: false,
            locale: "en",
            use24HourTimeFormat: true,
            verbose: true
        });
    }

    if (routinesWithTimezone.length <= 0) {
        return null;
    }

    return (
        <>
            {routinesWithTimezone.filter(routine => routine.enabled).sort((a, b) => {
                const aNext = parser.parseExpression(a.interval, { tz: a.timezone }).next().toDate();
                const bNext = parser.parseExpression(b.interval, { tz: b.timezone }).next().toDate();
                return aNext - bNext;
            }).map(routine => {
                let nextExecutionFormatted = '';
                let localRoutineInfo = '';
                try {
                    const nextExecution = parser.parseExpression(routine.interval, { tz: routine.timezone }).next().toDate();
                    nextExecutionFormatted = moment(nextExecution, userTimezone).format('ddd HH:mm');
                    if (routine.timezone !== userTimezone) {
                        localRoutineInfo = `Local time (${routine.timezone}): ` + moment(nextExecution).tz(routine.timezone).format('ddd YYYY-MM-DD HH:mm') + '; ';
                    }
                } catch (error) {
                    console.error(`Error parsing interval for routine ${routine.id}:`, error);
                    nextExecutionFormatted = 'Invalid';
                }
                return (
                    <ListGroup.Item
                        className="px-4 py-2 d-flex justify-content-between align-items-start"
                        key={routine.id}
                        as={Link}
                        to={urls.routine(routine.organizationId, routine.locationId, routine.id)}
                        action
                        title={localRoutineInfo + describeCronJob(routine.interval)}
                    >
                        <div>
                            <FaClock className='me-4 text-primary' style={{ marginTop: "-2px" }} />
                            {routine.name} @ {getOrganizationName(routine.organizationId)} by {routine.droneId}
                        </div>
                        <div>
                            <Badge pill bg='primary' className='me-1'>
                                <FaClock className='me-2' style={{ marginTop: "-2px" }} />
                                {nextExecutionFormatted}
                            </Badge>
                            <FaChevronRight style={{ color: "#b9b9b9" }} />
                        </div>
                    </ListGroup.Item>
                )
            })}
        </>
    );
}

function RoutineByDateList({ routines }) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [routinesWithTimezoneByDate, setRoutinesWithTimezoneByDate] = React.useState({});
    const userTimezone = moment.tz.guess();

    React.useEffect(() => {
        const fetchRoutines = async () => {
            setIsLoading(true);

            const newRoutinesWithTimezoneByDate = {};

            for (const routine of routines) {
                if (routine.enabled) {
                    try {
                        const timezone = await api.getTimezone(routine.organizationId, routine.locationId);

                        const nextOccurrenceDate = parser.parseExpression(routine.interval, { tz: timezone }).next().toDate();
                        const localNextOccurrenceDate = moment.tz(nextOccurrenceDate, userTimezone);
                        const date = localNextOccurrenceDate.format('YYYY-MM-DD');
                        
                        if (!newRoutinesWithTimezoneByDate[date]) {
                            newRoutinesWithTimezoneByDate[date] = [];
                        }
                        
                        // Add timezone information to local object
                        routine.timezone = timezone;
                        newRoutinesWithTimezoneByDate[date].push(routine);
                    } catch (error) {
                        console.error(`Error fetching timezone or parsing interval for routine ${routine.id}:`, error);
                    }
                }
            }

            setRoutinesWithTimezoneByDate(newRoutinesWithTimezoneByDate);
            setIsLoading(false);
        };

        fetchRoutines();
    }, [routines, userTimezone]);
    return (
        isLoading ? (
            <Spinner animation="border" role="status" variant="primary" className="mt-4">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        ) :
            <>
                {Object.keys(routinesWithTimezoneByDate).sort().map((date) => (
                    <Card className='mt-4' key={date}>
                        <Card.Header className="px-4 d-flex justify-content-between align-items-start">
                            <div><b>{date}</b></div>
                            <div className='text-muted'>
                                <small>
                                    {routinesWithTimezoneByDate[date].length} routine(s)
                                </small>
                            </div>
                        </Card.Header>
                        <RoutineList routinesWithTimezone={routinesWithTimezoneByDate[date]} />
                    </Card>
                ))}
            </>
    );

}

export default function AdminRoutineListPage() {
    const [routines, setRoutines] = React.useState([]);

    React.useEffect(() => {
        return api.subscribeAdminRoutines(setRoutines);
    }, []);

    return (
        <>
            <Layout>
                <Card>
                    <Title title='Routines'>
                    </Title>
                </Card>
                <RoutineByDateList routines={routines} />
            </Layout>
        </>
    );
}
