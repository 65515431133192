import React from "react";
import { Badge, Button, Card, ListGroup, Spinner } from "react-bootstrap";
import { FaCheck, FaDownload, FaPlay, FaSync, FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";
import * as systemApi from "../../api/systemApi";
import BackButton from "../../components/BackButton";
import Layout, {
    Title
} from "../../components/Layout/Layout";
import urls from "../../urls.js";

function DownloadButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-primary"
            className={props.className}
            onClick={() => api.downloadSystem(props.drone, props.system)}
        >
            <FaDownload
                className="me-2"
            />
            Download
        </Button>
    );
}

function DownloadingButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-primary"
            className={props.className}
            disabled
        >
            <Spinner
                as="span"
                size="sm"
                className="me-2"
                style={{ marginTop: '2px' }}
            />
            Downloading
        </Button>
    );
}

function RunButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-success"
            className={props.className}
            onClick={() => api.runSystem(props.drone, props.system)}
        >
            <FaPlay
                className="me-2"
            />
            Run
        </Button>
    );
}

function RestartButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-secondary"
            className={props.className}
            onClick={() => api.executeRestartSystemCommand(props.drone.id)}
        >
            <FaSync
                className="me-2"
            />
            Restart
        </Button>
    );
}

function RunningButton(props) {
    return (
        <Button
            size="sm"
            variant="success"
            className={props.className}
            disabled
        >
            <FaCheck
                className="me-2"
            />
            Running
        </Button>
    );
}

function StartingButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-success"
            className={props.className}
            disabled
        >
            <Spinner
                as="span"
                size="sm"
                className="me-2"
                style={{ marginTop: '2px' }}
            />
            Starting
        </Button>
    );
}

function StoppingButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-danger"
            className={props.className}
            disabled
        >
            <Spinner
                as="span"
                size="sm"
                className="me-2"
                style={{ marginTop: '2px' }}
            />
            Stopping
        </Button>
    );
}

function DeleteButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-danger"
            className={props.className}
            onClick={() => api.deleteSystem(props.drone, props.system)}
        >
            <FaTrash
                className="me-2"
            />
            Delete
        </Button>
    );
}

function DeletingButton(props) {
    return (
        <Button
            size="sm"
            variant="outline-danger"
            className={props.className}
            disabled
        >
            <Spinner
                as="span"
                size="sm"
                className="me-2"
                style={{ marginTop: '2px' }}
            />
            Deleting
        </Button>
    );
}

function SystemOptionButtons(props) {
    return (
        <>
            {systemApi.isUnavailable(props.droneConfig, props.droneState, props.system) && (
                <DownloadButton
                    drone={props.droneId}
                    system={props.system}
                    className='me-2'
                />
            )}
            {systemApi.isDownloading(props.droneConfig, props.droneState, props.system) && (
                <DownloadingButton
                    drone={props.droneId}
                    system={props.system}
                    className='me-2'
                />
            )}
            {systemApi.isAvailable(props.droneConfig, props.droneState, props.system) && (
                <>
                    <RunButton
                        drone={props.droneId}
                        system={props.system}
                        className='me-2'
                    />
                    <DeleteButton
                        drone={props.droneId}
                        system={props.system}
                        className='me-2'
                    />
                </>
            )}
            {systemApi.isStarting(props.droneConfig, props.droneState, props.system) && (
                <StartingButton
                    drone={props.droneId}
                    system={props.system}
                    className='me-2'
                />
            )}
            {systemApi.isStopping(props.droneConfig, props.droneState, props.system) && (
                <StoppingButton
                    drone={props.droneId}
                    system={props.system}
                    className='me-2'
                />
            )}
            {systemApi.isRunning(props.droneConfig, props.droneState, props.system) && (
                <>
                    <RestartButton
                        drone={props.droneId}
                        system={props.system}
                        className='me-2'
                    />
                    <RunningButton
                        drone={props.droneId}
                        system={props.system}
                        className='me-2'
                    />
                </>
            )}
            {systemApi.isDeleting(props.droneConfig, props.droneState, props.system) && (
                <DeletingButton
                    drone={props.droneId}
                    system={props.system}
                    className='me-2'
                />
            )}
        </>
    );
}

export default function DroneSystemListPage() {
    const { organizationId, locationId, droneId } = useParams();
    const [systems, setSystems] = React.useState({});
    const [droneConfig, setDroneConfig] = React.useState(null);
    const [droneState, setDroneState] = React.useState(null);

    // Load all system systems
    React.useEffect(() => {
        api.getSystems((newSystems) => {
            setSystems(newSystems);
        });
    }, []);

    // Load the drone state
    React.useEffect(() => {
        if (!droneId) return;
        return api.subscribeDroneFeedback(droneId, setDroneState);
    }, [droneId]);

    // Load drone config
    React.useEffect(() => {
        if (!droneId) return;
        return api.subscribeDroneConfig(droneId, setDroneConfig);
    }, [droneId]);

    return (
        <Layout>
            <Card>
                <Title
                    title={
                        <>
                            <BackButton href={urls.locationDrone(organizationId, locationId, droneId)} />
                            Drone System

                        </>
                    }
                />
                <ListGroup variant="flush">
                    {Object.keys(systems)
                        .filter((system) => !systems[system].deprecated)
                        .map((system) =>
                        (
                            <ListGroup.Item
                                key={system}
                                className="d-flex justify-content-between align-items-center px-4"
                            >
                                <div>
                                    {system}
                                    {systems[system].deprecating && (
                                        <Badge bg="danger" className="ms-1">
                                            deprecating
                                        </Badge>
                                    )}
                                </div>
                                <div className="d-inline-flex">
                                    <SystemOptionButtons
                                        droneState={droneState}
                                        droneConfig={droneConfig}
                                        droneId={droneId}
                                        system={system}
                                    />
                                </div>
                            </ListGroup.Item>
                        ))}
                </ListGroup>
            </Card>
        </Layout>
    );
}
