import GenericFormGroup from './GenericFormGroup';
import { Form } from 'react-bootstrap';

const SwitchFormGroup = ({ options, disabledOption, ...props }) => {
    return (
        <GenericFormGroup
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <Form.Check
                    className={'mt-2'}
                    type="switch"
                    {...field}
                    isInvalid={!!errorMessage}
                    checked={props.checked}
                    style={{ fontWeight: canResetToDefault ? 'bold' : 'normal' }}>
                </Form.Check>
            )}
        />
    );
};

export default SwitchFormGroup;
