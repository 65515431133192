import React from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import * as api from "../../api/api.jsx";
import TextFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import BackButton from "../../components/BackButton/index.jsx";
import CancelButton from "../../components/CancelButton/index.jsx"
import Layout, {
    PayloadBreadcrumbContainer, Title
} from "../../components/Layout/Layout.jsx";
import { useUser } from "../../contexts/user_provider.jsx";
import urls from "../../urls.js";
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

// Use better message. The default message is 'Expected number, received nan' but that is strange because it comes from implementation details to convert text to number
const numberFromString = z.coerce.number({ invalid_type_error: "Enter a valid number" });

const payloadConfigSchema = z.object({
    targetAEV: numberFromString.int({ message: "Enter an integer" }).min(0).max(255),
    exposureUpperBound: numberFromString.int({ message: "Enter an integer" }).positive(),
    isoLowerBound: numberFromString.int({ message: "Enter an integer" }).positive(),
    isoUpperBound: numberFromString.int({ message: "Enter an integer" }).positive(),
}).refine((data) => data.isoUpperBound >= data.isoLowerBound, {
    message: "ISO upper bound must be higher than or equal to ISO lower bound",
    path: ["isoUpperBound"],
});

export default function PayloadSettingsPage() {
    const { payloadId } = useParams();
    const { getUserIsAdmin } = useUser();
    const { control, handleSubmit, reset, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(payloadConfigSchema)
    });
    const [isLoadingPayload, setIsLoadingPayload] = React.useState(false);
    const [isLoadingDroneConfigs, setIsLoadingDroneConfigs] = React.useState(false);
    const [payloadName, setPayloadName] = React.useState("");
    const [droneConfigs, setDroneConfigs] = React.useState([]);
    const [payloadDroneConfigs, setPayloadDroneConfigs] = React.useState([]);

    React.useEffect(() => {
        setIsLoadingPayload(true);
        api.getPayload(payloadId, (payloadConfig) => {
            setPayloadName(payloadConfig.name);
            setPayloadDroneConfigs(
                droneConfigs
                    .filter(droneConfig => droneConfig.payloadId === payloadId)
            );
            reset(payloadConfig);
            setIsLoadingPayload(false);
        });
    }, [payloadId, droneConfigs, reset]);

    React.useEffect(() => {
        setIsLoadingDroneConfigs(true);
        api.getAllDroneConfigs((configs) => {
            setDroneConfigs(configs);
            setIsLoadingDroneConfigs(false);
        });
    }, []);


    const onSubmit = async (payloadConfigUpdate) => {
        console.log(payloadConfigUpdate)
        await api.updatePayloadConfig(payloadId, payloadConfigUpdate);
        reset(payloadConfigUpdate);
    }

    return (
        <Layout>
            <PayloadBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.payloads()} />
                        {payloadName} Settings
                    </>
                } />
                <Card.Body className="p-4 py-0">
                    {!getUserIsAdmin() &&
                        <Card.Body className="p-4 py-0 mb-3">
                            Insufficient rights change payload settings
                        </Card.Body>}
                    {getUserIsAdmin() &&
                        <>
                            {isLoadingPayload || isLoadingDroneConfigs ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                <>
                                    {payloadDroneConfigs.length === 0
                                        ? 'This payload is used on no drone.'
                                        : (
                                            <span>This payload is used on {
                                                payloadDroneConfigs.map((droneConfig, index, array) =>
                                                    <React.Fragment key={droneConfig.id}>
                                                        <Link to={urls.locationDrone(droneConfig.organizationId,
                                                            droneConfig.locationId,
                                                            droneConfig.id)}
                                                        >
                                                            {droneConfig.id}
                                                        </Link>
                                                        {index < array.length - 1 ? ', ' : ''}
                                                    </React.Fragment>
                                                )
                                            }.</span>
                                        )
                                    }
                                    <p />
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        {getUserIsAdmin() &&
                                            <>
                                                <TextFormGroup label="Target AEV"
                                                    name="targetAEV"
                                                    control={control}
                                                    errors={errors} />
                                                <TextFormGroup label="Exposure upper bound"
                                                    name="exposureUpperBound"
                                                    control={control}
                                                    errors={errors}
                                                    unitText="µs" />
                                                <TextFormGroup label="ISO lower bound"
                                                    name="isoLowerBound"
                                                    control={control}
                                                    errors={errors} />
                                                <TextFormGroup label="ISO upper bound"
                                                    name="isoUpperBound"
                                                    control={control}
                                                    errors={errors} />
                                            </>
                                        }
                                        <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-1">
                                            <FaSave className="mb-1 me-1" />
                                            {isSubmitting ? "Saving..." : "Save"}
                                        </Button>
                                        <CancelButton
                                            className="ms-2 mb-3 mt-1"
                                            href={urls.payloads()}
                                        />
                                    </Form>
                                </>
                            )}
                        </>
                    }
                </Card.Body>
            </Card>
        </Layout>
    );
}
