import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function XYZCommand(props) {
    const setX = (event) =>
        props.updateCommandSettings("x", event.target.value ? parseFloat(event.target.value) : '');
    const setY = (event) =>
        props.updateCommandSettings("y", event.target.value ? parseFloat(event.target.value) : '');
    const setZ = (event) =>
        props.updateCommandSettings("z", event.target.value ? parseFloat(event.target.value) : '');
    const setYaw = (event) =>
        props.updateCommandSettings("yaw", event.target.value ? parseFloat(event.target.value) : '');

    return (
        <>
            <Form.Group>
                <CorvusSetting name='X'>
                    <Form.Control
                        autoFocus
                        {...props.register("command_flytowaypoint_x", {onChange: setX})}
                        defaultValue={props.command.settings.x}
                    />
                    <InputGroup.Text style={{ minWidth: "40px" }}>
                        m
                    </InputGroup.Text>
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Y'>
                    <Form.Control
                        {...props.register("command_flytowaypoint_y", {onChange: setY})}
                        defaultValue={props.command.settings.y}
                    />
                    <InputGroup.Text style={{ minWidth: "40px" }}>
                        m
                    </InputGroup.Text>
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Z'>
                    <Form.Control
                        defaultValue={props.command.settings.z}
                        {...props.register("command_flytowaypoint_z", {onChange: setZ})}
                    />
                    <InputGroup.Text style={{ minWidth: "40px" }}>
                        m
                    </InputGroup.Text>
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Yaw'>
                    <Form.Control
                        defaultValue={props.command.settings.yaw}
                        placeholder="Leave empty to use yaw of flyzone"
                        {...props.register("command_flytowaypoint_yaw", {onChange: setYaw})}
                    />
                    <InputGroup.Text style={{ minWidth: "40px" }}>
                        °
                    </InputGroup.Text>
                </CorvusSetting>
            </Form.Group>
        </>
    );
}
