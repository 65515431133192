import React from "react";
import { Form } from "react-bootstrap";
import { useUser } from "../../contexts/user_provider";
import * as api from "../../api/api";
import { useForm } from "react-hook-form";

export default function CommandTypeSelector(props) {
    const [commands, setCommands] = React.useState([]);
    const { getUserIsAdmin } = useUser();
    const { register } = useForm();

    React.useEffect(() => {
        api.getCommands(setCommands);
    }, []);

    return (
        <Form.Select
            value={props.value}
            {...register("command_type", {
                onChange: (event) => props.onSelect(event.target.value),
            })}
        >
            {commands.map((command) => (
                <>
                    {(!command.hide || getUserIsAdmin()) && (
                        <option value={command.type}>
                            {command.name}
                        </option>
                    )}
                </>
            ))}
        </Form.Select>
    )
}
