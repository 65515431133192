import * as missionApi from "../../api/missionApi";
import { STATE_PENDING, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, STATE_UNKNOWN } from "../../api/missionApi";
import StateIcon from "../StateIcon";

export default function CollectStatusMessage(props) {

    function collectStatusIsTodo(mission) {
        if (missionApi.anyDroneStartedCollecting(mission)) return false;
        if (missionApi.everyDroneFinishedCollecting(mission)) return false;
        return true;
    }

    function collectStatusIsDoing(mission) {
        if (!missionApi.anyDroneStartedCollecting(mission)) return false;
        if (missionApi.everyDroneFinishedCollecting(mission)) return false;
        return true;
    }

    function collectStatusIsSucceeded(mission) {
        if (!missionApi.everyDroneSucceededCollecting(mission)) return false;
        return true;
    }

    function collectStatusIsFailed(mission) {
        if (!missionApi.anyDroneFailedCollecting(mission)) return false;
        return true;
    }

    function getCollectState(mission) {
        if (mission?.states?.collecting !== undefined) {
            return mission?.states?.collecting;
        } else if (collectStatusIsTodo(props.mission)) {
            return STATE_PENDING
        } else if (collectStatusIsDoing(props.mission)) {
            return STATE_RUNNING
        } else if (collectStatusIsSucceeded(props.mission)) {
            return STATE_SUCCEEDED
        } else if (collectStatusIsFailed(props.mission)) {
            return STATE_FAILED
        } else {
            return STATE_UNKNOWN
        }
    }

    return (
        <span>
            <StateIcon
                state={getCollectState(props.mission)}
                className={"me-4"}
            />
            Collecting
        </span>
    );
}