import React from "react";
import { Button } from "react-bootstrap";
import { FaSave } from "react-icons/fa";

export default function SaveButton(props) {
    return (
        <Button
            variant="success"
            className={props.className}
            onClick={props.onClick}
        >
            <FaSave className="me-2" />
            Save
        </Button>
    );
}
