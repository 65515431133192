import React from "react";
import queryString from 'query-string';
import { Alert, Card, ListGroup } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import BackButton from "../../components/BackButton";
import Layout, {
    MissionListBreadcrumbContainer,
    Title
} from "../../components/Layout/Layout";
import LoadMoreButton from "../../components/LoadMoreButton";
import MissionListItem from "../../components/MissionListItem";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import * as api from "../../api/api";
import TimeDisplay from "../../components/TimeDisplay/index.jsx";

function MissionList(props) {
    const { getUserIsAdmin } = useUser();

    return (
        <>
            {props.missions?.length === 0 && (
                <Alert variant="primary" className="mt-0 m-4">
                    No missions found
                </Alert>
            )}
            {props.missions.length > 0 && (
                <ListGroup variant='flush'>
                    {props.missions.map((mission) => (
                        <MissionListItem
                            key={mission.id}
                            to={urls.locationMission(props.organizationId, props.locationId, mission.id)}
                            mission={mission}
                            showActivity={true}
                            showMeasurements={true}
                        >
                            <TimeDisplay
                                timestamp={mission.timestamp.seconds}
                                format="YYYY-MM-DD HH:mm:ss"
                                organizationId={props.organizationId}
                                locationId={props.locationId}                
                            />{getUserIsAdmin() && (
                                <> by {mission.drone.id}</>
                            )}
                        </MissionListItem>
                    ))}
                </ListGroup>
            )}
        </>
    );
}

export default function MissionListPage(props) { 
    const missionsPerPage = 50;
    const maxMissionsToLoad = 1000;

    const { organizationId, locationId } = useParams();
    const queryParams = queryString.parse(props.location.search);

    const [missions, setMissions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [missionsToLoad, setMissionsToLoad] = React.useState(missionsPerPage);
    const showDeleted = 'showDeleted' in queryParams;
    const history = useHistory();

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }

    React.useEffect(() => {
        console.log('Fetching missions ...');
        setIsLoading(true);

        let limit = missionsToLoad >= 0 ? Math.min(missionsToLoad, maxMissionsToLoad) : maxMissionsToLoad;

        return api.subscribeMissions(organizationId, locationId, (newMissions) => {
            setMissions(newMissions);
            setIsLoading(false);
        }, limit, showDeleted);

    }, [organizationId, locationId, missionsToLoad, maxMissionsToLoad, showDeleted]);

    return (
        <>
            <Layout>
                <MissionListBreadcrumbContainer />
                <Card>
                    <Title title={
                        <>
                            <BackButton href={urls.location(organizationId, locationId)} />
                            Missions
                        </>
                    } />
                    <MissionList
                        organizationId={organizationId}
                        locationId={locationId}
                        missions={missions}
                    />
                </Card>
                <LoadMoreButton
                    disabled={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    load={() => {
                        setMissionsToLoad(missionsToLoad + missionsPerPage);
                    }}
                    showLoadAll={true}
                    loadAll={() => setMissionsToLoad(-1)}
                    text={`Showing ${missions.length} missions`}
                />
            </Layout>
        </>
    );
}