import React from "react";
import { Button, Spinner } from "react-bootstrap";

export default function SavingButton(props) {
    return (
        <Button
            variant="outline-success"
            className={props.className}
            onClick={props.onClick}
        >
            <Spinner
                animation="border"
                role="status"
                size="sm"
                className="text-success me-2"
                style={{
                    marginTop: "-2px",
                }}
            />
            Saving ...
        </Button>
    );
}
