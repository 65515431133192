import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function SetXYSpeedCommand(props) {
    const setSpeed = (event) => props.updateCommandSettings("speed", event.target.value ? parseFloat(event.target.value) : '');

    return (
        <>
            <CorvusSetting name='Speed'>
                <Form.Control
                    autoFocus
                    {...props.register("command_setxyspeed_speed", { onChange: setSpeed })}
                    defaultValue={props.command.settings.speed}
                />
                <InputGroup.Text>
                    m/s
                </InputGroup.Text>
            </CorvusSetting>
        </>
    );
}
