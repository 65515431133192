import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import * as api from "../../api/api";

const TimeDisplay = ({ timestamp, format, hideDateIfToday, breakOnSpace, organizationId, locationId, location: providedLocation }) => {
    const [location, setLocation] = useState(providedLocation);

    useEffect(() => {
        if (!providedLocation) {
            return api.subscribeLocation(organizationId, locationId, setLocation);
        }
    }, [organizationId, locationId, providedLocation]);

    const formatTimestamp = (timezone, breakOnSpace) => {
        const dateMoment = moment(timestamp * 1000).tz(timezone);
        const isToday = dateMoment.isSame(moment().tz(timezone), 'day');
        let displayFormat = isToday && hideDateIfToday ? format.replace(/YYYY-MM-DD\s*/, '') : format;
        if (breakOnSpace && displayFormat.includes(' ')){
            displayFormat = displayFormat.replace(' ', ' <br /> ');
        }
        return dateMoment.format(displayFormat);
    };

    const userTimezone = moment.tz.guess();
    const localTimezone = location?.timezone || 'UTC';
    const isDifferentTimezone = userTimezone !== localTimezone;
    const titleText = isDifferentTimezone ? `Local Time (${localTimezone}): ${formatTimestamp(localTimezone, false)}` : '';

    return (
        <span title={titleText}>
            <span dangerouslySetInnerHTML={{ __html: formatTimestamp(userTimezone, breakOnSpace) }} />
        </span>
    );
};

export default TimeDisplay;