import { useEffect, useState } from 'react';

function useExpectedImages(flights) {
    const [expectedImages, setExpectedImages] = useState(undefined);

    useEffect(() => {
        let nr_images = Object.keys(flights).reduce((result, f) => {
            const measurements = flights[f]?.measurementInfo?.nr_measurements;
            // If measurements is undefined, assume 0 measurements for that flight
            if (measurements === undefined) {
                return result;
            }
            return result + measurements;
        }, 0);
        setExpectedImages(nr_images);
    }, [flights]);

    return expectedImages;
}

export default useExpectedImages;