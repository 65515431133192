import { Form } from "react-bootstrap";
import CorvusSetting from "../CorvusSetting";

export default function CorvusTextSetting(props) {
    return (
        <CorvusSetting
            name={props.name}
            className={props.className}
        >
            <Form.Control
                type="text"
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
            />
            {props.children}
        </CorvusSetting>
    );
}
