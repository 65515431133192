import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../../api/api";
import CorvusSetting from "../../../components/CorvusSetting";

// TODO : rename command to Scan Zones

export default function KapCommand(props) {
    const [areas, setAreas] = React.useState({});
    const { organizationId, locationId } = useParams();

    const setStartKap = (event) =>
        props.updateCommandSettings("startKap", event.target.value);
    const setEndKap = (event) =>
        props.updateCommandSettings("endKap", event.target.value);
    const setStartLeg = (event) =>
        props.updateCommandSettings("startLeg", event.target.value);
    const setEndLeg = (event) =>
        props.updateCommandSettings("endLeg", event.target.value);

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getAreas(organizationId, locationId, setAreas);
        }
    }, [organizationId, locationId]);

    function validateKap(kap) {
        return kap in areas;
    }

    function validateLeg(validKap, legToValidate) {
        if (areas[validKap] === undefined) return false;
        return Object.keys(areas[validKap]).includes(legToValidate);
    }

    return (
        <>
            <CorvusSetting name='Start'>
                <Form.Control
                    autoFocus
                    isInvalid={props.formErrors.command_kap_start_kap}
                    value={props.command.settings.startKap}
                    {...props.register("command_kap_start_kap", {
                        onChange: setStartKap,
                        validate: validateKap,
                    })}
                    placeholder="Start kap"
                />
                <Form.Control
                    isInvalid={props.formErrors.command_kap_start_leg}
                    value={props.command.settings.startLeg}
                    {...props.register("command_kap_start_leg", {
                        onChange: setStartLeg,
                        // validate: validateLeg,
                        validate: (legToValidate) => validateLeg(props.command.settings.startKap, legToValidate),
                    })}
                    placeholder="Start leg"
                />
            </CorvusSetting>
            <CorvusSetting name='End'>
                <Form.Control
                    isInvalid={props.formErrors.command_kap_end_kap}
                    {...props.register("command_kap_end_kap", {
                        onChange: setEndKap,
                        validate: validateKap,
                    })}
                    defaultValue={props.command.settings.endKap}
                    placeholder="End kap"
                />
                <Form.Control
                    isInvalid={props.formErrors.command_kap_end_leg}
                    {...props.register("command_kap_end_leg", {
                        onChange: setEndLeg,
                        // validate: validateLeg,
                        validate: (legToValidate) => validateLeg(props.command.settings.endKap, legToValidate),
                    })}
                    defaultValue={props.command.settings.endLeg}
                    placeholder="End leg"
                />
            </CorvusSetting>
        </>
    );
}
