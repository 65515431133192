import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function SetPictureDistanceCommand(props) {
    const setDistance = (event) => props.updateCommandSettings("picture_distance", event.target.value ? parseFloat(event.target.value) : 0);

    return (
        <>
            <CorvusSetting name='Distance'>
                <Form.Control
                    autoFocus
                    {...props.register("command_setpicturedistance_picture_distance", { onChange: setDistance })}
                    defaultValue={props.command.settings.picture_distance}
                />
                <InputGroup.Text>
                    m
                </InputGroup.Text>
            </CorvusSetting>
        </>
    );
}
