import React from "react";
import {
    Alert, Card
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    CorvusList
} from "../../components/CorvusList";
import MissionListItem from "../../components/MissionListItem";
import urls from "../../urls.js";
import { useUser } from "../../contexts/user_provider";
import TimeDisplay from "../TimeDisplay/index.jsx";

export default function MissionList(props) {
    const { getUserIsAdmin } = useUser();

    return (
        <Card className={props.className}>
            <Card.Header className="d-flex justify-content-between">
                <div style={{ fontWeight: 'bold' }}>
                    {props.title || 'Missions'}
                </div>
                <Link
                    to={urls.locationMissions(props.organizationId, props.locationId)}
                >
                    View all
                </Link>
            </Card.Header>
            {props.missions.length === 0 && (
                <Card.Body>
                    <Alert className="text-center mb-0" variant="primary">
                        <div className="text-muted">
                            No missions
                        </div>
                    </Alert>
                </Card.Body>
            )}
            {props.missions.length > 0 && (
                <CorvusList>
                    {props.children}
                    {props.missions.map((mission) => (
                        <MissionListItem
                            key={mission.id}
                            mission={mission}
                            showActivity={true}
                            showMeasurements={true}
                            to={urls.locationMission(props.organizationId, props.locationId, mission.id)}
                        >
                            <TimeDisplay
                                timestamp={mission.timestamp.seconds}
                                format="YYYY-MM-DD HH:mm:ss"
                                organizationId={props.organizationId}
                                locationId={props.locationId}                
                            />{getUserIsAdmin() && (
                                <> by {mission.drone.id}</>
                            )}
                        </MissionListItem>
                    ))}
                </CorvusList>
            )}
        </Card>
    );
}