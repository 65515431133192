import React from "react";
import { FaLink } from "react-icons/fa";


export default function StorageDevMenuItem(props) {

    function visitStorage() {
        window.open(`https://console.cloud.google.com/storage/browser/corvus-backend.appspot.com/${props.path};tab=objects?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=corvus-backend`);
    }

    return (
        <props.as onClick={visitStorage}>
            <FaLink
                className="me-2 text-primary"
                style={{ marginTop: "-2px" }}
            />
            Storage
        </props.as>
    );
}
