import React from "react";
import {
    Alert, Button, Card, Container, Form,
    FormControl
} from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth_provider";
import urls from "../../urls.js";

export default function ResetPage() {
    const history = useHistory();
    const [email, setEmail] = React.useState("");
    const emailRef = React.useRef("");
    const { resetPasswordLink } = useAuth();
    const [success, setSuccess] = React.useState();

    function doSendResetLink(event) {
        event.preventDefault();

        // Define the message once
        const message = `If the email ${email} is linked to an account, we have sent a link to reset your password. Click the link to continue.`;

        if (email != null) {
            resetPasswordLink(email)
                .then(() => {
                    setSuccess(message);
                })
                .catch(() => {
                    setSuccess(message);
                });
        } else {
            setSuccess("Please enter an email!");
        }
    }

    return (
        <>
            <div className="my-5 text-center">
                <img
                    onClick={() => history.push(urls.home())}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                    src={process.env.PUBLIC_URL + "/logo-black-white.png"}
                    alt="none"
                />
            </div>
            <Container style={{ maxWidth: "400px" }}>
                {success && (
                    <Alert
                        variant="success"
                        className="mb-4"
                        onClose={() => setSuccess(undefined)}
                    >
                        <FaCheckCircle
                            className="me-2 text-success"
                            style={{ marginTop: "-2px" }}
                        />
                        {success}
                    </Alert>
                )}
                {!success && (
                    <Card>
                        <Card.Body>
                            <Form onSubmit={doSendResetLink}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Email address</b>
                                    </Form.Label>
                                    <FormControl
                                        type="email"
                                        ref={emailRef}
                                        value={email}
                                        onChange={(event) =>
                                            setEmail(event.target.value)
                                        }
                                    />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button
                                        type="submit"
                                        onClick={doSendResetLink}
                                    >
                                        <b>Send link</b>
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                )}
            </Container>
            <div className="text-center mt-3">
                Have a login?{" "}
                <Link to={urls.login()}>
                    Login
                </Link>
            </div>
        </>
    );
}
