import React from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CancelButton from "../../components/CancelButton";
import CorvusTextSetting from "../../components/CorvusTextSetting";
import Layout, {
    TaskSettingsBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import { ProgressingSaveButton } from "../../components/ProgressingSaveButton";
import urls from "../../urls.js";

export default function TaskSettingsPage() {
    const [task, setTask] = React.useState();
    const [saved, setSaved] = React.useState(true);
    const { organizationId, locationId, taskId } = useParams();
    const history = useHistory();

    React.useEffect(() => {
        if (taskId) {
            api.getTask(organizationId, locationId, taskId, setTask);
        }
    }, [organizationId, locationId, taskId]);

    function isNewTask() {
        return taskId === undefined;
    }

    function onSaveTask() {
        if (isNewTask()) {
            return api.createTask(organizationId, locationId, task)
                .then((taskId) => {
                    history.push(urls.task(organizationId, locationId, taskId));
                    setSaved(true);
                });
        } else {
            return api.saveTask(organizationId, locationId, taskId, task)
                .then(() => {
                    setSaved(true);
                });
        }
    }

    function changeTask(newKeyValues) {
        setTask({ ...task, ...newKeyValues, });
        setSaved(false);
    }

    function setName(event) {
        changeTask({ name: event.target.value });
    }

    function getBackUrl() {
        if (isNewTask()) {
            return urls.tasks(organizationId, locationId);
        } else {
            return urls.task(organizationId, locationId, taskId);
        }
    }

    // function setDescription(event) {
    //     changeTask({ description: event.target.value });
    // }

    return (
        <Layout>
            <TaskSettingsBreadcrumbContainer />
            <Card>
                {isNewTask() && (
                    <Title title={
                        <>
                            <BackButton href={getBackUrl()} />
                            Create Task
                        </>
                    } />
                )}
                {!isNewTask() && (
                    <Title title={
                        <>
                            <BackButton href={getBackUrl()} />
                            Task Settings
                        </>
                    } />
                )}
                <Card.Body className="p-4 py-0">
                    <CorvusTextSetting
                        name="Name"
                        defaultValue={task && task.name}
                        placeholder="Task Name ..."
                        onChange={setName}
                    />
                    {/* <CorvusTextSetting
                        name="Description"
                        className="mb-2"
                        defaultValue={task && task.description}
                        placeholder="Task Description ..."
                        onChange={setDescription}
                    /> */}
                </Card.Body>
                <Card.Body className="p-4">
                    <Card.Text>
                        <ProgressingSaveButton
                            onSave={onSaveTask}
                            saved={saved}
                            setSaved={setSaved}
                            className="me-2"
                        />
                        <CancelButton
                            href={getBackUrl()}
                            className="me-2"
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        </Layout>
    );
}
