import React from "react";
import GenericFormGroup from './GenericFormGroup';
import { Form, Row, Col } from 'react-bootstrap';
import './CheckFormGroup.css'; // Import custom CSS file for styling

const CheckFormGroup = (props) => {
    const [selected, setSelected] = React.useState(props.options.map(() => true));

    const handleDayToggle = (superCallback, event, index) => {
        let newSelected = [...selected];
        newSelected[index] = !selected[index];
        setSelected(newSelected);

        event.target.value = newSelected;
        let newEvent = {
            ...event,
            target: { value: newSelected }
        }
        superCallback(newEvent)
    };

    const CustomCheckboxLabel = ({ htmlFor, label }) => {
        return (
            <span htmlFor={htmlFor} style={{ cursor: 'pointer', width: "150px" }}>{label}</span>
        );
    };

    return (
        <GenericFormGroup
            as={Row}
            className="checkbox-group"
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <>
                    {field.value && setSelected(field.value)}
                    {Object.keys(props.options).map((_, index) =>
                    (
                        < Col key={index} xs={1} md={1} lg={1} >
                            <Form.Check
                                className={selected[index] ? 'round-selected' : 'round'}
                                type="checkbox"
                                {...field}
                                checked={selected[index]}
                                onChange={(e) => handleDayToggle(field.onChange, e, index)}
                                label={<CustomCheckboxLabel htmlFor={props.options[index] + "_id"} label={props.labels[index]} />}
                                id={props.options[index] + "_id"}
                                style={{ margin: '10px' }}
                                reverse={true}
                                value={selected[index]}
                                isInvalid={!!errorMessage}
                            />
                        </Col>
                    )
                    )
                    }
                </>
            )
            }
        />
    );
};

export default CheckFormGroup;
