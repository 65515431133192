// TODO : `React.lazy` could optimize imports throughout the project
// TODO : react optimization: [https://reactjs.org/docs/optimizing-performance.html]()
// TODO : loading skeletons: [https://www.npmjs.com/package/react-loading-skeleton]()
// TODO : load no card if any list of a list view is empty (avoids random lines)
// TODO : Interesting improvement: https://reactjs.org/docs/concurrent-mode-suspense.html
// TODO : Best would be creating a GraphQL api and use that for data fetching.

// TODO : a nice future improvement would be to disconnect after some inactive time.
// that way if the user keeps a tab open not all the updates are streamed which saves
// huge on firestore read/write costs

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import urls from "../urls.js";
import "./App.css";

import DemoPage from "../pages/DemoPage";
import DroneListPage from "../pages/DroneListPage";
import LoginPage from "../pages/LoginPage";
import ResetPage from "../pages/ResetPage";
// import UserProfilePage from "../pages/UserProfilePage";
import DronePage from "../pages/DronePage";
import FeedbackPage from "../pages/FeedbackPage";
// import SupportPage from "../pages/SupportPage";
import ProtectedRoute from "../components/ProtectedRoute";
import AdminMeasurementListPage from "../pages/AdminMeasurementPage/index.jsx";
import AdminMissionListPage from "../pages/AdminMissionListPage";
import AdminRoutineListPage from "../pages/AdminRoutineListPage";
import AppListPage from "../pages/AppListPage";
import DockListPage from "../pages/DockListPage";
import DockPage from "../pages/DockPage";
import DockSettingsPage from "../pages/DockSettingsPage";
import DroneCreatePage from "../pages/DroneCreatePage";
import DroneNerdPage from "../pages/DroneNerdPage";
import DroneSettingsPage from "../pages/DroneSettingsPage";
import DroneSystemListPage from "../pages/DroneSystemListPage";
import FlightListPage from "../pages/FlightListPage";
import FlightPage from "../pages/FlightPage";
import ImageListPage from "../pages/ImageListPage";
import ImagePage from "../pages/ImagePage";
import LocationListPage from "../pages/LocationListPage";
import LocationPage from "../pages/LocationPage";
import LocationSettingsPage from "../pages/LocationSettingsPage";
import MapPage from "../pages/MapPage";
import MapboxPage from "../pages/MapboxPage";
import MissionListPage from "../pages/MissionListPage";
import MissionPage from "../pages/MissionPage";
import NewPasswordPage from "../pages/NewPasswordPage";
import NotFoundPage from "../pages/NotFoundPage";
import OrganizationListPage from "../pages/OrganizationListPage";
import PayloadListPage from "../pages/PayloadListPage";
import PayloadSettingsPage from "../pages/PayloadSettingsPage";
import RoutineListPage from "../pages/RoutineListPage";
import RoutinePage from "../pages/RoutinePage";
import RoutineSettingsPage from "../pages/RoutineSettingsPage";
import StartPage from "../pages/StartPage";
import TaskAnalysePage from "../pages/TaskAnalysePage";
import TaskCommandPage from "../pages/TaskCommandPage";
import TaskListPage from "../pages/TaskListPage";
import TaskPage from "../pages/TaskPage";
import TaskSettingsPage from "../pages/TaskSettingsPage";
import LivestreamPage from "../pages/LivestreamPage/index.jsx";

export default function App() {

    return (
        <Router>
            <Switch>
                <ProtectedRoute exact path={urls.home()} component={LocationListPage} />

                {/* Auth pages */}
                {/* <Route exact path="/demo" component={DemoPage} /> */}
                <Route exact path={urls.login()} component={LoginPage} />
                <Route exact path="/reset" component={ResetPage} />
                {/* <Route exact path="/reset_password" component={PasswordPage} /> */}{/* TODO */}
                {/* <Route exact path={urls.support()} component={SupportPage} /> */}

                {/* TODO : url string to urls.newPasswordPage() */}
                <Route exact path="/newPasswordPage/:code" component={NewPasswordPage} />

                {/* User pages */}
                {/* <ProtectedRoute exact path={urls.profile()} component={UserProfilePage} /> */}
                <ProtectedRoute exact path={urls.feedback()} component={FeedbackPage} />

                <ProtectedRoute exact path={urls.locationMap(':organizationId', ':locationId')} component={MapPage} />
                <ProtectedRoute exact path={urls.locationMapbox(':organizationId', ':locationId')} component={MapboxPage} />

                {/* Application pages */}
                <ProtectedRoute exact path={urls.apps(':organizationId', ':locationId')} component={AppListPage} />

                {/* Drone pages */}
                <ProtectedRoute exact path={urls.locationDroneSettings(':organizationId', ':locationId', ':droneId')} component={DroneSettingsPage} />
                <ProtectedRoute exact path={urls.locationDrone(':organizationId', ':locationId', ':droneId')} component={DronePage} />
                <ProtectedRoute exact path={urls.droneCreate(':organizationId', ':locationId')} component={DroneCreatePage} />
                <ProtectedRoute exact path={urls.drone(':droneId')} component={DronePage} />
                <ProtectedRoute exact path={urls.locationDrones(':organizationId', ':locationId')} component={DroneListPage} />
                <ProtectedRoute exact path={urls.locationDroneNerd(':organizationId', ':locationId', ':droneId')} component={DroneNerdPage} />
                <ProtectedRoute exact path={urls.droneSystemList(':organizationId', ':locationId', ':droneId')} component={DroneSystemListPage} />
                <ProtectedRoute exact path={urls.locationDroneLivestream(':organizationId', ':locationId', ':droneId')} component={LivestreamPage} />


                {/* Mission pages */}
                <ProtectedRoute exact path={urls.locationMissions(':organizationId', ':locationId')} component={MissionListPage} />
                <ProtectedRoute exact path={urls.allMissions()} component={AdminMissionListPage} />
                <ProtectedRoute exact path={urls.allMeasurements()} component={AdminMeasurementListPage} />
                <ProtectedRoute exact path={urls.mission(':missionId')} component={MissionPage} />
                <ProtectedRoute exact path={urls.locationMission(':organizationId', ':locationId', ':missionId')} component={MissionPage} />
                <ProtectedRoute exact path={urls.measurements(':organizationId', ':locationId')} component={ImageListPage} />
                {/* TODO : decouple ImagePage from missionId, this should be a param so we can also set flightId instead */}
                <ProtectedRoute exact path={urls.measurement(':organizationId', ':locationId', ':missionId', ':measurementId')} component={ImagePage} />

                {/* Flight pages */}
                <ProtectedRoute exact path={urls.flight(':organizationId', ':locationId', ':flightId')} component={FlightPage} />
                <ProtectedRoute exact path={urls.flights(':organizationId', ':locationId')} component={FlightListPage} />

                <ProtectedRoute exact path={urls.aFlight(':flightId')} component={FlightPage} />

                {/* Dock pages */}
                <ProtectedRoute exact path={urls.docks(':organizationId', ':locationId')} component={DockListPage} />
                <ProtectedRoute exact path={urls.dockCreate(':organizationId', ':locationId')} component={DockSettingsPage} />
                <ProtectedRoute exact path={urls.dock(':organizationId', ':locationId', ':dockId')} component={DockPage} />
                <ProtectedRoute exact path={urls.dockSettings(':organizationId', ':locationId', ':dockId')} component={DockSettingsPage} />

                {/* Task pages */}
                <ProtectedRoute exact path={urls.tasks(':organizationId', ':locationId',)} component={TaskListPage} />
                {/* <ProtectedRoute exact path={urls.locationTasks(':locationId', ':locationId')} component={TaskListPage} /> */}
                <ProtectedRoute exact path={urls.taskCreate(':organizationId', ':locationId')} component={TaskSettingsPage} />
                <ProtectedRoute exact path={urls.task(':organizationId', ':locationId', ':taskId')} component={TaskPage} />
                <ProtectedRoute exact path={urls.taskSettings(':organizationId', ':locationId', ':taskId')} component={TaskSettingsPage} />
                <ProtectedRoute exact path={urls.taskCommand(':organizationId', ':locationId', ':taskId', ':commandId')} component={TaskCommandPage} />
                <ProtectedRoute exact path={urls.taskAnalyse(':organizationId', ':locationId', ':taskId', ':analyseId')} component={TaskAnalysePage} />

                {/* Routine pages */}
                <ProtectedRoute exact path={urls.routines(':organizationId', ':locationId')} component={RoutineListPage} />
                {/* <ProtectedRoute exact path={urls.locationRoutines(':locationId')} component={RoutineListPage} /> */}
                <ProtectedRoute exact path={urls.allRoutines()} component={AdminRoutineListPage} />
                <ProtectedRoute exact path={urls.routineCreate(':organizationId', ':locationId')} component={RoutineSettingsPage} />
                <ProtectedRoute exact path={urls.routineSettings(':organizationId', ':locationId', ':routineId')} component={RoutineSettingsPage} />
                <ProtectedRoute exact path={urls.routine(':organizationId', ':locationId', ':routineId')} component={RoutinePage} />
                <ProtectedRoute exact path={urls.demo()} component={DemoPage} />
                {/* <ProtectedRoute exact path={urls.locationMarkers(':locationId')} component={MarkerPage} /> */}

                <ProtectedRoute exact path={urls.locationStart(':organizationId', ':locationId')} component={StartPage} />
                <ProtectedRoute exact path={urls.locationCreate(':organizationId')} component={LocationSettingsPage} />
                <ProtectedRoute exact path={urls.location(':organizationId', ':locationId')} component={LocationPage} />
                <ProtectedRoute exact path={urls.locationSettings(':organizationId', ':locationId')} component={LocationSettingsPage} />
                <ProtectedRoute exact path={urls.organization(':organizationId')} component={LocationListPage} />
                <ProtectedRoute exact path={urls.organizations()} component={OrganizationListPage} />
                <ProtectedRoute exact path={urls.locations(':organizationId')} component={LocationListPage} />

                <ProtectedRoute exact path={urls.payloads()} component={PayloadListPage} />
                <ProtectedRoute exact path={urls.payloadSettings(':payloadId')} component={PayloadSettingsPage} />

                {/* Other pages */}
                <ProtectedRoute component={NotFoundPage} />

            </Switch>
        </Router>

    );
}
