import React, { createContext, useContext, useState } from "react";
import * as api from "../api/api";
import { useAuth } from "../contexts/auth_provider";

const UserRole = {
    ADMIN: "admin",
    USER: "user",
};

export const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const { user, userClaims } = useAuth();
    const [role, setRole] = React.useState(UserRole.USER);
    const [organizations, setOrganizations] = useState([]);

    // Setting userRole
    React.useEffect(() => {
        // setRole(userClaims.role) // TODO
        setRole(
            userClaims && userClaims.isAdmin ? UserRole.ADMIN : UserRole.USER
        );
    }, [userClaims]);

    // Setting organizations
    React.useEffect(() => {
        if (role === UserRole.ADMIN) {
            api.getOrganizations(setOrganizations); // TODO : make a subscription
        }
    }, [role]);

    const value = {
        organizations,
        role, // TODO : not export this
        getUserIsAdmin: () => role === UserRole.ADMIN,
        setUserRole: () => setRole(UserRole.USER),
        setAdminRole: () => setRole(UserRole.ADMIN),
        userClaims,
        user,
    };

    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};
