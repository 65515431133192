import { BsBattery, BsBatteryCharging, BsBatteryFull, BsBatteryHalf } from "react-icons/bs";
import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function DroneCard(props) {

    // Handle the display of the battery
    let icon = <BsBattery
        className='ms-2'
        style={{
            marginTop: '-2px',
        }}
    />;
    let batteryTextColor = 'text-danger';
    if (props.charging) {
        icon = (
            <BsBatteryCharging
                className='ms-2'
                style={{
                    marginTop: '-2px',
                }}
            />
        );
        batteryTextColor = 'text-success'
    } else if (props.battery < 25) {
        icon = <BsBattery
            className='ms-2'
            style={{
                marginTop: '-2px',
            }}
        />;
        batteryTextColor = 'text-danger'
    } else if (props.battery < 70) {
        icon = <BsBatteryHalf
            className='ms-2'
            style={{
                marginTop: '-2px',
            }}
        />;
        batteryTextColor = 'text-warning'
    } else {
        icon = <BsBatteryFull
            className='ms-2'
            style={{
                marginTop: '-2px',
            }}
        />;
        batteryTextColor = 'text-success'
    }

    return (
        <Card
            onClick={props.onClick}
            className='text-success text-decoration-none'
            style={{ borderRadius: '0' }}
            as={Link}
            to={props.to}>
            <Card.Body className="d-flex justify-content-between align-middle">
                <div className={"align-self-center " + props.stateColor}>
                    <h4 className="m-0 p-0">{props.name}</h4>
                    <p className="m-0 p-0">{props.state}</p>
                </div>
                {!props.offline && (
                    <div className={"align-self-center " + batteryTextColor}>
                        <h4>
                            {icon} {props.battery}%
                        </h4>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}
