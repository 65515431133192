// Here, this might be an improvement:
// https://xrutayisire.github.io/react-js-cron/?path=/docs/reactjs-cron--demo

import moment from 'moment-timezone';
import React from "react";
import { Button, ButtonGroup, Card, Dropdown, DropdownButton, Modal, Spinner } from "react-bootstrap";
import { FaCode, FaCog, FaDatabase, FaStopwatch, FaTrash } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import Layout, { RoutineBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import RoutinePreview from "../../components/RoutinePreview";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import TimeDisplay from '../../components/TimeDisplay/index.jsx';
const parser = require("cron-parser");

export default function RoutinePage() {
    const [routine, setRoutine] = React.useState();
    const [showFirebaseData, setShowFirebaseData] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const { organizationId, locationId, routineId } = useParams();
    const history = useHistory();
    const [routineTask, setRoutineTask] = React.useState();
    const [routineDrone, setRoutineDrone] = React.useState();
    const [routineMission, setRoutineMission] = React.useState();
    const [location, setLocation] = React.useState(null);
    const { getUserIsAdmin } = useUser();

    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    function visitRoutines() {
        history.push(urls.routines(organizationId, locationId));
    }

    function onDeleteRoutine() {
        api.deleteRoutine(organizationId, locationId, routineId).then(visitRoutines);
    }

    function handleClose() {
        setShowDeleteModal(false);
    }

    function handleShow() {
        setShowDeleteModal(true);
    }

    React.useEffect(() => {
        if (organizationId && routineId) {
            api.subscribeRoutine(organizationId, locationId, routineId, setRoutine);
        }
    }, [organizationId, locationId, routineId]);

    React.useEffect(() => {
        if (routine?.droneId !== routineDrone?.id) {
            console.log(`Loading drone '${routine.droneId}' ...`);
            api.getDroneConfig(routine.droneId, setRoutineDrone);
        }
    }, [routine, routineDrone]);

    React.useEffect(() => {
        if (routine?.taskId !== routineTask?.id) {
            api.getTask(organizationId, locationId, routine.taskId, setRoutineTask);
        }
    }, [organizationId, locationId, routine, routineTask]);

    React.useEffect(() => {
        if (routine?.missionId?.length === 0 || routine?.missionId === undefined) {
            setRoutineMission(undefined);
        }
        else if (routine?.missionId !== routineMission?.id) {
            api.subscribeMission(organizationId, locationId, routine.missionId, setRoutineMission);
        }
    }, [organizationId, locationId, routine, routineMission]);

    function visitFirestore() {
        const organizationPath = `organizations/${organizationId}`;
        const locationPath = `${organizationPath}/locations/${locationId}`;
        const routinePat = `${locationPath}/routines/${routineId}`;

        window.open(`https://console.firebase.google.com/u/0/project/corvus-backend/firestore/data/~2F${routinePat}`);
    }


    let routineDisplayContent;
    try {
        routineDisplayContent =
            routine ?
                <>
                    <RoutinePreview
                        className='m-0 mb-4'
                        organizationId={organizationId}
                        locationId={locationId}
                        location={location}
                        routineTask={routineTask}
                        routineDrone={routineDrone}
                        routineMission={routineMission}
                        routine={routine}
                    />
                    {
                        routine.enabled ? (
                            <>
                                Routine will start a new mission at {<code><TimeDisplay
                                    timestamp={moment(parser.parseExpression(routine.interval, { tz: location?.timezone || 'UTC' }).next().toDate()).unix()}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    organizationId={organizationId}
                                    locationId={locationId}
                                /></code>}
                            </>) : "Routine is disabled"
                    }
                </>
                :
                <Spinner animation="border" role="status" variant='primary'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
    } catch (error) {
        console.log("Unable to parse routine", error);
        routineDisplayContent = "Routine is invalid";
    }


    return (
        <Layout>
            <Modal show={showDeleteModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Routine</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure to permanently delete this routine?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={onDeleteRoutine}
                    >
                        <FaTrash
                            className="me-2"
                            style={{ marginTop: "-2px" }}
                        />
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <RoutineBreadcrumbContainer />
            {
                <Card className="m-0 mb-4">
                    <Title title={
                        <>
                            <BackButton href={urls.routines(organizationId, locationId)} />
                            {routine?.name || 'Routine'}
                        </>
                    }>

                        <ButtonGroup>
                            <Button
                                variant="secondary"
                                as={Link}
                                to={urls.routineSettings(organizationId, locationId, routineId)}
                            >
                                <FaCog
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Settings
                            </Button>
                            <DropdownButton
                                variant="secondary"
                                align="end"
                                as={ButtonGroup}
                            >
                                {getUserIsAdmin() &&
                                    <>
                                        <Dropdown.Item
                                            as={Link}
                                            to={`${urls.demo()}?organizationId=${organizationId}&locationId=${locationId}&routineId=${routineId}`}
                                        >
                                            <FaStopwatch
                                                className="me-2"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            Demo page
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={visitFirestore}>
                                            <FaDatabase
                                                className="me-2"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            View Firestore
                                        </Dropdown.Item>
                                        {showFirebaseData ? (
                                            <Dropdown.Item
                                                onClick={() => setShowFirebaseData(false)}
                                            >
                                                <FaCode
                                                    className="me-2"
                                                    style={{
                                                        marginTop: "-2px",
                                                    }}
                                                />
                                                Hide data
                                            </Dropdown.Item>
                                        ) : (
                                            <Dropdown.Item
                                                onClick={() => setShowFirebaseData(true)}
                                            >
                                                <FaCode
                                                    className="me-2"
                                                    style={{
                                                        marginTop: "-2px",
                                                    }}
                                                />
                                                Show Data
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Divider />
                                    </>
                                }
                                <Dropdown.Item
                                    className="text-danger"
                                    onClick={handleShow}
                                >
                                    <FaTrash
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Delete
                                </Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>
                    </Title>
                    <Card.Body className="p-4 pt-0">
                        {routineDisplayContent}
                    </Card.Body>
                    {showFirebaseData && routine && (
                        <Card.Body className="p-4 pt-0">
                            <CodeBlock name='Routine' code={routine} />
                        </Card.Body>
                    )}
                </Card>
            }
        </Layout>
    );
}
