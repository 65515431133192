import React from "react";
import { Alert, Badge, Button, Card } from "react-bootstrap";
import Countdown from "react-countdown";
import { FaBan, FaClock, FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList
} from "../../components/CorvusList";
import Layout, {
    RoutineListBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import ListItemWithToggle from "../../components/ListItemWithToggle";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import moment from "moment";
var parser = require("cron-parser");

function RoutineItemWithToggle(props) {
    const { organizationId, locationId } = useParams();
    const [timezone, setTimezone] = React.useState('UTC');
    const [nextExecutionTime, setNextExecutionTime] = React.useState(0);
    const { user } = useUser();

    const getDate = React.useCallback((interval) => {
        try {
            return moment(parser.parseExpression(interval, { tz: timezone }).next().getTime()).toDate();
        } catch {
            return 0;  // Return 0 if parsing fails
        }
    }, [timezone]);

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getTimezone(organizationId, locationId).then((timezone) => setTimezone(timezone));
        }
    }, [organizationId, locationId]);

    React.useEffect(() => {
        setNextExecutionTime(getDate(props.routine.interval));
    }, [timezone, getDate, props.routine.interval]);



    function setRoutineEnabled(enabled) {
        const promises = [
            api.updateRoutine(organizationId, locationId, props.routine.id, { enabled }),
        ];

        if (enabled) {
            promises.push(
                api.updateRoutine(organizationId, locationId, props.routine.id, {
                    enabler: user.uid,
                })
            );
        }

        return promises;
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <>
                {completed && "Starting"}
                {days > 0 && `${days}d `}
                {hours > 0 && `${hours}h `}
                {minutes > 0 && `${minutes}m `}
                {seconds > 0 && `${seconds}s `}
            </>
        );
    };

    return (
        <ListItemWithToggle
            itemKey={props.routine.id}
            item={props.routine}
            text={props.routine.name || 'Unnamed Routine'}
            enabled={props.routine.enabled}
            onDisable={() => setRoutineEnabled(false)}
            onEnable={() => setRoutineEnabled(true)}
            to={urls.routine(organizationId, locationId, props.routine.id)}
            badges={
                props.enabled === true ? (
                    <Badge pill bg='primary' className='me-1'>
                        <FaClock className='me-2' style={{ marginTop: "-2px" }} />
                        {
                            nextExecutionTime !== 0 ?
                                <Countdown
                                    className='d-inline'
                                    date={nextExecutionTime}
                                    key={nextExecutionTime}
                                    renderer={renderer}
                                    autoStart={true}
                                    intervalDelay={0}
                                    onComplete={() => {
                                        // Wait 1 second before updating the next execution time to ensure we set it to the next interval
                                        setTimeout(() => {
                                            setNextExecutionTime(
                                                getDate(props.routine.interval)
                                            );
                                        }, 1000);
                                    }}
                                /> : "Invalid"
                        }
                    </Badge>
                ) : (
                    <Badge pill bg='secondary' className='me-1'>
                        <FaBan className='mx-1' />
                    </Badge>
                )
            }
        />
    );
}

export default function RoutineListPage(props) {
    const { getUserIsAdmin } = useUser();
    const history = useHistory();
    const { organizationId, locationId } = useParams();
    const [routines, setRoutines] = React.useState([]);
    const [location, setLocation] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }

    // Subscribe to location
    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [getUserIsAdmin, organizationId, locationId]);

    React.useEffect(() => {
        if (organizationId) {
            return api.subscribeRoutines(organizationId, locationId, setRoutines);
        }
    }, [organizationId, locationId]);

    React.useEffect(() => {
        if (organizationId) {
            api.getTasks(organizationId, locationId, setTasks);
        }
    }, [organizationId, locationId]);

    return (
        <Layout>
            <RoutineListBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Routines
                    </>
                }>
                    <Button
                        variant="outline-success"
                        as={Link}
                        to={urls.routineCreate(organizationId, locationId)}
                    >
                        <FaPlus className="me-2" />
                        Add routine
                    </Button>
                </Title>
                {(location && (!location.assistant || !location.assistant.enabled)) && (
                    <Alert variant="danger" className="mx-4 mb-4">
                        Assistant is not enabled for this location. Routines won't work.
                    </Alert>
                )}
                {routines.length === 0 && (
                    <Alert variant="primary" className="mx-4 mb-4">
                        No routines
                    </Alert>
                )}
                {routines.length > 0 && (
                    <CorvusList>
                        {routines
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((routine) => (
                                <RoutineItemWithToggle
                                    key={routine.id}
                                    tasks={tasks}
                                    routine={routine}
                                    text={`Routine 123`}
                                    enabled={routine.enabled}
                                    onDisable={() => console.log("disabling ...")}
                                    onEnable={() => console.log("enabling ...")}
                                    to={urls.routine(organizationId, locationId, routine.id)}
                                />
                            ))}
                    </CorvusList>
                )}
            </Card>
        </Layout>
    );
}
