import React from "react";
import { PixiComponent, useApp } from "@inlet/react-pixi";
import { Viewport as PixiViewport } from "pixi-viewport";

const PixiComponentViewport = PixiComponent("Viewport", {

    create: (props) => {
        const viewport = new PixiViewport({
            screenWidth: props.screenWidth,
            screenHeight: props.screenHeight,
            screenWorldWidth: props.worldWidth,
            screenWorldHeight: props.worldHeight,

            ticker: props.app.ticker,
            interaction: props.app.renderer.plugins.interaction,
        });

        viewport.drag().pinch().wheel();

        viewport.bounce({
            sides: 'all',
            friction: 0.0,
            time: 0,
            // bounceBox: props.purpleBox,
            ease: 'easeInOutSine',
            underflow: 'center',
        })

        // viewport.fit();
        // viewport.moveCenter(props.worldWidth / 2, props.worldHeight / 2)
        // viewport.moveCenter(props.worldWidth / 2, props.worldHeight / 2);
        // viewport.zoom(10);

        // props.app.ticker.add((delta) => {
        //     viewport.setZoom(0.5);
        // })

        // props.app.ticker.add((delta) => {
        //     console.log(`Visible: ${viewport.getVisibleBounds()}`);
        // })

        // props.app.ticker.add((delta) => {
        //     console.log(`Scale ${viewport.scale.x}`);
        // })

        return viewport;
    }
});

function Viewport(props) {
    const app = useApp();
    return <PixiComponentViewport app={app} {...props} />;
};

export default Viewport;
