// (mission) EVENTS
// part of mission document
// const MISSION_INITIATED = 'FLIGHT_INITIATED';

// // (measurement) EVENTS // TODO
// const MEASUREMENT_INITIATED = INITIATED;
// const MEASUREMENT_CAPTURED = 'CAPTURED';
// const MEASUREMENT_UPLOADED = 'UPLOADED';
// const MEASUREMENT_TRANSFERRED = 'TRANSFERRED';

// (mission step 1) COLLECTING EVENTS
// (flight) EVENTS
// a flight is one way of collecting
const COLLECTING_STARTED = 'FLIGHT_STARTED'; // TODO : rename to name
const COLLECTING_SUCCEEDED = 'FLIGHT_SUCCEEDED'; // TODO : rename to name
const COLLECTING_FAILED = 'FLIGHT_FAILED'; // TODO : rename to name

// (mission step 2) UPLOADING EVENTS
// part of flight & measurement document
const UPLOADING_STARTED = 'FLIGHT_SUCCEEDED'; // TODO : rename to name
const UPLOADING_SUCCEEDED = 'FLIGHT_UPLOADED'; // TODO : rename to name
const UPLOADING_FAILED = 'FLIGHT_FAILED'; // TODO : rename to name

// // TODO : emit these events throughout the system ...
// part of mission & measurement document
// // (mission step 3) TRANSFERRING EVENTS
// const TRANSFERRING_STARTED = 'TRANSFERRING_STARTED';
// const TRANSFERRING_SUCCEEDED = 'TRANSFERRING_SUCCEEDED';
// const TRANSFERRING_FAILED = 'TRANSFERRING_FAILED';

// (mission step 4) PROCESSING EVENTS
const PROCESSING_STARTED = 'APP_STARTED'; // TODO : rename to name
const PROCESSING_SUCCEEDED = 'APP_SUCCEEDED'; // TODO : rename to name
const PROCESSING_FAILED = 'APP_FAILED'; // TODO : rename to name

export const STATE_PENDING = 'pending'
export const STATE_RUNNING = 'running'
export const STATE_SUCCEEDED = 'succeeded'
export const STATE_FAILED = 'failed'
export const STATE_CANCELLED = 'cancelled'
export const STATE_UNKNOWN = 'unknown'

export const PHASE_COLLECTING = 'collecting'
export const PHASE_UPLOADING = 'uploading'
export const PHASE_PROCESSING = 'processing'


function filterEventsByDrone(events, drone) {
    return events.filter((event) => event.drone && event.drone.id === drone.id);
}

function filterEventsByApp(events, app) {
    return events.filter((event) => event.app && event.app.id === app?.id);
}

function filterEventsByTypes(events, eventTypes) {
    return events.filter((event) => eventTypes.includes(event.type));
}

function anyEventsByTypes(events, eventTypes) {
    return filterEventsByTypes(events, eventTypes).length > 0;
}

function getLatestEvent(events) {
    return events[events.length - 1] || null;
}

function latestEventIsOfType(events, eventType) {
    return getLatestEvent(events)?.type === eventType;
}

export function getMissionFlights(mission) {
    return mission?.flights || [];
}

export function getMissionDrones(mission) {
    if (mission?.drones) return mission.drones;
    if (mission?.drone) return [mission.drone];
    return [];
}

export function getMissionApps(mission) {
    return mission.apps || [];
}

// DRONE COLLECTING INDIVIDUAL

export function droneStartedCollecting(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [COLLECTING_STARTED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, COLLECTING_STARTED);
}

export function droneSucceededCollecting(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [COLLECTING_SUCCEEDED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, COLLECTING_SUCCEEDED);
}

export function droneFailedCollecting(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [COLLECTING_FAILED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, COLLECTING_FAILED);
}

export function droneFinishedCollecting(mission, drone) {
    return droneFailedCollecting(mission, drone) || droneSucceededCollecting(mission, drone);
}

// DRONE COLLECTING AGGREGATE

export function anyDroneStartedCollecting(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneStartedCollecting(mission, drone);
    });
}

export function everyDroneSucceededCollecting(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.every((drone) => {
        return droneSucceededCollecting(mission, drone);
    });
}

export function anyDroneFailedCollecting(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneFailedCollecting(mission, drone);
    });
}

export function everyDroneFinishedCollecting(mission) {
    return anyDroneFailedCollecting(mission) || everyDroneSucceededCollecting(mission);
}

// DRONE UPLOADING INDIVIDUAL

export function droneStartedUploading(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [UPLOADING_STARTED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, UPLOADING_STARTED);
}

export function droneSucceededUploading(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [UPLOADING_SUCCEEDED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, UPLOADING_SUCCEEDED);
}

export function droneFailedUploading(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [UPLOADING_FAILED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, UPLOADING_FAILED);
}

export function droneFinishedUploading(mission, drone) {
    return droneSucceededUploading(mission, drone) || droneFailedUploading(mission, drone);
}

// DRONE UPLOADING AGGREGATE

export function anyDroneStartedUploading(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneStartedUploading(mission, drone);
    });
}

export function everyDroneSucceededUploading(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.every((drone) => {
        return droneSucceededUploading(mission, drone);
    });
}

export function anyDroneFailedUploading(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneFailedUploading(mission, drone);
    });
}

export function everyDroneFinishedUploading(mission) {
    return anyDroneFailedUploading(mission) || everyDroneSucceededUploading(mission);
}

// APP PROCESSING INDIVIDUAL

export function appStartedProcessing(mission, app) {
    const appEvents = filterEventsByApp(mission.events, app);
    const eventTypesOfInterest = [PROCESSING_STARTED];
    const events = filterEventsByTypes(appEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, PROCESSING_STARTED);
}

export function appSucceededProcessing(mission, app) {
    const appEvents = filterEventsByApp(mission.events, app);
    const eventTypesOfInterest = [PROCESSING_SUCCEEDED];
    return anyEventsByTypes(appEvents, eventTypesOfInterest);
}

export function appFailedProcessing(mission, app) {
    const appEvents = filterEventsByApp(mission.events, app);
    const eventTypesOfInterest = [PROCESSING_STARTED, PROCESSING_SUCCEEDED, PROCESSING_FAILED];
    const events = filterEventsByTypes(appEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, PROCESSING_FAILED);
}

export function appFinishedProcessing(mission, app) {
    return appFailedProcessing(mission, app) || appSucceededProcessing(mission, app);
}

// APP PROCESSING AGGREGATE

export function anyAppStartedProcessing(mission) {
    const apps = getMissionApps(mission);
    return apps.length !== 0 && apps.some((app) => {
        return appStartedProcessing(mission, app);
    });
}

export function everyAppSucceededProcessing(mission) {
    const apps = getMissionApps(mission);
    return apps.length !== 0 && apps.every((app) => {
        return appSucceededProcessing(mission, app);
    });
}

export function anyAppFailedProcessing(mission) {
    const apps = getMissionApps(mission);
    return apps.length !== 0 && apps.some((app) => {
        return appFailedProcessing(mission, app);
    });
}

export function everyAppFinishedProcessing(mission) {
    return anyAppFailedProcessing(mission) || everyAppSucceededProcessing(mission);
}

// MISSION PROGRESS

export function missionStarted(mission) {
    return anyDroneStartedCollecting(mission);
}

export function missionSucceeded(mission) {
    return [
        everyDroneSucceededCollecting(mission),
        everyDroneSucceededUploading(mission),
        everyAppSucceededProcessing(mission),
    ].every(e => e);
}

export function missionFailed(mission) {
    return [
        anyDroneFailedCollecting(mission),
        anyDroneFailedUploading(mission),
        anyAppFailedProcessing(mission),
    ].some(e => e);
}

export function missionFinished(mission) {
    return missionFailed(mission) || missionSucceeded(mission);
}

// MISSION STATUS

export function missionStatusIsTodo(mission) {
    if (missionStarted(mission)) return false;
    if (missionFinished(mission)) return false;
    return true;
}

export function missionStatusIsDoing(mission) {
    if (!missionStarted(mission)) return false;
    if (missionFinished(mission)) return false;
    return true;
}

export function missionStatusIsFailed(mission) {
    if (!missionFailed(mission)) return false;
    return true;
}

export function missionStatusIsSucceeded(mission) {
    if (!missionSucceeded(mission)) return false;
    return true;
}

// MISSION ACTIVITY

export function missionActivityIsCollecting(mission) {
    const startedCollecting = anyDroneStartedCollecting(mission);
    const finishedCollecting = everyDroneFinishedCollecting(mission);
    return startedCollecting && !finishedCollecting
}

export function missionActivityIsUploading(mission) {
    const startedUploading = anyDroneStartedUploading(mission);
    const finishedUploading = everyDroneFinishedUploading(mission);
    return startedUploading && !finishedUploading;
}

export function missionActivityIsProcessing(mission) {
    const startedProcessing = anyAppStartedProcessing(mission);
    const finishedProcessing = everyAppFinishedProcessing(mission);
    return startedProcessing && !finishedProcessing;
}

export function missionActivityIsFinished(mission) {
    return everyAppFinishedProcessing(mission);
}

export function missionActivityIsWaiting(mission) {
    if (missionActivityIsCollecting(mission)) return false;
    if (missionActivityIsUploading(mission)) return false;
    if (missionActivityIsProcessing(mission)) return false;
    if (everyAppFinishedProcessing(mission)) return false;
    return true;
}

export function getMissionActivity(mission) {
    if (mission.states) {
        const states = mission.states;
        const stateValues = [states.collecting, states.uploading, states.processing];

        if (states.processing === STATE_SUCCEEDED) {
            return STATE_SUCCEEDED;
        }
        if (states.processing === STATE_FAILED) {
            return STATE_FAILED;
        }

        if (stateValues.every(state => state === STATE_PENDING)) {
            return STATE_PENDING;
        }
        if (stateValues.every(state => state === STATE_SUCCEEDED)) {
            return STATE_SUCCEEDED;
        }
        if (stateValues.every(state => state === STATE_CANCELLED)) {
            return STATE_CANCELLED;
        }
        if (stateValues.includes(STATE_FAILED)) {
            return STATE_FAILED;
        }
        if (stateValues.includes(STATE_RUNNING)) {
            if (states.collecting === STATE_RUNNING) return PHASE_COLLECTING;
            if (states.uploading === STATE_RUNNING) return PHASE_UPLOADING;
            if (states.processing === STATE_RUNNING) return PHASE_PROCESSING;
        }
        // Handle the situation inbetween states
        if (states.collecting === STATE_SUCCEEDED && states.uploading === STATE_PENDING) {
            return PHASE_UPLOADING;
        }
        if (states.uploading === STATE_SUCCEEDED && states.processing === STATE_PENDING) {
            return PHASE_PROCESSING;
        }
        return "unknown";
    }
    else {
        if (everyAppFinishedProcessing(mission)) return 'finished';
        if (missionActivityIsCollecting(mission)) return PHASE_COLLECTING;
        if (missionActivityIsUploading(mission)) return PHASE_UPLOADING;
        if (missionActivityIsProcessing(mission)) return PHASE_PROCESSING;
        else return 'waiting';
    }
}

export function getFailedEvents(mission) {
    const eventTypesOfInterest = [COLLECTING_FAILED, UPLOADING_FAILED, PROCESSING_FAILED];
    return filterEventsByTypes(mission.events, eventTypesOfInterest);
}
