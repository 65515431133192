import React from "react";
import { Form } from "react-bootstrap";

export default function OverlaySelector(props) {
    return (
        <div>
            <Form.Select
                value={props.selectedOverlay}
                onChange={(event) => {
                    props.onChange(event.target.value)
                }}
            >
                <option value="">
                    None
                </option>
                {props.overlays.map((overlay) => {
                    return (
                        <option
                            key={overlay}
                            value={overlay}
                        >
                            {overlay}
                        </option>
                    );
                })}
            </Form.Select>
        </div>
    );
}
