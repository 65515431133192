import React from "react";
import { Card } from "react-bootstrap";

export default function Stat(props) {
    return (
        <Card onClick={props.onClick}>
            <Card.Body className="d-flex justify-content-between align-middle">
                <div className="align-self-center">
                    <h2 className="m-0 p-0">{props.text}</h2>
                    <p className="m-0 p-0">{props.label}</p>
                </div>
                <div className="align-self-center">
                    <h2>
                        {props.icon}
                    </h2>
                </div>
            </Card.Body>
        </Card>
    );
}
