import { InputGroup } from "react-bootstrap";

export default function CorvusSettingLabel(props) {
    return (
        <InputGroup.Text style={{ minWidth: "125px" }}>
            <small>
                <b>{props.name}</b>
            </small>
        </InputGroup.Text>
    );
}
