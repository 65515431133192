// TODO : display errors on this page

import moment from 'moment-timezone';
import React from "react";
import { Alert, Badge, Button, ButtonGroup, Card, Col, Dropdown, DropdownButton, ListGroup, Modal, Row } from "react-bootstrap";
import { FaChevronRight, FaClock, FaCode, FaCog, FaMap, FaPlay, FaTrash, FaTrashRestore } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import * as firestoreApi from "../../api/firestoreApi.js";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import DevMenuButton from "../../components/DevMenuButton";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import Layout, {
    LocationBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import LogDevMenuItem from "../../components/LogDevMenuItem";
import MapButton from "../../components/MapButton";
import MissionList from "../../components/MissionList";
import Stat from '../../components/Stat';
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import DroneCardList from '../../components/DroneCardList/index.jsx';
import TimeDisplay from '../../components/TimeDisplay/index.jsx';
var parser = require("cron-parser");

function LocationMissionList(props) {
    const { organizationId, locationId } = useParams();
    const [missions, setMissions] = React.useState([]);
    const [routines, setRoutines] = React.useState([]);
    const [timezone, setTimezone] = React.useState('UTC');

    React.useEffect(() => {
        if (organizationId && locationId) {
            const activeRoutines = routines.filter(routine => routine.enabled);
            console.log(Math.max(props.limit - activeRoutines.length, 0));
            return api.subscribeMissions(organizationId, locationId, setMissions, Math.max(props.limit - activeRoutines.length, 1), false);
        }
    }, [organizationId, locationId, props.limit, routines]);

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getTimezone(organizationId, locationId).then((timezone) => setTimezone(timezone));
            api.subscribeRoutines(organizationId, locationId, setRoutines);
        }
    }, [organizationId, locationId]);

    return (
        <MissionList
            title='Missions'
            className={props.className}
            routines={routines}
            organizationId={organizationId}
            locationId={locationId}
            missions={missions}
        >
            {routines.filter(routine => routine.enabled).sort((a, b) => {
                const aNext = parser.parseExpression(a.interval, { tz: timezone }).next().toDate();
                const bNext = parser.parseExpression(b.interval, { tz: timezone }).next().toDate();
                return aNext - bNext;
            }).map(routine => {
                let timeDisplayContent;
                try {
                    const nextOccurrence = parser.parseExpression(routine.interval, { tz: timezone }).next().toDate();
                    const timestamp = moment(nextOccurrence).unix();

                    timeDisplayContent = (
                        <TimeDisplay
                            timestamp={timestamp}
                            format="ddd HH:mm"
                            organizationId={organizationId}
                            locationId={locationId}
                        />
                    );
                } catch (error) {
                    console.error(`Error parsing interval for routine ${routine.id}:`, error);
                    timeDisplayContent = 'Invalid';  // Display 'Invalid' if parsing fails
                }

                return (
                    <ListGroup.Item
                        className="px-4 py-2 d-flex justify-content-between align-items-start"
                        key={props.key}
                        as={Link}
                        to={urls.routine(organizationId, locationId, routine.id)}
                        action
                    >
                        <div>
                            <FaClock className='me-4 text-primary' style={{ marginTop: "-2px" }} />
                            {routine.name} by {routine.droneId}
                        </div>
                        <div>
                            <Badge pill bg='primary' className='me-1' style={{ marginTop: "-2px" }}>
                                <FaClock className='me-2' style={{ marginTop: "-2px" }} />
                                {timeDisplayContent}
                            </Badge>
                            <FaChevronRight style={{ color: "#b9b9b9" }} />
                        </div>
                    </ListGroup.Item>
                )
            })}
        </MissionList>
    );
}

function AdminLocationButton(props) {
    const { organizationId, locationId } = useParams();
    const { getUserIsAdmin } = useUser();

    return (
        <ButtonGroup
            className={props.className}
        >
            <Button
                variant="primary"
                as={Link}
                to={urls.locationStart(organizationId, locationId)}
            >
                <FaPlay
                    className="me-2"
                    style={{
                        marginTop: "-2px",
                    }}
                />
                Start
            </Button>
            {getUserIsAdmin() && (
                <DropdownButton
                    variant="primary"
                    align="end"
                    as={ButtonGroup}
                    title=''
                >
                    <Dropdown.Item as={Link} to={urls.locationSettings(organizationId, locationId)}>
                        <FaCog
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Settings
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={props.showDeleteModal}
                        className="text-danger"
                    >
                        <FaTrash
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Delete
                    </Dropdown.Item>
                </DropdownButton>
            )}

        </ButtonGroup>
    );
}

export default function LocationPage(props) {
    const { organizationId, locationId } = useParams();
    const [drones, setDrones] = React.useState([]);
    const [droneFeedback, setDroneFeedback] = React.useState({});
    const [location, setLocation] = React.useState();
    const [tasks, setTasks] = React.useState([]);
    const [routines, setRoutines] = React.useState([]);
    const [view, setView] = React.useState("default");
    const { getUserIsAdmin } = useUser();
    const history = useHistory();
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

    function hideDeleteModal() {
        setDeleteModalVisible(false);
    }

    function showDeleteModal() {
        setDeleteModalVisible(true);
    }

    // Subscribe to location
    React.useEffect(() => {
        if (getUserIsAdmin()) {
            return api.subscribeLocation(organizationId, locationId, setLocation);
        }
    }, [getUserIsAdmin, organizationId, locationId]);

    React.useEffect(() => {
        api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        api.getDroneConfigs(organizationId, locationId, setDrones);
        api.getTasks(organizationId, locationId, setTasks);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        setDroneFeedback({});
        if (drones && drones.length > 0) {
            // Array to store unsubscribe functions
            const unsubscribeFunctions = drones.map(drone => {
                const upperDroneId = drone.id.toUpperCase();
                // Subscribe to drone feedback and return the unsubscribe function
                return api.subscribeDroneFeedback(upperDroneId, (newFeedback) => {
                    setDroneFeedback(prevFeedbacks => ({
                        ...prevFeedbacks,      // Keep previous feedbacks
                        [upperDroneId]: newFeedback  // Update the feedback for this specific drone
                    }));
                });
            });

            // Cleanup function to unsubscribe from all drone feedback subscriptions
            return () => {
                unsubscribeFunctions.forEach(unsubscribe => {
                    if (typeof unsubscribe === 'function') {
                        unsubscribe();
                    }
                });
            };
        }
    }, [drones]);

    React.useEffect(() => {
        api.getRoutines(organizationId, locationId, setRoutines);
    }, [organizationId, locationId]);

    function getEnabledRoutineIndicatorVariant() {
        if (routines.filter(routine => routine.enabled).length === 0) return 'outline-danger';
        return 'outline-success';
    }

    function getRoutineIndicatorVariant() {
        if (routines.length === 0) return 'outline-danger';
        return 'outline-success';
    }

    return (
        <Layout>
            <LocationBreadcrumbContainer />
            <Modal show={deleteModalVisible} onHide={hideDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Delete location <b>{location?.name}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        onClick={hideDeleteModal}
                    >
                        Cancel
                    </Button>
                    <Dropdown
                        as={ButtonGroup}
                        align={{ lg: 'start' }}
                    >
                        <Button
                            variant="danger"
                            onClick={() => {
                                hideDeleteModal();
                                api.deleteLocation(organizationId, locationId)
                            }}
                        >
                            <FaTrash
                                className="me-2"
                                style={{ marginTop: "-2px" }}
                            />
                            Delete
                        </Button>
                    </Dropdown>
                </Modal.Footer>
            </Modal>
            <Card>
                <Title title={
                    <>
                        <>
                            <BackButton href={urls.organization(organizationId)} />
                            {location ? location.name : "Location"}
                        </>
                    </>
                }>
                    <div>
                        {getUserIsAdmin() && (
                            <DevMenuButton className='ms-2'>
                                <Dropdown.Item as={Link} to={urls.locationMap(organizationId, locationId)}>
                                    <FaMap
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    View Map
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setView(view === 'default' ? 'data' : 'default')}>
                                    <FaCode
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    {view === 'default' ? 'View Data' : 'View Default'}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <FirestoreDevMenuItem
                                    as={Dropdown.Item}
                                    path={firestoreApi.getLocationDocumentPath(organizationId, locationId)}
                                />
                                <LogDevMenuItem
                                    as={Dropdown.Item}
                                    name='location'
                                    value={locationId}
                                />
                            </DevMenuButton>
                        )}
                        <MapButton
                            url={urls.locationMap(organizationId, locationId)}
                            className="ms-2"
                        />
                        <AdminLocationButton
                            view={view}
                            setView={setView}
                            showDeleteModal={showDeleteModal}
                            className="ms-2"
                        />
                    </div>
                </Title>
                {getUserIsAdmin() && location?.deleted && (
                    <Card.Body className='pb-4 pt-0 px-4'>
                        <Alert variant="danger" className='m-0'>
                            <p>
                                This location is deleted
                            </p>
                            <div>
                                <Button
                                    variant="danger"
                                    onClick={() => api.restoreLocation(organizationId, locationId)}
                                >
                                    <FaTrashRestore className='me-2' />
                                    Restore
                                </Button>
                            </div>
                        </Alert>
                    </Card.Body>
                )}
                {getUserIsAdmin() && (
                    <ListGroup variant="flush">
                        <ListGroup.Item className="px-4">
                            <Row>
                                <Col xs={12} md={3}>
                                    <b>Assistent</b>
                                </Col>
                                <Col xs={12} md={9}>
                                    <h5 className='d-inline'>
                                        <Button
                                            variant={location?.assistant?.enabled ? 'success' : 'danger'}
                                            size='sm'
                                            className="me-2"
                                        >
                                            {location?.assistant?.enabled ? 'Enabled' : 'Disabled'}
                                        </Button>
                                        <Button
                                            variant={getRoutineIndicatorVariant()}
                                            size='sm'
                                            className="me-2"
                                        >
                                            {routines.length || 0} routines
                                        </Button>
                                        <Button
                                            variant={getEnabledRoutineIndicatorVariant()}
                                            size='sm'
                                            className="me-2"
                                        >
                                            {routines.filter(routine => routine.enabled).length || 0} enabled
                                        </Button>
                                    </h5>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item className="px-4">
                            <Row>
                                <Col xs={12} md={3}>
                                    <b>Timezone</b>
                                </Col>
                                <Col xs={12} md={9}>
                                    <h5 className='d-inline'>
                                        <Button
                                            variant='outline-secondary'
                                            as={Link}
                                            to={urls.locationSettings(organizationId, locationId)}
                                            size='sm'
                                            className="me-2"
                                        >
                                            {location?.timezone || 'UTC'}
                                        </Button>
                                        <Button
                                            variant='outline-secondary'
                                            size='sm'
                                            className="me-2"
                                        >
                                            {/* Display hour:minute only */}
                                            {moment().tz(location?.timezone || 'UTC').format('HH:mm')}
                                        </Button>
                                    </h5>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                )}
                {view === 'data' && (
                    <Card.Body className="p-4 pt-0">
                        <CodeBlock name='Location' code={location} />
                    </Card.Body>
                )}
            </Card>
            <Row>
                <Col xs={6} className="mt-4">
                    <Stat
                        label="Drones"
                        text={drones.length || 0}
                        onClick={() => history.push(urls.locationDrones(organizationId, locationId))}
                        icon={
                            <FaChevronRight
                                className="ms-2"
                                style={{
                                    marginTop: "-2px",
                                    color: "#b9b9b9",
                                }}
                            />
                        }
                    />
                </Col>
                <Col xs={6} className="mt-4">
                    <Stat
                        label="Tasks"
                        text={tasks.length}
                        onClick={() => {
                            history.push(urls.tasks(organizationId, locationId));
                        }}
                        icon={
                            <FaChevronRight
                                className="ms-2"
                                style={{
                                    marginTop: "-2px",
                                    color: "#b9b9b9",
                                }}
                            />
                        }
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={12} md={8}>
                    <LocationMissionList
                        organizationId={organizationId}
                        locationId={locationId}
                        limit={10}
                        className='mb-4'
                    />
                </Col>
                <Col xs={12} md={4}>
                    <DroneCardList
                        droneFeedbacks={droneFeedback}
                        organizationId={organizationId}
                        locationId={locationId}
                    />
                </Col>
            </Row>
        </Layout>
    );
}
