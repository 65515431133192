import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function WaitForPeriodCommand(props) {
    const setPeriod = (event) => props.updateCommandSettings("period", event.target.value ? parseFloat(event.target.value) : '');

    return (
        <>
            <CorvusSetting name='Period'>
                <Form.Control
                    autoFocus
                    {...props.register("command_waitforperiod_period", { onChange: setPeriod })}
                    defaultValue={props.command.settings.period}
                />
                <InputGroup.Text>
                    s
                </InputGroup.Text>
            </CorvusSetting>
        </>
    );
}
