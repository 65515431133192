import React from "react";
import * as missionApi from "../../api/missionApi";
import { STATE_PENDING, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, STATE_UNKNOWN } from "../../api/missionApi";
import StateIcon from "../StateIcon";

export default function AppStatusMessage(props) {

    function appStatusIsTodo(mission, app) {
        if (missionApi.appStartedProcessing(mission, app)) return false;
        if (missionApi.appFinishedProcessing(mission, app)) return false;
        return true;
    }

    function appStatusIsDoing(mission, app) {
        if (!missionApi.appStartedProcessing(mission, app)) return false;
        if (missionApi.appFinishedProcessing(mission, app)) return false;
        return true;
    }

    function appStatusIsSucceeded(mission, app) {
        if (!missionApi.appSucceededProcessing(mission, app)) return false;
        return true;
    }

    function appStatusIsFailed(mission, app) {
        if (!missionApi.appFailedProcessing(mission, app)) return false;
        return true;
    }

    function getAppState(mission, app) {
        const matchingApp = mission.apps.find(appItem => appItem.id === app?.id);
        console.log(matchingApp)
        if (matchingApp?.state !== undefined) {
            return matchingApp.state;
        } else {
            if (appStatusIsTodo(mission, app)) {
                return STATE_PENDING
            } else if (appStatusIsDoing(mission, app)) {
                return STATE_RUNNING
            } else if (appStatusIsSucceeded(mission, app)) {
                return STATE_SUCCEEDED
            } else if (appStatusIsFailed(mission, app)) {
                return STATE_FAILED
            } else {
                return STATE_UNKNOWN
            }
        }
    }

    return (
        <span>
            <StateIcon
                state={getAppState(props.mission, props.app)}
                className={"me-4"}
            />
            {props.app?.name || "Unknown app"}
        </span>
    );
}