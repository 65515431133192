import React from "react";
import { Dropdown } from "react-bootstrap";

var fileDownload = require("js-file-download");
var stringify = require('json-stable-stringify');

export default function FileExporter(props) {
    // TODO : Merge with component in LocationSettingsPage
    // TODO : Replace Dropdown with a passable component

    function exportFile() {
        const stringData = stringify(props.data, { space: 4 });
        fileDownload(stringData, `${props.name}.json`);
    }

    return (
        <Dropdown.Item onClick={exportFile}>
            {props.children}
        </Dropdown.Item>
    );
}
