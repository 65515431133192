// TODO : display errors on this page

import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, { Title } from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";


function getDroneIdsOfPayload(droneConfigs, payloadId) {
    return droneConfigs
        .filter(droneConfig => droneConfig.payloadId === payloadId)
        .map(droneConfig => droneConfig.id)
}

export default function PayloadListPage() {
    const { getUserIsAdmin } = useUser();
    const [payloads, setPayloads] = React.useState([]);
    const [droneConfigs, setDroneConfigs] = React.useState([]);

    React.useEffect(() => {
        api.getPayloads(setPayloads);
        api.getAllDroneConfigs(setDroneConfigs);
    }, []);

    return (
        <Layout>
            <Card>
                <Title title="Payloads" />
                <CorvusList>
                    {getUserIsAdmin() &&
                        payloads &&
                        payloads.map((payload) => (
                            <CorvusListItem
                                className="p-0"
                                key={payload.id}
                                as={Link}
                                to={urls.payloadSettings(payload.id)}
                            >
                                <CorvusListItemText>
                                    {payload.name} [{getDroneIdsOfPayload(droneConfigs, payload.id).join(', ')}]
                                </CorvusListItemText>
                            </CorvusListItem>
                        ))}
                </CorvusList>
            </Card>
        </Layout>
    );
}
