import React from "react";
import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { FaCode } from "react-icons/fa";

export default function DevMenuButton(props) {
    return (
        <DropdownButton
            className={props.className}
            variant="outline-secondary"
            align={{sm: 'start'}}
            as={ButtonGroup}
            title={
                <FaCode style={{ marginTop: "-2px" }}/>
            }
        >
            {props.children}
        </DropdownButton>
    );
}
