import React from "react";
import { Button, ButtonGroup, Card, Dropdown, DropdownButton, ListGroup } from "react-bootstrap";
import { FaCode, FaCog, FaDatabase, FaTrash } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import Layout, { DockBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseFirestore } from "../../corvusFirebase.js";
import urls from "../../urls.js";

var stringify = require('json-stable-stringify');

export default function DockPage(props) {
    const [dock, setDock] = React.useState();
    const [dockDrone, setDockDrone] = React.useState();
    const history = useHistory();
    const { getUserIsAdmin } = useUser();
    const { organizationId, locationId, dockId } = useParams();
    const [view, setView] = React.useState('default');

    React.useEffect(() => {
        api.getDock(organizationId, locationId, dockId, setDock)
    }, [organizationId, locationId, dockId]);

    React.useEffect(() => {
        return getFirebaseFirestore() /// TODO : move to api file
            .collection(`droneConfigs`)
            .where("organizationId", "==", organizationId)
            .where("locationId", "==", locationId)
            .where("dockId", "==", dockId)
            .get()
            .then((snapshots) => {
                if (snapshots.docs.length > 0) {
                    setDockDrone(snapshots.docs[0].data());
                } else {
                    setDockDrone(null);
                }
            });
    }, [organizationId, locationId, dockId]);

    function onDeleteDock() {
        api.deleteDock(organizationId, locationId, dockId)
            .then(() => {
                history.push(urls.docks(organizationId, locationId));
            });
    }

    function visitFirestore() {
        const organizationPath = `organizations/${organizationId}`;
        const locationPath = `${organizationPath}/locations/${locationId}`;
        const dockPath = `${locationPath}/docks/${dockId}`;

        window.open(`https://console.firebase.google.com/u/0/project/corvus-backend/firestore/data/~2F${dockPath}`);
    }

    return (
        <Layout>
            <DockBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.docks(organizationId, locationId)} />
                        Dock {dock?.name && dock.name !== '' ? dock.name : dockId}
                    </>
                }>
                    <ButtonGroup>
                        <Button
                            variant="secondary"
                            as={Link}
                            to={urls.dockSettings(organizationId, locationId, dockId)}
                        >
                            <FaCog
                                className="me-2"
                                style={{
                                    marginTop: "-2px",
                                }}
                            />
                            Settings
                        </Button>
                        <DropdownButton
                            variant="secondary"
                            align="end"
                            as={ButtonGroup}
                        >
                            {getUserIsAdmin() && (
                                <>
                                    <Dropdown.Item
                                        onClick={() => setView(view === 'default' ? 'data' : 'default')}
                                    >
                                        <FaCode
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        {view === 'default' ? 'View Data' : 'View Default'}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={visitFirestore}
                                    >
                                        <FaDatabase
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        View Firestore
                                    </Dropdown.Item>
                                </>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item
                                className="text-danger"
                                onClick={onDeleteDock}
                            >
                                <FaTrash
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Delete Dock
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </Title>
                {view === 'data' && (
                    <Card.Body className='p-4 pt-0'>
                        <CodeBlock name='Dock' code={dock} />
                    </Card.Body>
                )}
                {view === 'default' && (
                    <ListGroup variant="flush">
                        <ListGroup.Item className="px-4">
                            <b>Position</b>
                            <span className="ms-2">
                                {stringify(dock && dock.position)}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item className="px-4">
                            <b>Yaw</b>
                            <span className="ms-2">
                                {stringify(dock && dock.yaw)}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item className="px-4">
                            <b>Drone</b>
                            <span className="ms-2">
                                {dockDrone && (
                                    <Link
                                        to={urls.locationDrone(organizationId, locationId, dockDrone.id)}
                                    >
                                        {dockDrone.id}
                                    </Link>
                                )}
                                {!dockDrone && (
                                    <>No Drone</>
                                )}
                            </span>
                        </ListGroup.Item>
                    </ListGroup>
                )}
            </Card>
        </Layout>
    );
}
