/** The configurations for connecting to the Firebase project */

// This config values are no secret since all security concerns
// are handled by Firebase security rules. Committing it to the
// Git repository is therefore no concern.

export const firebaseConfig = {
    apiKey: "AIzaSyBxdgBHGzlMYmo09BK5w0FNcenRWYVeyag",
    authDomain: "corvus-backend.firebaseapp.com",
    projectId: "corvus-backend",
    storageBucket: "corvus-backend.appspot.com",
    messagingSenderId: "493740961644",
    appId: "1:493740961644:web:be73dfd5707977e342cd49",
    measurementId: "G-KHX45G82SZ"
};
