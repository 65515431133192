import React from "react";
import { Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

export default function SavedButton(props) {
    return (
        <Button
            variant="outline-success"
            className={props.className}
            disabled
        >
            <FaCheck className="me-2" />
            Saved
        </Button>
    );
}
