import { Spinner } from "react-bootstrap";
import { FaCheck, FaRegHourglass, FaTimes, FaQuestion } from "react-icons/fa";
import { STATE_PENDING, STATE_CANCELLED, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED } from "../../api/missionApi";

export default function StateIcon({ state, className }) {
    switch (state) {
        case STATE_PENDING: return <FaRegHourglass className={`text-primary ${className}`} style={{ marginTop: "-2px" }} />
        case STATE_RUNNING:
            return <Spinner
                animation="border"
                role="status"
                size="sm"
                className={`text-primary ${className}`}
                style={{ marginTop: "-2px" }}
            />
        case STATE_SUCCEEDED: return <FaCheck className={`text-success ${className}`} style={{ marginTop: "-2px" }} />
        case STATE_FAILED: return <FaTimes className={`text-danger ${className}`} style={{ marginTop: "-2px" }} />
        case STATE_CANCELLED: return <FaTimes className={`text-secondary ${className}`} style={{ marginTop: "-2px" }} />

        default:
            return <FaQuestion className={className} style={{ marginTop: "-2px" }} />
    }
}
