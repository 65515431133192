import React from "react";
import { Button, ButtonGroup, Dropdown, Spinner } from "react-bootstrap";

export default function LoadMoreButton(props) {
    return (
        <div className='mt-4 d-flex justify-content-center align-items-center'>
            <div xs='auto'>
                <div className='text-center'>
                    {props.label && (
                        <p className='text-muted m-0 p-0 mb-2'>
                            Showing {props.itemsLoaded} of {props.totalItems} {props.label || 'items'}
                        </p>
                    )}
                    {props.text && (
                        <p className='text-muted m-0 p-0 mb-2'>
                            {props.text}
                        </p>
                    )}
                    {props.isLoading && (
                        <LoadingButton
                            loadHowManyMore={props.loadHowManyMore}
                        />
                    )}
                    {!props.isLoading && (
                        <LoadButton
                            onClick={props.load}
                            showLoadAll={props.showLoadAll}
                            loadAll={props.loadAll}
                            disabled={props.disabled}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function LoadingButton(props) {
    return (
        <Button variant='outline-primary' disabled>
            <Spinner
                as='span'
                animation='border'
                size='sm'
                className='me-2'
            />
            Loading ...
        </Button>
    );
}

function LoadButton(props) {
    return (
        <Dropdown
            as={ButtonGroup}
        >
            <Button
                variant='primary'
                onClick={props.onClick}
                disabled={props.disabled}
            >
                Load more
            </Button>
            {props.showLoadAll && (
                <>
                    <Dropdown.Toggle
                        split
                        variant='primary'
                        disabled={props.disabled}
                    />
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={props.loadAll}
                        >
                            Load all
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </>
            )}
        </Dropdown>
    );
}
