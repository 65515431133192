import React from "react";
import { Card, Button, Modal } from "react-bootstrap";

import * as api from "../../api/api";
import { useUser } from "../../contexts/user_provider";


export default function UserQuestion(props) {
    const [showUserQuestionModal, setShowUserQuestionModal] = React.useState(false);
    const [userQuestion, setUserQuestion] = React.useState(null);
    const { user } = useUser();

    React.useEffect(() => {
        if (props.mission && props.mission.userQuestions) {
            const activeQuestions = props.mission.userQuestions
                // Filter by expirationDate (include if not expired)
                .filter((question) => {
                    return (!question.expirationDate || new Date(question.expirationDate.seconds * 1000) > new Date()) && question.answer.length === 0;
                })
                // Sort by timestamp (latest first)
                .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
            const firstQuestion = activeQuestions.length > 0 ? activeQuestions[0] : null;

            setUserQuestion(firstQuestion);
            if (firstQuestion) {
                setShowUserQuestionModal(true);
            } else {
                setShowUserQuestionModal(false);
            }
        }
    }, [props.mission]);

    function handleUserQuestionAnswer(answer) {
        api.answerUserQuestion(props.organizationId, props.locationId, props.mission.id, userQuestion.id, answer, user.email)
        return;
    }

    return (
        <>
            <Modal show={showUserQuestionModal} onHide={() => setShowUserQuestionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{showUserQuestionModal && userQuestion.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showUserQuestionModal && userQuestion.question}
                </Modal.Body>
                <Modal.Footer>
                    {showUserQuestionModal && userQuestion.options && userQuestion.options.length > 0 ? (
                        userQuestion.options.map((option, index) => (
                            <Button
                                variant={option.colour}
                                onClick={() => handleUserQuestionAnswer(option.value)}
                            >{option.value}</Button>
                        ))
                    ) : (
                        <p>No options available</p>
                    )}
                </Modal.Footer>
            </Modal>
            {userQuestion && (
                <Card
                    className='mb-4 rounded'
                    style={{ borderRadius: '0' }}>
                    <Card.Body className="d-flex justify-content-between align-middle ">
                        <div>
                            <h4 className="m-0 p-0">{userQuestion.title}</h4>
                            <p className="m-0 p-0">{userQuestion.question}</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            {userQuestion.options && userQuestion.options.length > 0 ? (
                                userQuestion.options.map((option, index) => (
                                    <Button
                                        className="me-2"
                                        variant={option.colour}
                                        onClick={() => handleUserQuestionAnswer(option.value)}
                                    >{option.value}</Button>
                                ))
                            ) : (
                                <p>No options available</p>
                            )}

                        </div>
                    </Card.Body>
                </Card>)}
        </>
    );
}
