import React from "react";
import { Alert, Button, ButtonGroup, Dropdown, Form, Modal } from "react-bootstrap";
import { FaPaperPlane } from "react-icons/fa";
import * as api from "../../api/api";
import { useUser } from "../../contexts/user_provider";

export default function FeedbackModal(props) {
    const [feedbackComment, setFeedbackComment] = React.useState('');
    const [feedbackOptions, setFeedbackOptions] = React.useState([]);
    const [selectedFeedbackOptions, setSelectedFeedbackOptions] = React.useState([]);
    const [feedbackSent, setFeedbackSent] = React.useState(false);
    const { user } = useUser();

    React.useEffect(() => {
        async function getFeedbackOptions() {
            const feedbackName = props.name.toLowerCase();
            await api.getFeedbackOptions(feedbackName, setFeedbackOptions);
        }
        getFeedbackOptions();
    }, [props.name]);

    function feedbackOptionClicked(event, clickedOption) {
        const newSelection = [...selectedFeedbackOptions];

        const index = newSelection.findIndex(o => o.keyword === clickedOption.keyword);
        if (index !== -1) {
            newSelection.splice(index, 1);
        } else {
            newSelection.push(clickedOption);
        }

        setSelectedFeedbackOptions(newSelection);
    }

    function onFeedbackCommentTyped(event) {
        setFeedbackComment(event.target.value);
    }

    function sendFeedback() {
        const feedbackMessages = [...selectedFeedbackOptions ];

        if (feedbackComment) {
            feedbackMessages.push({
                keyword: "comment",
                description: feedbackComment,
            });
        }

        const feedback = {
            author: user.uid,
            messages: feedbackMessages,
            timestamp: new Date().toISOString(),
            type: props.name.toLowerCase(),
        }

        const firestorePath = `${props.firestoreDocumentPath}/feedbacks`;
        return api.sendFeedback(firestorePath, feedback)
            .then(() => setFeedbackSent(true));
    }

    return (
        <Modal
            show={props.isVisible}
            onHide={() => props.setIsVisible(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.name}{' '}Feedback
                </Modal.Title>
            </Modal.Header>
            {feedbackSent && (
                <>
                    <Modal.Body>
                        <Alert variant="success" className='m-0'>
                            Thanks for your feedback!
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Dropdown as={ButtonGroup}
                            align={{ lg: 'start' }}
                        >
                            <Button
                                variant="success"
                                onClick={() => {
                                    setFeedbackSent(false);
                                    props.setIsVisible(false);
                                }}
                            >
                                Close
                            </Button>
                        </Dropdown>
                    </Modal.Footer>
                </>
            )}
            {!feedbackSent && (
                <>
                    <Modal.Body>
                        {feedbackOptions.map(feedbackOption =>
                            <Form.Check
                                type="checkbox"
                                onChange={(event) => feedbackOptionClicked(event, feedbackOption)}
                                label={feedbackOption.description}
                            />
                        )}
                        <Form.Control
                            placeholder="Did you notice anything else?"
                            as="textarea"
                            rows={2}
                            className="mt-2"
                            onChange={onFeedbackCommentTyped}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-secondary"
                            onClick={() => props.setIsVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Dropdown as={ButtonGroup}
                            align={{ lg: 'start' }}
                        >
                            <Button
                                variant="success"
                                onClick={sendFeedback}
                            >
                                <FaPaperPlane
                                    className="me-2"
                                    style={{ marginTop: "-2px" }}
                                />
                                Send
                            </Button>
                        </Dropdown>
                    </Modal.Footer>
                </>
            )}

        </Modal>
    );
}