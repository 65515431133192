import React from "react";
import { Card, Form, Button, Spinner, Alert } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import TextFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import BackButton from "../../components/BackButton";
import CancelButton from "../../components/CancelButton"
import Layout, {
    DroneCreateBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export default function DroneCreatePage() {
    const { organizationId, locationId } = useParams();
    const { getUserIsAdmin } = useUser();
    const history = useHistory();
    const [droneIds, setDroneIds] = React.useState([]);
    const [droneConfigPresets, setDroneConfigPresets] = React.useState(undefined);
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    const createDroneConfigSchema = () => z.object({
        droneId: z.string()
            .length(4, { message: "Drone name must be 4 letters" })
            .regex(/^[A-Za-z]+$/, { message: "Drone name must contain only letters" })
            .transform((val) => val.toUpperCase())
            .refine((val) => !droneIds.includes(val), { message: "Drone name already exists" })
    });

    const { control, handleSubmit, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(createDroneConfigSchema())
    });

    React.useEffect(() => {
        setIsLoading(true);

        const fetchDroneIdsPromise = api.getAllDroneIds(setDroneIds);
        const fetchDroneConfigPresetsPromise = api.getDroneConfigPresets(setDroneConfigPresets);

        Promise.all([fetchDroneIdsPromise, fetchDroneConfigPresetsPromise])
            .then(() => {
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setHasError(true);
                setIsLoading(false);
            });
    }, []);

    const onSubmit = async (formData) => {
        const droneId = formData.droneId;
        const droneConfig = {
            id: droneId,
            organizationId: organizationId,
            locationId: locationId,
            payloadId: 'KaLPC6zGR5KXzJ3dKYL2',
            dockId: 'none',
            augmentation: 'none',
            docks: [],
            // The following are just for backwards compatibility and can be removed later
            focal_length: droneConfigPresets["E12"]["default"]["payload"]["camera_settings"]["focal_length"],
            sensor_height: droneConfigPresets["E12"]["default"]["payload"]["camera_settings"]["sensor_height"],
            sensor_width: droneConfigPresets["E12"]["default"]["payload"]["camera_settings"]["sensor_width"],
            max_focus_distance: droneConfigPresets["E12"]["default"]["payload"]["camera_settings"]["max_focus_distance"],
            min_focus_distance: droneConfigPresets["E12"]["default"]["payload"]["camera_settings"]["min_focus_distance"],
            z_offset: droneConfigPresets["E12"]["default"]["payload"]["camera_settings"]["z_offset"],
            bufferDistance: droneConfigPresets["E12"]["default"]["routeplanner"]["buffer_distance"],
            max_flight_duration: droneConfigPresets["E12"]["default"]["routeplanner"]["max_flight_duration"],
            max_speed: droneConfigPresets["E12"]["default"]["routeplanner"]["max_speed"],
            scan_speed: droneConfigPresets["E12"]["default"]["routeplanner"]["scan_speed"],
            skipTakeOff: droneConfigPresets["E12"]["default"]["routeplanner"]["skip_take_off"],
            takeoff_height: droneConfigPresets["E12"]["default"]["routeplanner"]["takeoff_height"],
            takeoff_buffer: droneConfigPresets["E12"]["default"]["routeplanner"]["takeoff_buffer"],

            // The following should stay
            ...droneConfigPresets["E12"]["default"]
        }
        await api.updateDroneConfig(droneId, droneConfig);
        history.push(urls.locationDrone(organizationId, locationId, droneId));
    }

    return (
        <Layout>
            <DroneCreateBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.locationDrones(organizationId, locationId)} />
                        Create new drone
                    </>
                } />
                {!getUserIsAdmin() &&
                    <Card.Body className="p-4 py-0 mb-3">
                        Insufficient rights to create a new drone
                    </Card.Body>}
                {getUserIsAdmin() &&
                    < Card.Body className="p-4 py-0">
                        {hasError ? (
                            <Alert variant="danger" className="mx-4 mb-4">
                                Something went wrong. Please refresh the page.
                            </Alert>) :
                            isLoading ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    {<TextFormGroup label="Drone name"
                                        name="droneId"
                                        control={control}
                                        errors={errors} />}
                                    <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-1">
                                        <FaSave className="mb-1 me-1" />
                                        {isSubmitting ? "Saving..." : "Save"}
                                    </Button>
                                    <CancelButton
                                        className="ms-2 mb-3 mt-1"
                                        href={urls.locationDrones(organizationId, locationId)}
                                    />
                                </Form>)}
                    </Card.Body>
                }
            </Card>
        </Layout >
    );
}
