import { useEffect, useState, useRef } from 'react';
import * as api from "../api/api";

function useMissionFlightsSubscription(organizationId, locationId, mission) {
    const [flights, setFlights] = useState([]);
    const unsubscribeFlightsRef = useRef([]);

    useEffect(() => {
        // Unsubscribe from the existing flights
        unsubscribeFlightsRef.current.forEach((flightUnsubscribe) => flightUnsubscribe());

        if (mission) {
            let flightIds = mission.flights.map(flight => flight.id);
            const newFlightsUnsubscribe = flightIds.map(flightId => api.subscribeFlight(organizationId, locationId, flightId, (flightData) => {
                setFlights(prevFlights => ({ ...prevFlights, [flightId]: flightData }));
            }));

            // Clear existing unsubscribe functions before assigning new ones
            unsubscribeFlightsRef.current = newFlightsUnsubscribe;
        }

        // Cleanup function for unsubscribing when the component unmounts
        return () => {
            // Unsubscribe from the flights
            unsubscribeFlightsRef.current.forEach((flightUnsubscribe) => flightUnsubscribe());
        };
    }, [organizationId, locationId, mission]);

    return flights;
}

export default useMissionFlightsSubscription;