import classnames from "classnames";
import React from "react";
import { Form, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import CorvusRightIcon from "../../components/CorvusRightIcon";

export default function ListItemWithToggle(props) {
    return (
        <ListGroup.Item
            key={props.itemKey}
            action={props.to ? props.enabled : false}
            className={classnames(
                "p-0 d-flex justify-content-between align-items-center",
                props.className
            )}
        >
            <div
                className='ps-4 pe-3 py-2'
                onClick={() => { props.enabled ? props.onDisable(props.item) : props.onEnable(props.item) }}
            >
                <Form.Check
                    type="switch"
                    defaultChecked={props.enabled}
                />
            </div>
            {(props.enabled || !props.toggleLink) && (
                <Link
                    to={props.to}
                    className='ps-0 px-4 py-2 d-flex justify-content-between align-items-center text-decoration-none text-dark'
                    style={{ flexGrow: '4' }}
                >
                    <div>
                        {props.text}
                    </div>
                    <div>
                        {props.badges}
                        <CorvusRightIcon className='d-inline' />
                    </div>
                </Link>
            )}
            {(!props.enabled && props.toggleLink) && (
                <div
                    className='ps-0 px-4 py-2 d-flex justify-content-between align-items-center text-decoration-none text-dark'
                    style={{ flexGrow: '4' }}
                >
                    <div>
                        {props.text}
                    </div>
                    <div>
                        {props.badges}
                    </div>
                </div>
            )}
        </ListGroup.Item>
    );
}
