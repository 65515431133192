import { Card, Alert } from "react-bootstrap";

import DroneCard from "../DroneCard";
import { CorvusList } from "../CorvusList";
import * as api from "../../api/api";
import urls from "../../urls.js";

export default function DroneCardList(props) {

    // turn the feedbacks into a list so they have an order
    let feedbackArray = [];
    for (var droneId in props.droneFeedbacks) {
        feedbackArray.push(props.droneFeedbacks[droneId]);
    }

    // add the state to each of them
    feedbackArray = feedbackArray.map(feedback => {
        const uploadStatus = feedback?.widgets?.payloadUploadStatus?.data || "(0.00 B)"
        const uploading = Number(uploadStatus.split("(")[1].split(" ")[0]); // Get a more machine readable why to check if the drone is uploading
        const missions = feedback?.widgets?.missions || [];
        const status = feedback?.widgets?.status?.data || "Sleeping";
        const online = api.droneIsOnline(feedback);

        let state = "Offline";
        let stateColor = "text-secondary";
        if (missions.length > 0 || 
            (status !== "Sleeping" && // TODO: When everything is handled by the mission handler, these status checks can be removed
             status !== "Ready for takeoff" && 
             !status.startsWith("Not ready for takeoff") &&
             status !== "Battery is empty" &&
             status !== "Going to sleep")) {
            state = "Collecting";
            stateColor = "text-primary";
        } else if (uploading > 0 && online) {
            state = "Uploading";
            stateColor = "text-primary";
        } else if (online) {
            state = "Idle"
            stateColor = "text-success";
        }

        return { ...feedback, state: state, stateColor: stateColor };
    });

    // sort the feedbacks, first on state 'Collecting > Uploading > Idle > Offline', then on battery percentage
    const STATE_OPTIONS = ['Collecting', 'Uploading', 'Idle', 'Offline'];
    feedbackArray.sort((a, b) => {
        // first sort on state
        if (a.state !== b.state) {
            return STATE_OPTIONS.indexOf(a.state) - STATE_OPTIONS.indexOf(b.state);
        }
        // if both drones are offline, sort alphabeticly on drone id
        if (a.state === "Offline" && b.state === "Offline") {
            if (a.droneId < b.droneId) {
                return -1;
            } else if (b.droneId < a.droneId) {
                return 1;
            } else {
                return 0;
            }
        }

        // if both states are the same but not offline, then sort on the amount of battery charge
        const batteryA = a?.widgets?.battery?.data.toFixed() || -1;
        const batteryB = b?.widgets?.battery?.data.toFixed() || -1;
        return batteryB - batteryA;
    })


    return (
        <Card className={props.className}>
            <Card.Header className="d-flex justify-content-between">
                <div style={{ fontWeight: 'bold' }}>
                    {props.title || 'Drones'}
                </div>
            </Card.Header>
            {feedbackArray.length === 0 && (
                <Card.Body>
                    <Alert className="text-center mb-0" variant="primary">
                        <div className="text-muted">
                            No Drones
                        </div>
                    </Alert>
                </Card.Body>
            )}
            {feedbackArray.length > 0 && (
                <CorvusList height={feedbackArray.length > 5 ? "411px" : '100%'}>
                    {feedbackArray.map((droneFeedback) => (
                        <DroneCard
                            battery={droneFeedback?.widgets?.battery?.data.toFixed() || -1}
                            offline={!api.droneIsOnline(droneFeedback)}
                            charging={droneFeedback?.widgets?.isCharging?.data || false}
                            state={droneFeedback?.state}
                            stateColor={droneFeedback?.stateColor}
                            name={droneFeedback.droneId}
                            to={urls.locationDrone(props.organizationId, props.locationId, droneFeedback.droneId)}
                        />
                    ))}
                </CorvusList>
            )}
        </Card>
    );
}
