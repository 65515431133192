import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout, { TaskAnalyseBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import SampleSeedlingsSettings from "../../components/AppSettings/SampleSeedlings";


export default function TaskAnalysePage(props) {
    const { organizationId, locationId, taskId, analyseId } = useParams();
    console.log("appId", analyseId);

    return (
        <Layout>
            <TaskAnalyseBreadcrumbContainer />
            <Card>
                <Title title={`App Settings`} />
                {(analyseId === "WfJUnkRP6wx0C7kZDKFn" || analyseId === "4lzObdrZZzbPumJ9nAfa") && (
                    <SampleSeedlingsSettings
                        organizationId={organizationId}
                        locationId={locationId}
                        taskId={taskId}
                        appId={analyseId}>
                    </SampleSeedlingsSettings>)
                }
            </Card>
        </Layout>
    );
}
