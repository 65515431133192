import React from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, {
    TaskListBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import urls from "../../urls.js";

export default function TaskListPage() {
    const history = useHistory();
    const [tasks, setTasks] = React.useState([]);
    const { organizationId, locationId } = useParams();

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getTasks(organizationId, locationId, setTasks); // TODO : use subscribeTasks instead ...
        }
    }, [organizationId, locationId]);

    return (
        <Layout>
            <TaskListBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Tasks
                    </>
                }>
                    <div>
                        <Button
                            className="d-inline ms-2 py-2"
                            variant="outline-success"
                            as={Link}
                            to={urls.taskCreate(organizationId, locationId)}
                        >
                            <FaPlus className="me-2" />
                            New Task
                        </Button>
                    </div>
                </Title>
                {tasks.length === 0 && (
                    // TODO : Move this functionalty to a component and reuse it in DroneListPage & RoutineListPage
                    <Alert variant="primary" className="mx-4 mb-4">
                        No tasks
                    </Alert>
                )}
                {tasks.length > 0 && (
                    <CorvusList>
                        {tasks.map((task) => (
                            <CorvusListItem
                                className="p-0"
                                key={task.id}
                                as={Link}
                                to={urls.task(organizationId, locationId, task.id)}
                            >
                                <CorvusListItemText>
                                    {task.name}
                                </CorvusListItemText>
                            </CorvusListItem>
                        ))}
                    </CorvusList>
                )}
            </Card>
        </Layout>
    );
}
