import React from "react";
import { Dropdown, Form } from "react-bootstrap";

export default function FileImporter(props) {
    // TODO : Merge with component in LocationSettingsPage

    const ref = React.useRef(null);

    const handleFileRead = (event) => {
        return props.onImport(event.target.result);
    };

    const onChange = (event) => {
        let fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(event.target.files[0]);
    };

    const onClick = (event) => {
        ref.current.value = null;
        ref.current.click();
    }

    return (
        <>
            <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={ref}
                onChange={onChange}
            />
            <Dropdown.Item onClick={onClick}>
                {props.children}
            </Dropdown.Item>
        </>
    );
}
