import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import TimeDisplay from "../TimeDisplay";
import urls from "../../urls.js";
import moment from 'moment-timezone';

var cronstrue = require("cronstrue/i18n");

export default function RoutinePreview({routine, organizationId, locationId, location, routineTask, routineDrone, routineMission, className}) {
    function describeCronJob(cronString) {
        return cronstrue.toString(cronString, {
            throwExceptionOnParseError: false,
            locale: "en",
            use24HourTimeFormat: true,
            verbose: true
        });
    }

    const userTimezone = moment.tz.guess();
    const localTimezone = location?.timezone;
    const showTimezone = localTimezone !== undefined && localTimezone !== userTimezone;

    return (
        <Alert
            variant="primary"
            className={className}
        >
            Start task
            {' '}
            <Link to={urls.task(
                organizationId,
                locationId,
                routineTask?.id)}
            >
                {routineTask?.name}
            </Link>
            {' '}
            {describeCronJob(routine?.interval).toLowerCase()}
            {showTimezone && " in timezone '" + localTimezone +"'"}
            {' '}on drone{' '}
            <Link to={urls.locationDrone(
                organizationId,
                locationId,
                routineDrone?.id)
            }>
                {routineDrone?.id}
            </Link>
            {
                routineMission &&
                <>
                    , continuing with mission <Link to={urls.locationMission(
                        organizationId,
                        locationId,
                        routineMission.id)
                    }>
                        {<TimeDisplay
                            timestamp={routineMission.timestamp.seconds}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={organizationId}
                            locationId={locationId}
                        />}
                    </Link>
                </>
            }
        </Alert>
    );
}
