import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaMap } from "react-icons/fa";

export default function MapButton(props) {
    return (
        <Button
            variant="outline-secondary"
            as={Link}
            to={props.url}
            className={props.className}
            size={props.size}
        >
            <FaMap className="me-2" />
            Map
        </Button>
    );
}