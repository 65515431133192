// Modify the following react code so that map-container is the full width and height of the page. Please respond with code block. I saw another site do it like this (class="h-full w-full overflow-hidden absolute top-0 bottom-0 padding-for-mapbox-attribution mapboxgl-map") with tailwindcss but I am using bootstrap

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React from 'react';

mapboxgl.accessToken = 'pk.eyJ1IjoiY29ydnVzZHJvbmVzIiwiYSI6ImNsZG5kejZhaTBobTkzcW4wbnVmOWVubDcifQ.byLyRVTk--hsoW-O3mM-8w';

const warehouseGeoJSON = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-70.9, 42.35],
                        [-70.8, 42.35],
                        [-70.8, 42.36],
                        [-70.9, 42.36],
                        [-70.9, 42.35],
                    ],
                ],
            },
        },
    ],
};

const warehousePinGeoJSON = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-70.9, 42.35],
            },
        },
    ],
};

export default function App() {
    const mapContainer = React.useRef(null);
    const map = React.useRef(null);
    const [lng, setLng] = React.useState(-70.9);
    const [lat, setLat] = React.useState(42.35);

    const [zoom, setZoom] = React.useState(9);

    React.useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: {
                attributionControl: false,
                version: 8,
            },
            center: [lng, lat],
            zoom: zoom,
            // style: 'mapbox://styles/mapbox/streets-v11',
            //style: 'mapbox://styles/mapbox/dark-v11',
            attributionControl: false,
            projection: 'globe',
            // do not display any names on the map
        });

        map.current.on('load', () => {
            map.current.addSource('warehouse', {
                type: 'geojson',
                data: warehouseGeoJSON,
            });

            map.current.addSource('warehousePin', {
                type: 'geojson',
                data: warehousePinGeoJSON,
            });

            map.current.addLayer({
                id: 'warehouse',
                type: 'fill',
                source: 'warehouse',
                paint: {
                    'fill-color': '#088',
                    'fill-opacity': 0.8,
                },
            });
        });
    });

    React.useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <div>
            <div className="sidebar">
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div>
            <div
                ref={mapContainer}
                style={{
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    paddingBottom: '30px',
                }}
            />
        </div>
    );
}
