import React from 'react';
import { Form, Row, Col, InputGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaUndo, FaCog } from 'react-icons/fa';
import { Controller } from 'react-hook-form';

const GenericFormGroup = ({ name, label, control, errors, defaultValue, setValue, renderInput }) => {
    // Function to safely access nested errors
    const getErrorMessage = (errors, path) => {
        return path.split('.').reduce((acc, segment) => acc?.[segment], errors)?.message;
    };

    const errorMessage = getErrorMessage(errors, name);
    const hasResetAbility = defaultValue !== undefined && setValue !== undefined;

    const resetValue = () => {
        if (hasResetAbility) {
            console.log(`Reset value of ${name} to ${defaultValue}`);
            setValue(name, defaultValue, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Reset to default value
        </Tooltip>
    );

    return (
        <Form.Group as={Row} className="mb-2" controlId={label}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    const canResetToDefault = hasResetAbility && String(field.value) !== String(defaultValue);

                    return (
                        <>
                            <Form.Label column sm={3} style={{ fontWeight: canResetToDefault ? 'bold' : 'normal' }}>
                                {label}
                            </Form.Label>
                            <Col sm={9}>
                                <InputGroup>
                                    {renderInput(field, canResetToDefault, errorMessage)}
                                    {hasResetAbility && (
                                        <OverlayTrigger delay={{ show: 250 }} overlay={renderTooltip}>
                                            <Button variant="outline-secondary" onClick={resetValue} disabled={!canResetToDefault}>
                                                <FaCog />
                                                <FaUndo />
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                                </InputGroup>
                                {errorMessage && <Form.Control.Feedback type="invalid" className="d-block ms-2">{errorMessage}</Form.Control.Feedback>}
                            </Col>
                        </>
                    );
                }}
            />
        </Form.Group>
    );
};

export default GenericFormGroup;
