import React from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CancelButton from "../../components/CancelButton";
import CorvusSetting from "../../components/CorvusSetting";
import CorvusTextSetting from "../../components/CorvusTextSetting";
import Layout, {
    DockSettingsBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import { ProgressingSaveButton } from "../../components/ProgressingSaveButton";
import urls from "../../urls.js";

// TODO : There is a bug in the name settings, when setting to fast it wont pickup. For now not too important because only developers are using it... Solution would be to use the form like on the DroneSettings page.

/*
function MarkerSelectButton(props) {
    const [markers, setMarkers] = React.useState([]);
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getMarkers(organizationId, locationId, setMarkers);
    }, [organizationId, locationId]);

    return (
        <Form.Select
            onChange={(event) => {
                // parse float from marker id
                props.onChange(parseFloat(event.target.value))
            }}
            value={props.value}
        >
            <option value=''>
                Choose a marker ...
            </option>
            {markers.map((marker, markerId) => {
                return (
                    <option key={markerId} value={marker.id}>
                        {marker.id}
                    </option>
                );
            })}
        </Form.Select>
    );
}
*/
export default function DockSettingsPage() {
    const history = useHistory();
    const [dock, setDock] = React.useState({
        name: '',
        markers: { left: { id: '', }, right: { id: '', }, },
        position: { x: 0, y: 0, z: 0 },
        yaw: 0,
    });
    const { organizationId, locationId, dockId } = useParams();
    const [saved, setSaved] = React.useState(true);

    React.useEffect(() => {
        if (!isNewDock()) {
            api.getDock(organizationId, locationId, dockId, setDock);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, locationId, dockId,]);

    function isNewDock() {
        return dockId === undefined;
    }

    function onSaveDock() {
        const validDock = {
            ...dock,
            markers: [3, 17],
            position: {
                x: parseFloat(dock.position.x || 0),
                y: parseFloat(dock.position.y || 0),
                z: parseFloat(dock.position.z || 0),
            },
            yaw: parseFloat(dock.yaw || 0),
        }
        if (isNewDock()) {
            return api.createDock(organizationId, locationId, validDock)
                .then((dockId) => {
                    history.push(urls.dockSettings(organizationId, locationId, dockId));
                    setSaved(true);
                });
        } else {
            return api.saveDock(organizationId, locationId, dockId, validDock)
                .then(() => {
                    setSaved(true);
                });
        }
    }

    function changeDock(newKeyValues) {
        setDock({ ...dock, ...newKeyValues, });
        setSaved(false);
    }

    function changePosition(newKeyValues) {
        changeDock({
            position: {
                ...(dock.position || {}),
                ...newKeyValues,
            }
        });
    }
    /*
    function changeMarkers(newKeyValues) {
        changeDock({
            markers: {
                ...(dock.markers || {}),
                ...newKeyValues,
            }
        });
    }
    */
    function setYaw(yaw) {
        changeDock({ yaw });
    }

    function setName(name) {
        changeDock({ name });
    }

    function setX(x) {
        changePosition({ x });
    }

    function setY(y) {
        changePosition({ y });
    }

    function setZ(z) {
        changePosition({ z });
    }

    /*
    function setLeftMarker(leftMarkerId) {
        changeMarkers({ left: { id: leftMarkerId, }, });
    }

    function setRightMarker(rightMarkerId) {
        changeMarkers({ right: { id: rightMarkerId, }, });
    }
    */
    function getBackUrl() {
        if (isNewDock()) {
            return urls.docks(organizationId, locationId);
        } else {
            return urls.dock(organizationId, locationId, dockId);
        }
    }

    return (
        <Layout>
            <DockSettingsBreadcrumbContainer />
            <Card>
                {isNewDock() && (
                    <Title title={
                        <>
                            <BackButton href={getBackUrl()} />
                            Create Dock
                        </>
                    } />
                )}
                {!isNewDock() && (
                    <Title title={
                        <>
                            <BackButton href={getBackUrl()} />
                            Dock Settings
                        </>
                    } />
                )}
                <Card.Body className="p-4 py-0">
                    <CorvusTextSetting
                        name="Name"
                        className="mb-2"
                        placeholder="Dock name ..."
                        defaultValue={dock.name || ''}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <CorvusSetting name="Position" className="mb-2">
                        <InputGroup.Text>
                            x
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Dock X position ..."
                            value={dock.position.x}
                            onChange={(event) => setX(event.target.value)}
                        />
                        <InputGroup.Text>
                            y
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Dock Y position ..."
                            value={dock.position.y}
                            onChange={(event) => setY(event.target.value)}
                        />
                        <InputGroup.Text>
                            z
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Dock Z position ..."
                            value={dock.position.z}
                            onChange={(event) => setZ(event.target.value)}
                        />
                    </CorvusSetting>
                    <CorvusSetting name="Yaw" className="mb-2">
                        <Form.Control
                            type="text"
                            placeholder="Dock Yaw rotation ..."
                            value={dock.yaw}
                            onChange={(event) => setYaw(event.target.value)}
                        />
                    </CorvusSetting>
                </Card.Body>
                <Card.Body className="p-4">
                    <Card.Text>
                        <ProgressingSaveButton
                            className="me-2"
                            onSave={onSaveDock}
                            saved={saved}
                        />
                        <CancelButton
                            href={getBackUrl()}
                            className="me-2"
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        </Layout >
    );
}
