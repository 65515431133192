import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function FlyToZCommand(props) {
    const setZ = (event) => props.updateCommandSettings("z", event.target.value ? parseFloat(event.target.value) : '');

    return (
        <>
            <CorvusSetting name='Z'>
                <Form.Control
                    autoFocus
                    {...props.register("command_flytoz_z", { onChange: setZ })}
                    defaultValue={props.command.settings.z}
                />
                <InputGroup.Text>
                    m
                </InputGroup.Text>
            </CorvusSetting>
        </>
    );
}
