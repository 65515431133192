import queryString from 'query-string';
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import Layout, { FlightImageListBreadcrumbContainer, LocationImageListBreadcrumbContainer, MissionImageListBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import LoadMoreButton from "../../components/LoadMoreButton";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseFirestore, getFirebaseStorageDownloadUrl } from "../../corvusFirebase.js";
import urls from "../../urls.js";

export default function ImageListPage() {
    const resolution = '256x256';
    const measurementsPerPage = 16;
    const maxMeasurementsToLoad = 10000;

    const [measurements, setMeasurements] = React.useState([]);
    const [mission, setMission] = React.useState(null);
    const [measurementDocs, setMeasurementDocs] = React.useState([]);
    const { organizationId, locationId } = useParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const { getUserIsAdmin } = useUser();
    const [measurementsToLoad, setMeasurementsToLoad] = React.useState(measurementsPerPage);
    const { missionId, flightId } = queryString.parse(window.location.search);
    const history = useHistory();

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }


    React.useEffect(() => {
        console.log('Fetching measurement docs ...');
        setIsLoading(true);

        let query = getFirebaseFirestore()
            .collection(`organizations/${organizationId}/locations/${locationId}/measurements`);

        if (missionId) {
            query = query.where('meta.mission.id', '==', missionId);
        } else if (flightId) {
            query = query.where('meta.flight.id', '==', flightId);
        }

        query = query.orderBy("event.summary.MEASUREMENT_STARTED", "asc");

        query = query.limit(Math.min(measurementsToLoad, maxMeasurementsToLoad));

        return query
            .onSnapshot((snapshot) => {
                console.log(`(Firestore) Receiving ... measurements`);

                const newMeasurements = snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                        organization: { id: organizationId },
                        location: { id: locationId },
                    };
                });

                setMeasurementDocs(newMeasurements);
                setIsLoading(false);
            });
    }, [organizationId, locationId, missionId, flightId, measurementsToLoad]);

    // Subscribe mission if any
    React.useEffect(() => {
        if (missionId) {
            return api.subscribeMission(organizationId, locationId, missionId, setMission);
        }
    }, [organizationId, locationId, missionId]);

    // Load measurements images, sort on timestamp
    React.useEffect(() => {

        Promise.all(measurementDocs.map((measurement) => {
            const organizationId = measurement.organization.id;
            const locationId = measurement.location.id;

            const organizationPath = `organizations/${organizationId}`;
            const locationPath = `${organizationPath}/locations/${locationId}`;
            const resolutionPath = `${locationPath}/measurements/${resolution}`;
            const measurementName = `${measurement.id}_${resolution}.jpg`;
            const measurementPath = `${resolutionPath}/${measurementName}`;

            return getFirebaseStorageDownloadUrl(measurementPath)
                .then((url) => {
                    return { ...measurement, thumbnail: url };
                })
                .catch((error) => {
                    return { ...measurement, thumbnail: process.env.PUBLIC_URL + "/image_placeholder.png" };
                });
        })).then(setMeasurements);

    }, [measurementDocs]);

    return (
        <Layout>
            {!missionId && flightId && (
                <FlightImageListBreadcrumbContainer />
            )}
            {missionId && !flightId && (
                <MissionImageListBreadcrumbContainer />
            )}
            {!missionId && !flightId && (
                <LocationImageListBreadcrumbContainer />
            )}
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.locationMission(organizationId, locationId, missionId)} />
                        Images
                    </>
                } />
            </Card>
            <Row>
                {measurements.map(m => (
                    <Col key={m.id} lg={3}>
                        <Card
                            as={Link}
                            to={urls.measurement(organizationId, locationId, missionId, m.id)}
                            className="mt-4"
                        >
                            <div style={{ position: 'relative' }}>
                                <Card.Img
                                    src={m.thumbnail}
                                />
                                {getUserIsAdmin() && (m.tags || []).length > 0 && (
                                    <h1
                                        className='h6 mt-1 ms-1'
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            color: 'blue',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        🔵
                                    </h1>
                                )}
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <LoadMoreButton
                label='images'
                load={() => {
                    setMeasurementsToLoad(measurementsToLoad + measurementsPerPage)
                }}
                showLoadAll={true}
                loadAll={() => setMeasurementsToLoad(maxMeasurementsToLoad)}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                itemsLoaded={measurementDocs?.length || 0}
                totalItems={mission?.measurements?.length || 0}
                className='mt-4'
                disabled={measurementDocs?.length >= mission?.measurements?.length}
            />
        </Layout>
    );
}
