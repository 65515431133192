import { FaChevronRight } from "react-icons/fa";

export default function CorvusRightIcon(props) {
    return (
        <div className={props.className}>
            <FaChevronRight
                style={{
                    color: "#b9b9b9",
                }}
            />
        </div>
    );
}
