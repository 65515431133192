import copy from 'copy-to-clipboard';
import React from 'react';
import { Button, Card } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { JSONTree } from 'react-json-tree';

export default function CodeBlock(props) {
    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
        copy(JSON.stringify(props.code, null, 4));
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    return (
        <Card
            variant='dark'
            className="m-0"
            style={{ backgroundColor: "rgb(0, 43, 54)" }}
        >
            <Card.Header
                className="d-flex justify-content-between align-items-center"
                style={{ backgroundColor: '#30323f' }}
            >
                <div style={{ color: 'white', fontWeight: 'bold' }}>
                    {!!props.name ? `${props.name}` : `JSON`}
                </div>
                <Button
                    size="sm"
                    variant={copied ? "success" : "primary"}
                    onClick={handleCopy}
                >
                    <FaCopy className="me-2" />
                    {copied ? `Copied!` : 'Copy'}
                </Button>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#000000' }}>
                <pre className="m-0">
                    <JSONTree
                        data={props.code}
                        hideRoot={true}
                        sortObjectKeys={true}
                        labelRenderer={([key]) => <strong>{key}</strong>}
                        // valueRenderer={(raw) => <em>{raw}</em>}
                        theme={{
                            valueLabel: {
                                textDecoration: 'underline',
                            },
                            // make the background black
                            tree: {
                                backgroundColor: 'rgb(0, 0, 0)',
                            },
                            nestedNodeLabel: ({ style }, keyPath, nodeType, expanded) => ({
                                style: {
                                    ...style,
                                    textTransform: expanded ? 'uppercase' : style.textTransform,
                                },
                            }),
                        }}
                    />
                </pre>
            </Card.Body>
        </Card>
    );
}
