import React from "react";
import SaveButton from '../SaveButton';
import SavedButton from '../SavedButton';
import SavingButton from '../SavingButton';

export function ProgressingSaveButton(props) {
    const [saving, setSaving] = React.useState(false);
    
    if (saving) {
        return (
            <SavingButton className={props.className} />
        );
    }
    if (!props.saved) {
        return (
            <SaveButton
                className={props.className}
                onClick={() => {
                    setSaving(true);
                    return props.onSave().then(() => setSaving(false))
                }}
            />
        );
    }
    if (props.saved) {
        return (
            <SavedButton className={props.className} />
        );
    }
}
