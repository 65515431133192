// TODO : lots of cleanup to do here...

import React from "react";
import { Container, Card, Form, FormControl, Button } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { useAuth } from "../../contexts/auth_provider";
import urls from "../../urls.js";

const NewPasswordPage = (props) => {
    const history = useHistory();
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
    const emailRef = React.useRef("");
    const { corvusConfirmPasswordReset } = useAuth();

    var params = new URLSearchParams(props.location.search);

    function doChangePassword(event) {
        event.preventDefault();
        if (newPassword !== null) {
            if (newPassword !== confirmNewPassword) {
                alert("Passwords do not match!");
            } else {
                corvusConfirmPasswordReset(params.get("oobCode"), newPassword)
                    .then(function () {
                        alert("Password Change Successfully");
                        history.push(urls.home());
                        // Success
                    })
                    .catch(function (e) {
                        alert(e);
                        // Invalid code
                    });
            }
        } else {
            alert("Please enter an New Password!");
        }
    }

    return (
        <>
            <div className="my-5 text-center">
                <img
                    onClick={() => history.push(urls.home())}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                    src={process.env.PUBLIC_URL + "/logo-black-white.png"}
                    alt="none"
                />
            </div>
            <Container style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <Form onSubmit={doChangePassword}>
                            <Form.Group>
                                <Form.Label>
                                    <b>New Password</b>
                                </Form.Label>
                                <FormControl
                                    type="password"
                                    ref={emailRef}
                                    value={newPassword}
                                    onChange={(event) => setNewPassword(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <b>Confirm New Password</b>
                                </Form.Label>
                                <FormControl
                                    type="password"
                                    ref={emailRef}
                                    value={confirmNewPassword}
                                    onChange={(e) => {
                                        setConfirmNewPassword(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Button
                                type="submit"
                                onClick={doChangePassword}
                                block
                            >
                                <b>Change password</b>
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

export default withRouter(NewPasswordPage);
