// TODO : display errors on this page

import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";
import Layout, {
    DroneNerdBreadcrumbContainer, Title
} from "../../components/Layout/Layout";

var stringify = require('json-stable-stringify');

export default function DroneNerdPage() {
    const { droneId } = useParams();
    const [drone, setDrone] = React.useState(undefined);

    React.useEffect(() => {
        if (droneId) {
            return api.subscribeDroneFeedback(droneId, setDrone);
        }
    }, [droneId]);

    return (
        <Layout>
            <DroneNerdBreadcrumbContainer />
            <Card>
                <Title title="Drone nerd" />
                {drone &&
                    drone.widgets &&
                    Object.keys(drone.widgets).length > 0 && (
                        <ListGroup variant="flush">
                            {Object.keys(drone.widgets)
                                .sort()
                                .map((key, index) => {
                                    return (
                                        <ListGroup.Item
                                            key={index}
                                            className="px-4"
                                        >
                                            <b>{key}</b>
                                            <span className="ms-2">
                                                {stringify(drone.widgets[key])}
                                            </span>
                                        </ListGroup.Item>
                                    );
                                })}
                        </ListGroup>
                    )}
            </Card>
        </Layout>
    );
}
