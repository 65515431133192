function getStateSystems(droneState) {
    // drone.widgets.versions looks like ["v0.0.1", "v0.0.2", ...] and it should be { "v0.0.1": true, "v0.0.2": true, ...} and {} if it's null
    return droneState?.widgets?.systems?.reduce((acc, system) => {
        acc[system] = true;
        return acc;
    }, {}) || {};
}

function configHasSystem(droneConfig, system) {
    const localSystems = droneConfig?.systems || {};
    if (!(system in localSystems)) return false;
    if (localSystems[system] === false) return false;
    return true;
}

function stateHasSystem(droneState, system) {
    const localSystems = getStateSystems(droneState);
    return system in localSystems;
}

export function isUnavailable(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemInConfig = configHasSystem(droneConfig, system);
    return !systemInConfig && !systemInState;
}

export function isDownloading(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemInConfig = configHasSystem(droneConfig, system);
    return systemInConfig && !systemInState;
}

export function isAvailable(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemIsCurrentInConfig = droneConfig?.system === system;
    const systemIsCurrentInState = droneState?.widgets?.system === system;
    const systemInConfig = configHasSystem(droneConfig, system);

    return systemInConfig && systemInState && !systemIsCurrentInConfig && !systemIsCurrentInState;
}

export function isStarting(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemIsCurrentInConfig = droneConfig?.system === system;
    const systemIsCurrentInState = droneState?.widgets?.system === system;
    const systemInConfig = configHasSystem(droneConfig, system);
    return systemInConfig && systemInState && systemIsCurrentInConfig && !systemIsCurrentInState;
}

export function isStopping(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemIsCurrentInConfig = droneConfig?.system === system;
    const systemIsCurrentInState = droneState?.widgets?.system === system;
    const systemInConfig = configHasSystem(droneConfig, system);
    return systemInConfig && systemInState && !systemIsCurrentInConfig && systemIsCurrentInState;
}

export function isRunning(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemInConfig = configHasSystem(droneConfig, system);
    const systemIsCurrentInConfig = droneConfig?.system === system;
    const systemIsCurrentInState = droneState?.widgets?.system === system;
    return systemInConfig && systemInState && systemIsCurrentInConfig && systemIsCurrentInState;
}

export function isDeleting(droneConfig, droneState, system) {
    const systemInState = stateHasSystem(droneState, system);
    const systemInConfig = configHasSystem(droneConfig, system);
    return !systemInConfig && systemInState;
}
